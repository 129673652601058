import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ItemImage from 'components/ItemImage'
import PriceRange from 'components/PriceRange'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { TotalPriceRangeT } from 'types/Product'

interface PropsT {
  brandName: string
  imgUrl: string
  handleClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  miniPDPOpenedFrom?: MiniPDPLocationsT
  price: TotalPriceRangeT
  productName?: string
  webStyleId: number
  wideWidth?: boolean
}

const VerticalProductImage = ({
  brandName,
  imgUrl,
  handleClickForMiniPDP,
  miniPDPOpenedFrom,
  price,
  productName,
  webStyleId,
  wideWidth
}: PropsT): JSX.Element => {
  return (
    <Box key={webStyleId} sx={{ width: wideWidth ? '160px' : '130px' }}>
      <Button
        aria-label="view product details"
        onClick={() => {
          if (handleClickForMiniPDP && webStyleId && miniPDPOpenedFrom) {
            handleClickForMiniPDP({
              webStyleId: webStyleId.toString(),
              miniPDPOpenedFrom,
              imgUrl: imgUrl
            })
          }
        }}
        sx={{ maxWidth: '120px', padding: 0 }}
        startIcon={
          <AddIcon
            sx={{
              position: 'absolute',
              top: '2%',
              right: '2%',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '5px',
              width: '1.5em',
              height: 'auto',
              opacity: '0.95'
            }}
          />
        }
      >
        <ItemImage imageUrl={imgUrl} height={184} width={120} />
      </Button>
      <Typography
        fontWeight={productName ? 'bold' : 'normal'}
        variant={'body2'}
        my={0.5}
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2
        }}
      >
        {brandName}
      </Typography>
      {productName && (
        <Typography
          variant={'body2'}
          my={0.5}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2
          }}
        >
          {productName}
        </Typography>
      )}
      <PriceRange price={price} />
    </Box>
  )
}

export default VerticalProductImage
