import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Button, Grid } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ErrorMessage from '../../ErrorMessage'
import Skeleton from 'components/Skeleton'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

type CardContentT = {
  count: number
  copy: string
  renderIcon: () => React.ReactNode
  iconBackgroundColor: string
  nrEvent: string
  buttonPath: string
  buttonCopy: string
  isError: boolean
  isLoading: boolean
}

type PropsT = {
  content: CardContentT
}

const Card = (props: PropsT): ReactElement => {
  const history = useHistory()
  const {
    count,
    copy,
    renderIcon,
    iconBackgroundColor,
    nrEvent,
    buttonPath,
    buttonCopy,
    isError,
    isLoading
  } = props.content

  const handleClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs(nrEvent, attributes)
    history.push({ pathname: buttonPath })
  }

  if (isError) {
    return (
      <Grid item xs={12} sm={12} md={4}>
        <ErrorMessage errorTitle="Hmm, the section didn’t load" isInlineAlert />
      </Grid>
    )
  }

  return (
    <Grid item xs={12} sm={12} md={4}>
      {isLoading ? (
        <Skeleton width="100%" height="118px" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: { xs: '100%', md: '118px' },
            padding: { xs: '12px', md: '16px' },
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: { xs: '8px', sm: '12px', md: '12px' },
              height: '100%'
            }}
          >
            <Box
              my="auto"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'flex-start',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                marginRight: 2,
                minWidth: '40px',
                bgcolor: count === 0 ? '#eeeeee' : iconBackgroundColor
              }}
            >
              {renderIcon()}
            </Box>
            <Typography variant="subtitle2">{copy}</Typography>
          </Box>
          <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              alignSelf: 'flex-end',
              cursor: 'pointer',
              color: '#482FE9',
              marginTop: 'auto',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
            onClick={handleClick}
          >
            <Typography
              variant="button"
              sx={{
                fontWeight: 'bold',
                marginRight: '8px'
              }}
            >
              {buttonCopy}
            </Typography>
            <ArrowForwardIosIcon
              sx={{
                fontSize: { xs: '12px', sm: '15px' },
                fontWeight: 'bold'
              }}
            />
          </Button>
        </Box>
      )}
    </Grid>
  )
}

export default Card
