export const DOSSIER_FEEDBACK_STORAGE_KEY = 'customer_dossier_feedback_storage'
export const DOSSIER_STORAGE_KEY = 'customer_dossier_storage'
export const NEGATIVE_FEEDBACK_DETAILS_STORAGE_KEY =
  'negative_feedback_details_storage_key'

export const addDossierItemToLocalStorage = <T>({
  storageKey,
  dossierIdentifier,
  data
}: {
  storageKey: string
  dossierIdentifier: number | string
  data: T
}): void => {
  const customerDossiers = JSON.parse(localStorage.getItem(storageKey) || '{}')
  const newDossier = {
    ...customerDossiers,
    [dossierIdentifier]: data
  }
  localStorage.setItem(storageKey, JSON.stringify(newDossier))
}

export const getDossierItemFromLocalStorage = <T>(
  dossierIdentifier: number | string,
  storageKey: string
): T => {
  const dossierItem = localStorage.getItem(storageKey)
  return dossierItem ? JSON.parse(dossierItem)[dossierIdentifier] : undefined
}

export const deleteCustomerDossier = (
  dossierIdentifier: number | string
): undefined => {
  const dossier = JSON.parse(localStorage.getItem(DOSSIER_STORAGE_KEY) || '{}')
  const dossierFeedback = JSON.parse(
    localStorage.getItem(DOSSIER_FEEDBACK_STORAGE_KEY) || '{}'
  )
  delete dossier[dossierIdentifier]
  delete dossierFeedback[dossierIdentifier]
  localStorage.setItem(DOSSIER_STORAGE_KEY, JSON.stringify(dossier))
  localStorage.setItem(
    DOSSIER_FEEDBACK_STORAGE_KEY,
    JSON.stringify(dossierFeedback)
  )
}
