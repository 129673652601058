import { ReactElement, ReactNode } from 'react'
import { Box } from '@mui/material'
import AssistantIcon from '@mui/icons-material/Assistant'

const SummaryBoxWithIcon = ({
  children
}: {
  children: ReactNode
}): ReactElement => {
  return (
    <Box display="flex" flexDirection="row">
      <Box display="flex" flexDirection="row">
        <AssistantIcon sx={{ mr: 1 }} />
        {children}
      </Box>
    </Box>
  )
}

export default SummaryBoxWithIcon
