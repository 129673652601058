import Drawer from '@mui/material/Drawer'
import FiltersContent from './FiltersContent'
import { CopyErrorDataT } from 'types/CopyErrorData'

export const FILTERS_DRAWER_WIDTH = 320

type PropsT = {
  isOpen: boolean
  onClose: () => void
  customersCount: number
  extendedCustomerDataIsLoading: boolean
  extendedCustomerDataCopyError?: CopyErrorDataT
}

const FiltersDrawer = (props: PropsT): JSX.Element => {
  const {
    isOpen,
    onClose,
    customersCount,
    extendedCustomerDataIsLoading,
    extendedCustomerDataCopyError
  } = props

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      variant="temporary"
      sx={{
        width: { xs: '100%', sm: `${FILTERS_DRAWER_WIDTH}px` },
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: `${FILTERS_DRAWER_WIDTH}px` }
        }
      }}
    >
      <FiltersContent
        onClose={onClose}
        customersCount={customersCount}
        extendedCustomerDataIsLoading={extendedCustomerDataIsLoading}
        extendedCustomerDataCopyError={extendedCustomerDataCopyError}
      />
    </Drawer>
  )
}

export default FiltersDrawer
