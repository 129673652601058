import { Box, Typography, List, ListItem } from '@mui/material'
import { customStylingTheme } from 'theme'
import { TipsAndUpdates } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { ReactElement } from 'react'
import { SEARCH_OPTIONS } from 'pages/SearchDrawer/SearchDrawer'

interface HelperTextProps {
  title: string
  description: string
  listItems: string[]
}

const THREE_HUNDRED_PX = 37.5

const HelperText = ({
  title,
  description,
  listItems
}: HelperTextProps): ReactElement => (
  <Box
    sx={{
      maxWidth: customStylingTheme.spacing(THREE_HUNDRED_PX),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    }}
  >
    <Typography variant="subtitle2">{title}</Typography>
    <Typography variant="caption" color="text.secondary">
      {description}
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            pl: customStylingTheme.spacing(0.25),
            lineHeight: customStylingTheme.spacing(0.5)
          },
          marginY: customStylingTheme.spacing(1.5),
          marginLeft: customStylingTheme.spacing(0.75)
        }}
      >
        {listItems.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    </Typography>
  </Box>
)

const SearchHelperText = ({
  searchType
}: {
  searchType: SEARCH_OPTIONS
}): ReactElement => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '80vh',
      textAlign: 'left',
      mt: customStylingTheme.spacing(4)
    }}
  >
    <Box
      sx={{
        maxWidth: customStylingTheme.spacing(THREE_HUNDRED_PX),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#EBEFF1',
          borderRadius: '50px',
          width: '67px',
          height: '67px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px'
        }}
      >
        {searchType === SEARCH_OPTIONS.FASHION_MAP && (
          <TipsAndUpdates
            fontSize="large"
            color="secondary"
            sx={{ textAlign: 'center' }}
          />
        )}
        {searchType === SEARCH_OPTIONS.POLARIS && (
          <SearchIcon
            fontSize="large"
            color="secondary"
            sx={{ textAlign: 'center' }}
          />
        )}
      </Box>
      {searchType === SEARCH_OPTIONS.FASHION_MAP && (
        <HelperText
          title="Get the most out of Concept Search"
          description="Try searching by characteristics such as style, occasion, season, location, and item features. For example:"
          listItems={[
            'Edgy blazer for winter party',
            'Romantic long floral dress for spring',
            'Classic structured gold button office jacket'
          ]}
        />
      )}
      {searchType === SEARCH_OPTIONS.POLARIS && (
        <HelperText
          title="Get the most out of N.com Search"
          description="Try searching for products with specific language. Include a size, color,
price, brand, or material in your search for better results. For example:"
          listItems={[
            'Dresses for women',
            'Vince sweaters',
            "Men's black shoes size 11"
          ]}
        />
      )}
    </Box>
  </Box>
)

export default SearchHelperText
