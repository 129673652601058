import { Box, Typography } from '@mui/material'
import { CustomerDossierT } from 'types/CustomerDossier'
import SummaryFeedback from '../CustomerSummaryFeedback'
import SummaryBoxWithIcon from '../SummaryBoxWithIcon/SummaryBoxWithIcon'
import { DossierLogsAttributesT, DossierOriginT } from '../../helpers'

const SummaryTextBlock = ({ content }: { content: CustomerDossierT }) => {
  return (
    <Box mb={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        Style overview
      </Typography>
      {content?.styleKeys && (
        <Typography variant="body2">{content.styleKeys}</Typography>
      )}
      {content?.overview && (
        <Typography variant="body2" display="block" mb={1}>
          {content.overview}
        </Typography>
      )}
      <Typography variant="subtitle2" fontWeight="bold">
        Color palette
      </Typography>
      {content?.colorPalette && (
        <Typography variant="body2" mb={1}>
          {content.colorPalette}
        </Typography>
      )}
      <Typography variant="subtitle2" fontWeight="bold">
        Product summary
      </Typography>
      {content?.productSummary && (
        <Typography variant="body2">{content.productSummary}</Typography>
      )}
    </Box>
  )
}

const CustomerSummaryBlock = ({
  customerName,
  data,
  dossierOrigin,
  dossierIdentifier,
  dossierLogsAttributes
}: {
  customerName?: string
  data: CustomerDossierT
  dossierOrigin: DossierOriginT
  dossierIdentifier: number | string
  dossierLogsAttributes: DossierLogsAttributesT
}): JSX.Element => {
  return (
    <SummaryBoxWithIcon>
      <Box display="flex" flexDirection="column">
        <Typography mb={2} variant="subtitle2" fontWeight="bold">
          {customerName
            ? `Generated summary of ${customerName}`
            : 'Generated summary'}
        </Typography>
        {data && <SummaryTextBlock content={data} />}
        <SummaryFeedback
          dossierOrigin={dossierOrigin}
          dossierIdentifier={dossierIdentifier}
          dossierLogsAttributes={dossierLogsAttributes}
        />
        <Typography variant="caption" display="block" color="text.secondary">
          Summary is generated by AI from customer purchases. For seller use
          only.
        </Typography>
      </Box>
    </SummaryBoxWithIcon>
  )
}

export default CustomerSummaryBlock
