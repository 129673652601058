import { ReactElement } from 'react'
import { Container, Box } from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import EditRequestDetails from '../EditRequestDetails'
import RequestDetails from '../RequestDetails'
import SavedBrands from '../SavedBrands'
import ShoppedBrands from '../ShoppedBrands'
import SavedSizes from '../SavedSizes'
import WishListPreview from '../WishListPreview'
import RecentPurchases from '../RecentPurchases'
import SnapshotNavigation from '../SnapshotNavigation'
import FashionPillar from '../FashionPillar'
import { whenSnapshotClosed } from 'utils/track'
import ErrorBoundary from '../ErrorBoundary'
import CustomerInfo from '../RequestDetails/CustomerInfo'
import { useGetConsentedCustomerQuery } from 'services/clientelingApi'
import { useGetCurationByIdQuery } from 'services/curationSvc'
import { savedSizesT } from 'types/ConsentedCustomer'
import StyleBoards from '../StyleBoards/'
import CustomerDossier from '../CustomerDossier'
import { useGetPurchaseHistoryQuery } from 'services/employeeExperienceApi'
import { OrderT } from 'types/Orders'
import moment from 'moment'
import ConsentedCustomerTripsListItem from 'components/ConsentedCustomerTripsListItem'

const MIN_ORDERS_FOR_DOSSIER = 10
const MAX_ORDER_AGE_FOR_DOSSIER_IN_DAYS = 730

export type PropsT = {
  customerId: string
  handleClickForPdp: (webstyleId: string) => void
  isSellerInitiated: boolean
  onClose: () => void
}

const Snapshot = (props: PropsT): ReactElement => {
  const { customerId, handleClickForPdp, isSellerInitiated, onClose } = props
  const {
    isEditRequestEnabledPersonalBook,
    isConsentedCustomerDetailsStyleBoardsEnabled,
    isCustomerDossierEnabled,
    isConsentedCustomerTripsDetailsAndSnapshotEnabled
  } = useFeatureFlags()

  /* Long term solution: Move logic to check for consented customer into "Create Curation" call in curation svc and only allow curations to be created when customer and seller are in consented relationhip. */
  const {
    data: getConsentData,
    isLoading: isGetConsentLoading,
    isError: isGetConsentError,
    endpointName: getConsentEndpointName,
    error: getConsentError,
    originalArgs: getConsentOriginalArgs
  } = useGetConsentedCustomerQuery(
    {
      customerId,
      retrieveQualification: false,
      retrieveShoppedBrands: true,
      retrieveSavedSizes: true
    },
    {
      // The isSellerInitiated param is enough to handle the skip criteria. It's data from a curation request,
      // so the feature flags endpoint should have already been resolved when needed.
      skip: !isSellerInitiated
    }
  )

  const curationSlice = useAppSelector(curationSelector)
  const { id: curationId } = curationSlice
  const curation = useGetCurationByIdQuery({
    curationId: curationId as number
  })

  const {
    error: phError,
    endpointName: phEndpointName,
    orders,
    isLoading: phIsLoading,
    isError: phIsError
  } = useGetPurchaseHistoryQuery(
    { customerId },
    {
      selectFromResult: ({
        data,
        isError,
        isLoading,
        error,
        endpointName
      }) => ({
        orders: data?.orders,
        isLoading,
        isError,
        error,
        endpointName
      })
    }
  )

  // A customer should only have one edit request at a time
  // so it is safe to assume that the data needed is the first item in the array
  const editRequest = curation?.data?.curation.editRequests.length
    ? curation?.data?.curation.editRequests[0]
    : false

  const onSnapshotDrawerClose = () => {
    whenSnapshotClosed()
    onClose()
  }

  const enoughOrdersForDossier = (orders?: OrderT[]) => {
    const recentOrdersForDossier = (orders: OrderT[]) =>
      orders.filter(
        (order) =>
          moment().diff(order.orderDate, 'days') <=
          MAX_ORDER_AGE_FOR_DOSSIER_IN_DAYS
      )

    if (!orders) {
      return false
    }
    if (recentOrdersForDossier(orders).length < MIN_ORDERS_FOR_DOSSIER) {
      return false
    }

    return true
  }

  if ((isSellerInitiated && isGetConsentLoading) || phIsLoading) {
    return <LoadingSpinner />
  }

  if (isGetConsentError || (isSellerInitiated && !getConsentData)) {
    return (
      <PageError
        errorDetails={{
          endpoint: getConsentEndpointName,
          errorData: getConsentError,
          identifiers: {
            customerId: customerId || 'NO-DATA'
          },
          originalArgs: getConsentOriginalArgs
        }}
      />
    )
  }

  return (
    <BoxWithGladlySpace>
      <Container>
        <SnapshotNavigation
          isCloseButton
          title="Customer Details"
          onClick={onSnapshotDrawerClose}
        />

        {editRequest && isEditRequestEnabledPersonalBook?.active && (
          <EditRequestDetails editRequest={editRequest} />
        )}

        <ErrorBoundary>
          <>
            {isSellerInitiated && getConsentData ? (
              <CustomerInfo customerId={getConsentData.customer.customerId} />
            ) : (
              <RequestDetails customerId={customerId} curation={curation} />
            )}
            {isCustomerDossierEnabled && curationId && (
              <CustomerDossier
                customerId={customerId}
                enoughRecentOrdersForDossier={
                  // allow generate even if ph call fails
                  enoughOrdersForDossier(orders) || phIsError
                }
                dossierOrigin={'curation'}
                dossierIdentifier={curationId}
                dossierLogsAttributes={{
                  curationId,
                  customerId,
                  curationSlice
                }}
              />
            )}
            {!!isConsentedCustomerTripsDetailsAndSnapshotEnabled?.active && (
              <ConsentedCustomerTripsListItem
                customerId={getConsentData?.customer.customerId ?? customerId}
              />
            )}
          </>
        </ErrorBoundary>
      </Container>

      <Container>
        <ErrorBoundary>
          <FashionPillar customerId={customerId} />
        </ErrorBoundary>
      </Container>

      <Container>
        <Box mt={4}>
          <ErrorBoundary>
            <SavedBrands customerId={customerId} />
          </ErrorBoundary>
        </Box>
      </Container>
      <Container>
        {isSellerInitiated && (
          <Box mt={4}>
            <ShoppedBrands
              brands={getConsentData?.customer?.shoppedBrands as string[]}
              customerId={customerId}
              isError={getConsentData?.customer?.errors?.shoppedBrands}
              endpointName={getConsentEndpointName}
            />
          </Box>
        )}
      </Container>

      <Container>
        {isSellerInitiated && (
          <Box mt={4}>
            <SavedSizes
              savedSizes={getConsentData?.customer?.savedSizes as savedSizesT}
              customerId={customerId}
              isError={getConsentData?.customer?.errors?.savedSizes}
              endpointName={getConsentEndpointName}
            />
          </Box>
        )}
      </Container>

      <Container>
        <Box mt={4}>
          <ErrorBoundary>
            <RecentPurchases
              customerId={customerId}
              orders={orders}
              isLoading={phIsLoading}
              isError={phIsError}
              error={phError}
              endpointName={phEndpointName}
            />
          </ErrorBoundary>
        </Box>

        <Box my={4}>
          <ErrorBoundary>
            <WishListPreview
              handleClickForPdp={handleClickForPdp}
              customerId={customerId}
            />
          </ErrorBoundary>
        </Box>
      </Container>

      <>
        {isConsentedCustomerDetailsStyleBoardsEnabled?.active && (
          <Container>
            <Box mt={2} mb={4}>
              <StyleBoards previewCount={2} customerId={customerId} />
            </Box>
          </Container>
        )}
      </>
    </BoxWithGladlySpace>
  )
}

export default Snapshot
