import { ReactElement, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import DividerComponent from 'pages/CurationHome/components/DividerComponent'
import {
  isUSSeller,
  isStyleAdvisor,
  isScheduledFlagActiveAndEnabledForUser
} from 'utils/userPermissions'
import Drafts from './components/Drafts'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useGetEmployeeQuery } from 'services/clientelingApi'
import WelcomeHome from './components/Welcome'
import SalesAndInsights from './components/SalesAndInsights'
import LearnMore from './components/LearnMore'
import QueueSection from './components/QueueSection'
import AlertMessage from './components/AlertMessage'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import Customers from './components/Customers'
import HolidayGiftingRecommendations from 'pages/CurationReview/components/HolidayGiftingRecommendations'
import MiniPDPDrawer from 'pages/MiniPDPDrawer'
import CreateStyleBoardDialog from 'pages/CurationHome/components/CreateStyleBoardDialog'

const Home = (): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const showInviteButton = !isStyleAdvisor()
  const {
    data: employeeData,
    isLoading,
    isFetching
  } = useGetEmployeeQuery({ employeeId })

  const {
    isPBSurveyLinkEnabled,
    isHomePhase2Enabled,
    isPBHomePageHolidayGiftingRecsEnabled
  } = useFeatureFlags()
  const [selectedWebStyleId, setSelectedWebStyleId] = useState('')
  const [isMiniPDPDrawerOpen, setIsMiniPDPDrawerOpen] = useState(false)
  const [selectedImgUrl, setSelectedImgUrl] = useState('')
  const [selectedRmsSku, setSelectedRmsSku] = useState('')
  const [isCreateStyleBoardModalOpen, setIsCreateStyleBoardModalOpen] =
    useState(false)

  const surveyAlertDismissed = JSON.parse(
    localStorage.getItem('survey-alert-dismissed') || 'false'
  )
  const [showSurveyAlert, setShowSurveyAlert] = useState(!surveyAlertDismissed)

  const onClose = () => {
    localStorage.setItem('survey-alert-dismissed', 'true')
    setShowSurveyAlert(false)
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('surveyAlertCloseClick', attributes)
  }

  const handleClickForMiniPDP = ({
    imgUrl,
    webStyleId
  }: {
    imgUrl: string
    webStyleId: string
  }) => {
    setSelectedImgUrl(imgUrl)
    setSelectedWebStyleId(webStyleId)
    setIsMiniPDPDrawerOpen(true)
    generateNewRelicLogs('miniPDPOpened', {
      webStyleId,
      miniPDPOpenedFrom: 'HomePageHolidayGiftRecommendations'
    })
  }

  const handleCloseMiniPDP = () => {
    setSelectedRmsSku('')
    setIsMiniPDPDrawerOpen(false)
  }

  const handleSubmit = (rmsSku: string) => {
    setSelectedRmsSku(rmsSku)
    setIsCreateStyleBoardModalOpen(true)
  }

  return (
    <BoxWithGladlySpace>
      <Container disableGutters>
        {selectedWebStyleId && (
          <MiniPDPDrawer
            handleSubmit={handleSubmit}
            imgUrl={selectedImgUrl}
            isEdit={false}
            isMiniPDPOpen={isMiniPDPDrawerOpen}
            isPublicBoard={false}
            miniPDPOpenedFrom={'HomePageHolidayGiftRecommendations'}
            onClose={handleCloseMiniPDP}
            webStyleId={selectedWebStyleId}
          />
        )}
        <Box width={[1, 1 / 4]}>
          <CreateStyleBoardDialog
            open={isCreateStyleBoardModalOpen}
            onClose={() => {
              setIsCreateStyleBoardModalOpen(false)
            }}
            sourcePage="Home"
            rmsSku={selectedRmsSku}
            productSource={
              selectedRmsSku ? 'homePageHolidayGiftRecs' : undefined
            }
          />
        </Box>
        <Box>
          <WelcomeHome
            employee={employeeData}
            isLoading={isLoading}
            isFetching={isFetching}
            showInviteButton={showInviteButton}
          />
          {showSurveyAlert && isPBSurveyLinkEnabled?.active && (
            <AlertMessage onClose={onClose} />
          )}
          {isHomePhase2Enabled?.active && (
            <>
              <DividerComponent marginSize={2} />
              <SalesAndInsights />
            </>
          )}
          {!isStyleAdvisor() && isHomePhase2Enabled?.active && (
            <>
              <DividerComponent marginSize={2} />
              <Customers />
            </>
          )}
          {!isUSSeller() && (
            <>
              <DividerComponent marginSize={2} />
              <QueueSection />
            </>
          )}
          {isScheduledFlagActiveAndEnabledForUser(
            isPBHomePageHolidayGiftingRecsEnabled
          ) && (
            <>
              <DividerComponent marginSize={2} />
              <Container>
                <Typography variant="h6">Gift Giving Made Easy</Typography>
                <Typography variant="body2">Add items for holiday</Typography>
                <Box>
                  <HolidayGiftingRecommendations
                    onClickForMiniPDP={handleClickForMiniPDP}
                    curationId={1}
                    useBackgroundColor={false}
                    useTitle={false}
                  />
                </Box>
              </Container>
            </>
          )}
          <DividerComponent marginSize={2} />
          <Drafts />
          <DividerComponent marginSize={2} />
          <LearnMore />
        </Box>
      </Container>
    </BoxWithGladlySpace>
  )
}

export default Home
