import { NR_CLIENTELING_PAGES } from '../nrClientelingPages'
const { CUSTOMER_DETAILS_PAGE } = NR_CLIENTELING_PAGES

export const NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS = {
  CUSTOMER_DETAILS: `${CUSTOMER_DETAILS_PAGE}-customer_details_tab`,
  PURCHASES: `${CUSTOMER_DETAILS_PAGE}-purchases_tab`,
  WISH_LISTS: `${CUSTOMER_DETAILS_PAGE}-wish_lists_tab`,
  STYLEBOARDS: `${CUSTOMER_DETAILS_PAGE}-styleboards_tab`
} as const

// SHARED EVENT(S)
const CLICKED_TO_OPEN_PDP = 'clicked_to_open_pdp'

// HEADER
export const NR_CUSTOMER_DETAILS_PAGE_ARCHIVE_CUSTOMER = `${CUSTOMER_DETAILS_PAGE}-clicked_to_archive_customer`
export const NR_CUSTOMER_DETAILS_PAGE_UNARCHIVE_CUSTOMER = `${CUSTOMER_DETAILS_PAGE}-clicked_to_unarchive_customer`

// CUSTOMER DETAILS TAB
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_CUSTOMER_DETAILS_TAB = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.CUSTOMER_DETAILS}-clicked`
export const NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_TEXT = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.CUSTOMER_DETAILS}-clicked_text_cta`
export const NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_EMAIL = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.CUSTOMER_DETAILS}-clicked_email_cta`
// similar to `startBoardForCustomerFromCustomerDetailsClick` event from Curation:
export const NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_CREATE_STYLEBOARD = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.CUSTOMER_DETAILS}-clicked_create_styleboard_cta`

// PURCHASES TAB
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_PURCHASES_TAB = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-clicked`
export const NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PDP = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-${CLICKED_TO_OPEN_PDP}`
export const NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PURCHASE_DETAILS = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-clicked_to_open_purchase_details`
export const NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_TRACK_PACKAGE = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-clicked_to_track_package`
export const NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_ENABLED_PURCHASES_WITH_ME = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-enabled_purchases_with_me`
// similar to 'createCurationFromCustomerPurchaseHistoryClick' event from OrderItem:
export const NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_CREATE_BOARD_WITH_ITEM = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES}-clicked_to_create_board_with_item`

// WISH LISTS TAB
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_WISH_LISTS_TAB = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-clicked`
export const NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_OPEN_PDP = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-${CLICKED_TO_OPEN_PDP}`
export const NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_SELECT_DIFFERENT_WISH_LIST = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-selected_different_wish_list`
export const NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_STORE_AVAILABILITY_FILTER = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-enabled_store_availability_filter`
export const NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_ENABLE_ANNIVERSARY_FILTER = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-enabled_anniversary_filter`
// similar to `createCurationFromCustomerListWishlistClick` event from Curation:
export const NR_CUSTOMER_DETAILS_PAGE_WISH_LISTS_TAB_CREATE_BOARD_WITH_ITEM = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.WISH_LISTS}-clicked_to_create_board_with_item`

// STYLEBOARDS TAB
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_STYLEBOARDS_TAB = `${NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.STYLEBOARDS}-clicked`

// FOLLOW UPS
export const NR_CUSTOMER_DETAILS_PAGE_MARK_AS_COMPLETE = `${CUSTOMER_DETAILS_PAGE}-mark_follow_up_as_complete`
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_FOLLOW_UP_PURCHASE_DETAILS = `${CUSTOMER_DETAILS_PAGE}-clicked_follow_up_purchase_details`
export const NR_CUSTOMER_DETAILS_PAGE_CLICKED_COMPLETED_FOLLOW_UPS = `${CUSTOMER_DETAILS_PAGE}-clicked_completed_follow_ups`
