import { ReactElement } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import { ArrowBack, ChevronLeft, Close as CloseIcon } from '@mui/icons-material'
import { useGetCustomerQuery } from 'services/employeeExperienceApi'
import Skeleton from 'components/Skeleton'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { whenCustomerInfoViewed } from 'utils/track'

const BackToBoardButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick} startIcon={<ArrowBack />}>
    Back to board
  </Button>
)

const ViewDetailsButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick} color="primary" sx={{ maxWidth: 102 }}>
    View details
  </Button>
)

const HeaderText = ({
  isLoading,
  headerText
}: {
  isLoading: boolean
  headerText: string
}) => {
  return isLoading ? (
    <Skeleton.Text textVariant={'body1'} width={'100%'} />
  ) : (
    <Typography
      fontWeight="bold"
      color="text.primary"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical'
      }}
    >
      {headerText}
    </Typography>
  )
}

const ShoppingHeader = ({
  customerId,
  customerName,
  handleBackClick,
  isLoading,
  onViewDetails
}: {
  customerId: string | null
  customerName?: string
  handleBackClick: () => void
  isLoading: boolean
  onViewDetails: () => void
}) => {
  const headerText = customerName
    ? `Shopping for ${customerName}`
    : 'Shopping style board'

  return (
    <Box>
      <BackToBoardButton onClick={handleBackClick} />
      <Box sx={{ ml: 5, py: 1 }}>
        <HeaderText isLoading={isLoading} headerText={headerText} />
        {customerId && <ViewDetailsButton onClick={onViewDetails} />}
      </Box>
    </Box>
  )
}

const HeaderTemplate = ({
  customerId,
  handleClick,
  headerText,
  icon,
  iconButtonLabel,
  isLoading,
  onViewDetails
}: {
  customerId: string | null
  handleClick: () => void
  headerText: string
  icon: ReactElement
  iconButtonLabel: string
  isLoading: boolean
  onViewDetails: () => void
}) => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton aria-label={iconButtonLabel} onClick={handleClick}>
        {icon}
      </IconButton>
      <Box display="flex" flexDirection="column" sx={{ ml: 2, py: 2 }}>
        <HeaderText isLoading={isLoading} headerText={headerText} />
        {customerId && <ViewDetailsButton onClick={onViewDetails} />}
      </Box>
    </Box>
  )
}

const ReviewHeader = ({
  customerId,
  customerName,
  handleBackClick,
  isLoading,
  isOutsideQueueRequest,
  isPublicBoard,
  onViewDetails
}: {
  customerId: string | null
  customerName?: string
  handleBackClick: () => void
  isLoading: boolean
  isOutsideQueueRequest: boolean
  isPublicBoard: boolean
  onViewDetails: () => void
}) => {
  const headerText = customerName
    ? `Style board for ${customerName}`
    : isOutsideQueueRequest
    ? 'Style board for customer'
    : isPublicBoard
    ? 'Public style board'
    : 'Style board'

  return (
    <HeaderTemplate
      customerId={customerId}
      handleClick={handleBackClick}
      headerText={headerText}
      icon={<ChevronLeft color="secondary" />}
      iconButtonLabel={'back'}
      isLoading={isLoading}
      onViewDetails={onViewDetails}
    />
  )
}

const EditHeader = ({
  customerId,
  customerName,
  handleBackClick,
  isLoading,
  onViewDetails
}: {
  customerId: string | null
  customerName?: string
  handleBackClick: () => void
  isLoading: boolean
  onViewDetails: () => void
}) => {
  const headerText = customerName
    ? `Edit board for ${customerName}`
    : 'Edit style board'

  return (
    <HeaderTemplate
      customerId={customerId}
      handleClick={handleBackClick}
      headerText={headerText}
      icon={<CloseIcon color="secondary" />}
      iconButtonLabel={'close'}
      isLoading={isLoading}
      onViewDetails={onViewDetails}
    />
  )
}

const CurationMenuBar = ({
  customerId,
  handleBackClick,
  isEditRequest,
  isOutsideQueueRequest,
  isPublicBoard,
  onViewDetails,
  shouldShowShoppingHeader
}: {
  customerId: string | null
  handleBackClick: () => void
  isEditRequest: boolean
  isOutsideQueueRequest: boolean
  isPublicBoard: boolean
  onViewDetails: () => void
  shouldShowShoppingHeader: boolean
}): ReactElement => {
  const { data, isLoading } = useGetCustomerQuery(customerId ?? skipToken)

  const customerName = data?.firstName
    ? `${data?.firstName} ${data?.lastName}`
    : ''

  const curation = useAppSelector(curationSelector)

  const onViewCustomer = () => {
    const attributes = {
      curationId: curation.id,
      ocpId: customerId,
      preferredEmployeeId: curation.preferredEmployee,
      page: isEditRequest
        ? 'edit'
        : shouldShowShoppingHeader
        ? 'shopping'
        : 'review',
      type: curation.type
    }

    generateNewRelicLogs('viewCustomerDetails', attributes)
    whenCustomerInfoViewed()
    onViewDetails()
  }

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: 'background.default',
          borderBottom: 1,
          boxShadow: 0
        }}
      >
        <Toolbar>
          {shouldShowShoppingHeader ? (
            <ShoppingHeader
              customerId={customerId}
              customerName={customerName}
              isLoading={isLoading}
              handleBackClick={handleBackClick}
              onViewDetails={onViewCustomer}
            />
          ) : isEditRequest ? (
            <EditHeader
              customerId={customerId}
              customerName={customerName}
              handleBackClick={handleBackClick}
              isLoading={isLoading}
              onViewDetails={onViewCustomer}
            />
          ) : (
            <ReviewHeader
              customerId={customerId}
              customerName={customerName}
              handleBackClick={handleBackClick}
              isLoading={isLoading}
              isOutsideQueueRequest={isOutsideQueueRequest}
              isPublicBoard={isPublicBoard}
              onViewDetails={onViewCustomer}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default CurationMenuBar
