import { Alert, Box, Button, LinearProgress, Typography } from '@mui/material'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import SummaryBoxWithIcon from '../SummaryBoxWithIcon'

const GenerateAISummaryLoading = (): JSX.Element => {
  return (
    <Box textAlign="center">
      <LinearProgress sx={{ mb: 1 }} />
      <Typography variant="caption" color="text.secondary">
        Generating... this may take a second
      </Typography>
    </Box>
  )
}

const AISummary = ({
  isLoading,
  onClick,
  enoughRecentOrdersForDossier,
  isNotEnoughOrdersError,
  error
}: {
  isLoading: boolean
  onClick: () => void
  enoughRecentOrdersForDossier: boolean
  isNotEnoughOrdersError: (
    error?: FetchBaseQueryError | SerializedError
  ) => boolean
  error?: FetchBaseQueryError | SerializedError
}): JSX.Element => {
  const isShowingButton = ({
    isLoading,
    enoughRecentOrdersForDossier,
    error
  }: {
    isLoading: boolean
    enoughRecentOrdersForDossier: boolean
    error?: FetchBaseQueryError | SerializedError
  }) =>
    !isLoading && enoughRecentOrdersForDossier && !isNotEnoughOrdersError(error)

  const isShowingNotEnoughPurchasesMessage = ({
    isLoading,
    enoughRecentOrdersForDossier,
    isNotEnoughOrdersError
  }: {
    isLoading: boolean
    enoughRecentOrdersForDossier: boolean
    isNotEnoughOrdersError: boolean
  }) => !isLoading && (!enoughRecentOrdersForDossier || isNotEnoughOrdersError)

  return (
    <SummaryBoxWithIcon>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" fontWeight="bold">
          Generate customer summary
        </Typography>
        <Typography variant="body2">
          Get a summary of this customer based on their purchases and other
          information.
        </Typography>
        <Box mt={2}>
          {isLoading && <GenerateAISummaryLoading />}
          {isShowingButton({
            isLoading,
            enoughRecentOrdersForDossier,
            error
          }) && (
            <Button onClick={onClick} variant="outlined" sx={{ maxWidth: 80 }}>
              Generate
            </Button>
          )}
          {isShowingNotEnoughPurchasesMessage({
            isLoading,
            enoughRecentOrdersForDossier,
            isNotEnoughOrdersError: isNotEnoughOrdersError(error)
          }) && (
            <Alert
              severity="info"
              sx={{
                bgcolor: '#F9FAFA',
                pl: 0,
                pr: 1,
                pt: 0,
                '& .MuiAlert-icon': { mr: 1 },
                '& .MuiAlert-message': { color: 'info.main' }
              }}
            >
              Not enough recent purchase info to generate summary for this
              customer
            </Alert>
          )}
        </Box>
      </Box>
    </SummaryBoxWithIcon>
  )
}

export default AISummary
