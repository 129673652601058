import { ReactElement } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import ItemImage from 'components/ItemImage'
import PriceBlock from 'components/PriceBlock'
import { EnticementDetailsT } from 'types/Product'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

interface PropsT {
  brandName?: string
  color?: string
  currentPrice?: number
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  itemName?: string
  itemNumber?: string
  onClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  regularPrice?: number
  size?: string
  webStyleId?: string
  miniPDPOpenedFrom: MiniPDPLocationsT
  rmsSku?: string
  lastPurchased?: string
  isStyleOnlinePurchasable?: boolean
  isOnlinePurchasableStatusLoading?: boolean
}

const ItemDetails = (props: PropsT): ReactElement => {
  const {
    brandName,
    color,
    currentPrice,
    enticements,
    imageUrl,
    itemName,
    itemNumber,
    onClickForMiniPDP,
    regularPrice,
    size,
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku,
    lastPurchased,
    isStyleOnlinePurchasable,
    isOnlinePurchasableStatusLoading
  } = props

  const shouldShowSoldOut =
    !isOnlinePurchasableStatusLoading &&
    webStyleId &&
    isStyleOnlinePurchasable === false

  return (
    <Box display="flex">
      {webStyleId && (
        <Button
          aria-label="view product details"
          onClick={() => {
            if (webStyleId && onClickForMiniPDP && rmsSku) {
              onClickForMiniPDP({
                webStyleId: webStyleId.toString(),
                miniPDPOpenedFrom,
                rmsSku
              })
            }
          }}
          sx={{ maxWidth: '74px', padding: 0 }}
        >
          <ItemImage
            autoHeight={true}
            imageAltText={`${brandName} ${itemName}`}
            imageUrl={imageUrl}
          />
        </Button>
      )}
      <Stack sx={{ ml: 1 }}>
        <Typography
          mb={0.5}
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
        >
          <strong>{brandName && `${brandName}`}</strong>{' '}
          {itemName && `${itemName}`}
        </Typography>
        <Typography
          mb={1}
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {size && `${size},`} {color && `${color}`}
        </Typography>
        {shouldShowSoldOut ? (
          <Typography variant="body2" fontWeight="bold">
            Sold out
          </Typography>
        ) : (
          <PriceBlock
            enticements={enticements}
            price={{
              current: currentPrice,
              original: regularPrice
            }}
          />
        )}
        <Typography color="text.secondary" variant="caption" mt={1}>
          {itemNumber && !lastPurchased && `Item #${itemNumber}`}
          {lastPurchased &&
            `Last purchased on: ${new Date(lastPurchased).toLocaleDateString(
              'en-US'
            )}`}
        </Typography>
      </Stack>
    </Box>
  )
}

export default ItemDetails
