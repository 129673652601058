import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { colors } from 'pages/CustomerDetails/constants'

const NoFollowUps = (): ReactElement => {
  return (
    <Box marginBottom="16px">
      <Typography variant="body2" color={colors.subtitleGrey} fontSize="18px">
        No outstanding follow ups
      </Typography>
    </Box>
  )
}

export default NoFollowUps
