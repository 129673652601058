import { Button } from '@mui/material'
import { ReactElement } from 'react'
import SearchIcon from '@mui/icons-material/Search'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const SearchButton = (props: PropsT): ReactElement => {
  const { onClick } = props

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      startIcon={<SearchIcon />}
      data-testid={'open-search-drawer-button'}
    >
      Search
    </Button>
  )
}

export default SearchButton
