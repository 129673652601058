import { Box, useTheme } from '@mui/material'
import {
  useGenerateAppointmentsCustomerDossierMutation,
  useGenerateCustomerDossierMutation,
  useGetCustomerQuery,
  useValidateCustomerDossierMutation
} from 'services/employeeExperienceApi'
import { CustomerDossierT } from 'types/CustomerDossier'
import {
  CustomerDossierStatusT,
  DossierLogsAttributesT,
  DossierOriginT,
  generateCustomerSummaryLog
} from './helpers'
import {
  DOSSIER_STORAGE_KEY,
  getDossierItemFromLocalStorage,
  addDossierItemToLocalStorage
} from 'utils/customerDossier'
import PageError from 'components/PageError'
import oktaTokenStorage from 'utils/okta-token-utils'
import CustomerSummaryBlock from './components/CustomerSummaryBlock'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import AISummary from './components/AISummary'

const NOT_ENOUGH_ORDERS_ERROR =
  'Customer does not have enough orders in their purchase history'

interface PropsT {
  dossierOrigin: DossierOriginT
  dossierIdentifier: string | number
  customerId: string
  enoughRecentOrdersForDossier: boolean
  dossierLogsAttributes: DossierLogsAttributesT
}

const CustomerDossier = ({
  dossierOrigin,
  dossierIdentifier,
  customerId,
  enoughRecentOrdersForDossier,
  dossierLogsAttributes
}: PropsT): JSX.Element => {
  const theme = useTheme()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const { data: customerData } = useGetCustomerQuery(customerId, {
    skip: !customerId
  })

  const generateDossierErrorTitle = 'Issue generating summary - try again'
  const generateCustomerDossier = useGenerateCustomerDossierMutation()
  const generateAppointmentsCustomerDossier =
    useGenerateAppointmentsCustomerDossierMutation()

  const [
    generateDossier,
    {
      data,
      isLoading: isGenerateDossierLoading,
      isError: isGenerateDossierError,
      endpointName,
      error
    }
  ] =
    dossierOrigin === 'curation'
      ? generateCustomerDossier
      : generateAppointmentsCustomerDossier

  const customerSummary =
    getDossierItemFromLocalStorage<CustomerDossierT | undefined>(
      dossierIdentifier,
      DOSSIER_STORAGE_KEY
    ) ||
    data ||
    null

  const [
    validateDossier,
    // eslint-disable-next-line
    {}
  ] = useValidateCustomerDossierMutation()

  const onGenerateSummaryClick = () => {
    const generateDossierParams =
      dossierOrigin === 'curation'
        ? {
            customerId,
            shopperId: customerData?.shopperId,
            curationId: dossierIdentifier
          }
        : {
            customerId
          }

    generateDossier(generateDossierParams)
      .unwrap()
      .then((customerDossier) => {
        addDossierItemToLocalStorage<CustomerDossierT>({
          dossierIdentifier,
          data: customerDossier,
          storageKey: DOSSIER_STORAGE_KEY
        })

        if (dossierIdentifier) {
          generateCustomerSummaryLog({
            dossierOrigin,
            dossierLogsAttributes,
            status: CustomerDossierStatusT.SUCCESS
          })

          // validate about 1 of every 20 dossiers generated, it is only for curation
          if (
            dossierOrigin === 'curation' &&
            dossierIdentifier &&
            Number(dossierIdentifier) % 20 === 0
          ) {
            validateDossier({
              customerId,
              shopperId: customerData?.shopperId,
              curationId: Number(dossierIdentifier),
              customerDossier
            })
          }
        }
        return customerDossier
      })
      .catch(() => {
        generateCustomerSummaryLog({
          dossierOrigin,
          dossierLogsAttributes,
          status: CustomerDossierStatusT.ERROR
        })
      })
  }

  const isNotEnoughOrdersError = (
    error?: FetchBaseQueryError | SerializedError
  ) => {
    // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#error-result-example
    if (error) {
      if ('status' in error) {
        const errorData = error.data as { error: string }
        return errorData?.error === NOT_ENOUGH_ORDERS_ERROR
      }
    }

    return false
  }

  return (
    <Box p={3} sx={{ backgroundColor: theme.palette.grey[50] }} mt={2} mb={2}>
      {customerSummary && (
        <CustomerSummaryBlock
          dossierOrigin={dossierOrigin}
          dossierIdentifier={dossierIdentifier}
          dossierLogsAttributes={dossierLogsAttributes}
          customerName={customerData?.firstName}
          data={customerSummary}
        />
      )}
      {!customerSummary && (
        <AISummary
          isLoading={isGenerateDossierLoading}
          onClick={onGenerateSummaryClick}
          enoughRecentOrdersForDossier={enoughRecentOrdersForDossier}
          isNotEnoughOrdersError={isNotEnoughOrdersError}
          error={error}
        />
      )}
      {isGenerateDossierError && !isNotEnoughOrdersError(error) && (
        <PageError
          customBoxStyle={{ backgroundColor: theme.palette.grey[50] }}
          customAlertStyle={{
            backgroundColor: theme.palette.grey[50],
            color: 'error.main',
            '& .MuiAlert-icon': {
              color: 'error.main'
            }
          }}
          isInlineAlert={true}
          errorTitle={generateDossierErrorTitle}
          shouldShowRefresh={false}
          errorDetails={{
            endpoint: endpointName,
            errorData: error,
            identifiers: {
              customerId: customerId,
              curationId: dossierIdentifier ?? 'NO-DATA',
              employeeId
            }
          }}
        />
      )}
    </Box>
  )
}

export default CustomerDossier
