import { ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import {
  useTheme,
  Box,
  Stack,
  Alert,
  AlertTitle,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useGetConsentedCustomerQuery } from 'services/clientelingApi'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import useWindowDimensions from 'utils/useWindowDimensions'
import WishList from 'components/WishList'
import RecentPurchasesList from '../../../../SnapshotDrawer/pages/RecentPurchasesList'
import ConsentedCustomerInfo from '../../../../CustomerDetails/components/ConsentedCustomerInfo'
import LinkCopiedSnackbar from '../../../../CustomerDetails/components/LinkCopiedSnackbar'
import TabPanel from '../../../../CustomerDetails/components/TabPanel'
import StyleBoards from '../../../../CustomerDetails/components/StyleBoards'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  CONVERSATION_FAILED_MESSAGE,
  NO_SAVED_NUMBER_ERROR,
  MAP_TAB_POSITION_TO_NR_EVENT
} from '../../../../CustomerDetails/constants'
import isEmpty from 'lodash/isEmpty'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import BaseSnackbar from 'components/BaseSnackbar'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'

type StateLinkT = {
  isStyleBoardLinkCopySuccess?: boolean
  firstName: string
  lastName: string
}

type PropsT = {
  customerId?: string
  isCustomerDataLoading: boolean
}

const CustomerDetailsTabs = ({
  customerId,
  isCustomerDataLoading
}: PropsT): ReactElement => {
  const { state } = useLocation<StateLinkT>()
  const { setDisplayAppBar } = useNavigation()
  const theme = useTheme()
  const { height } = useWindowDimensions()
  const featureFlags = useFeatureFlags()

  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false)
  const [isFullPageDisabled, setIsFullPageDisabled] = useState(false)
  const [tabPosition, setTabPosition] = useState(0)
  const [didConversationCreationFail, setDidConversationCreationFailed] =
    useState(false)
  const [conversationCreationError, setConversationCreationError] = useState('')
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()

  const {
    isClientelingAnniversaryStylingAppEnabled,
    isFollowUpsFeatureEnabled
  } = featureFlags
  const nrCustomerDetailsEventAttributes = {
    page: NR_CLIENTELING_PAGES.CUSTOMER_APPOINTMENT_PAGE,
    customerId
  }

  useEffect(() => {
    if (!isEmpty(featureFlags)) {
      setFeatureFlagsLoaded(true)
    }
  }, [featureFlags])

  useEffect(() => {
    const storedTabPosition = localStorage.getItem('tabPosition')
    if (storedTabPosition) {
      setTabPosition(Number(storedTabPosition))
    }
  }, [])

  useEffect(() => {
    setDisplayAppBar(false)
  }, [setDisplayAppBar])

  const {
    data,
    isLoading,
    isError,
    endpointName,
    error,
    originalArgs,
    refetch
  } = useGetConsentedCustomerQuery(
    {
      customerId: customerId ?? '',
      retrieveQualification:
        !!isClientelingAnniversaryStylingAppEnabled?.active,
      retrieveSavedSizes: true,
      retrieveShoppedBrands: true,
      retrieveFollowUps: !!isFollowUpsFeatureEnabled?.active,
      fetchAdditionalCustomerIds: true,
      relationshipBypass: true
    },
    { skip: !featureFlagsLoaded || !customerId }
  )

  const nonConsentedErrorTitle = 'Customer details not available'
  const nonConsentedErrorDescription =
    'This customer hasn’t consented to share their personal details.'
  const apiErrorTitle = 'Issue getting customer details.'
  const snackBarAlertTitle = didConversationCreationFail
    ? CONVERSATION_FAILED_MESSAGE
    : NO_SAVED_NUMBER_ERROR

  const customerFullName = state?.firstName
    ? `${state.firstName} ${state?.lastName}`
    : data?.customer.firstName && data?.customer.lastName
    ? `${data.customer.firstName} ${data.customer.lastName}`
    : ''

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    generateNewRelicLogs(
      MAP_TAB_POSITION_TO_NR_EVENT[newValue].NR_EVENT,
      nrCustomerDetailsEventAttributes
    )
    setTabPosition(newValue)
    localStorage.setItem('tabPosition', String(newValue))
  }

  useEffect(() => {
    const storedTabPosition = localStorage.getItem('tabPosition')
    if (storedTabPosition) {
      setTabPosition(Number(storedTabPosition))
    }
  }, [])

  if (isLoading || isCustomerDataLoading) {
    return <LoadingSpinner />
  }

  if (!customerId) {
    return (
      <Box>
        <Alert severity="info">
          <AlertTitle>This customer doesn&#39;t have an account</AlertTitle>
          Additional details, past purchases, wish lists and style boards are
          not available.
        </Alert>
      </Box>
    )
  }

  return (
    <>
      <Stack
        direction="column"
        position="sticky"
        zIndex="1000"
        bgcolor="white"
        top="0"
      >
        {!customerFullName && (
          <Box
            sx={{
              backgroundColor: 'white',
              mb: '8px',
              [theme.breakpoints.up('md')]: {
                alignSelf: 'center'
              }
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  paddingX: 2
                }
              }}
            >
              {!customerFullName && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  <AlertTitle>Customer name is not on file</AlertTitle>
                  Customer can add their name in account on Nordstrom.com.
                </Alert>
              )}
            </Box>
          </Box>
        )}
        <Tabs
          value={tabPosition}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <Tab
            value={0}
            label={MAP_TAB_POSITION_TO_NR_EVENT[0].LABEL}
            sx={{ minWidth: 'fit-content', flex: 1 }}
          />
          <Tab
            value={1}
            label={MAP_TAB_POSITION_TO_NR_EVENT[1].LABEL}
            sx={{ minWidth: 'fit-content', flex: 1 }}
          />
          <Tab
            value={2}
            label={MAP_TAB_POSITION_TO_NR_EVENT[2].LABEL}
            sx={{ minWidth: 'fit-content', flex: 1 }}
          />
          <Tab
            value={3}
            label={MAP_TAB_POSITION_TO_NR_EVENT[3].LABEL}
            sx={{ minWidth: 'fit-content', flex: 1 }}
          />
        </Tabs>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          pb: tabPosition === 1 ? 0 : '40px',
          minHeight: height - 196
        }}
      >
        <Box>
          {isError && (
            <PageError
              errorTitle={
                error && 'status' in error && error.status === 403
                  ? nonConsentedErrorTitle
                  : apiErrorTitle
              }
              errorDescription={
                error && 'status' in error && error.status === 403
                  ? nonConsentedErrorDescription
                  : undefined
              }
              errorDetails={{
                endpoint: endpointName,
                errorData: error,
                identifiers: {
                  customerId: customerId ?? '',
                  curationId: 'NO-DATA',
                  employeeId,
                  shoppingSessionId: 'NO-DATA'
                },
                originalArgs: originalArgs
              }}
            />
          )}
          {data && (
            <>
              <LoadingBackdrop open={isFullPageDisabled} />
              <TabPanel value={tabPosition} index={0}>
                <ConsentedCustomerInfo
                  customer={data.customer}
                  completedFollowUpsCount={0}
                  endpointName={endpointName}
                  setDidConversationCreationFailed={
                    setDidConversationCreationFailed
                  }
                  setIsFullPageDisabled={setIsFullPageDisabled}
                  setConversationCreationError={setConversationCreationError}
                  refetchCustomerData={refetch}
                  followUpsError={
                    data.customer.errors?.followUps
                      ? {
                          endpoint: endpointName,
                          identifiers: { customerId: data.customer.customerId }
                        }
                      : undefined
                  }
                  isAppointmentsDetails={true}
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={1}>
                <RecentPurchasesList
                  customerId={data.customer.customerId}
                  hideSnapshotNavigation
                  sourcePath={window.location.pathname}
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={2}>
                <WishList
                  customerId={data.customer.customerId}
                  hideSnapshotNavigation
                  isInCustomerDetails={true}
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={3}>
                <StyleBoards
                  customerId={data.customer.customerId}
                  customerName={data.customer.firstName}
                />
              </TabPanel>
            </>
          )}
        </Box>
      </Box>
      {state?.isStyleBoardLinkCopySuccess && <LinkCopiedSnackbar />}
      <BaseSnackbar open={didConversationCreationFail}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDidConversationCreationFailed(false)
                setConversationCreationError('')
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>
            <>
              <strong>{snackBarAlertTitle}</strong>
              {didConversationCreationFail && (
                <CopyErrorData
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  errorDetails={{ errorData: conversationCreationError }}
                />
              )}
            </>
          </AlertTitle>
        </Alert>
      </BaseSnackbar>
      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </>
  )
}

export default CustomerDetailsTabs
