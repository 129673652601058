import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { RELATIONSHIP_STATUS } from 'constants/clienteling/consentConstants'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import PageError from 'components/PageError'
import DossierWrapper from '../DossierWrapper'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useGetConsentedCustomerQuery } from 'services/clientelingApi'
import { copyToClipboard } from 'utils/copyToClipboard'
import BaseSnackbar from 'components/BaseSnackbar'
import { useAppSelector } from 'app/hooks'
import { twilioSelectors } from 'services/twilioSlice'
import { useCreateConversationMutation } from 'services/twilioApi'
import LoadingBackdrop from 'components/LoadingBackdrop'
import CopyErrorData from 'components/CopyErrorData'

export type PropsT = {
  customerName: string
  customerPhone: string
  customerEmail: string
  appointmentId: string
  customerId?: string
  customerRelationshipStatus?: string
  customerInvitationStatus?: string
  isGetCustomerDataLoading: boolean
  isGetCustomerDataError: boolean
  getCustomerDataError: unknown
  getCustomerDataEndpointName: string
}

const CUSTOMER_STATUS: Record<string, string> = {
  NOT_IN_BOOK: 'Not in customer book',
  ALREADY_IN_BOOK: 'In customer book',
  INVITATION_PENDING: 'Invited to customer book'
}

const EMAIL_COPIED_TO_CLIPBOARD =
  'Email address copied. Paste in Outlook to send your message.'

const getCustomerStatus = ({
  relationshipStatus = '',
  invitationStatus = ''
}): string => {
  if (relationshipStatus === RELATIONSHIP_STATUS.VERIFIED) {
    return CUSTOMER_STATUS.ALREADY_IN_BOOK
  }
  if (invitationStatus === RELATIONSHIP_STATUS.PENDING) {
    return CUSTOMER_STATUS.INVITATION_PENDING
  }
  return CUSTOMER_STATUS.NOT_IN_BOOK
}

const CustomerInfo = ({
  customerName,
  customerPhone,
  customerEmail,
  appointmentId,
  customerId,
  customerRelationshipStatus,
  customerInvitationStatus,
  isGetCustomerDataLoading,
  isGetCustomerDataError,
  getCustomerDataError,
  getCustomerDataEndpointName
}: PropsT): ReactElement => {
  const history = useHistory()
  const {
    isAppoCustomerDetailsButtonEnabled,
    isClientelingAnniversaryStylingAppEnabled,
    isFollowUpsFeatureEnabled
  } = useFeatureFlags()

  const [copiedToClipBoard, setCopiedToClipBoard] = useState({
    status: false,
    text: ''
  })
  const [hasCreateConversationError, setHasCreateConversationError] =
    useState(false)

  const {
    data: customerNordstromData,
    isLoading: isCustomerNordstromDataLoading,
    refetch: refetchCustomerData
  } = useGetConsentedCustomerQuery(
    {
      customerId: customerId ?? '',
      retrieveQualification:
        !!isClientelingAnniversaryStylingAppEnabled?.active,
      retrieveSavedSizes: true,
      retrieveShoppedBrands: true,
      retrieveFollowUps: !!isFollowUpsFeatureEnabled?.active,
      fetchAdditionalCustomerIds: true,
      relationshipBypass: true
    },
    { skip: !customerId }
  )

  const [
    createConversation,
    {
      isLoading: isCreateConversationLoading,
      endpointName: createConversationEndpointName,
      error: createConversationError,
      originalArgs: createConversationOriginalArgs
    }
  ] = useCreateConversationMutation()

  const conversation = useAppSelector((state) =>
    twilioSelectors.conversationByCustomerIdSelector(state, customerId || '')
  )

  const statusText = isGetCustomerDataLoading
    ? 'Getting status...'
    : isGetCustomerDataError
    ? 'Error fetching customer status'
    : customerRelationshipStatus || customerInvitationStatus
    ? getCustomerStatus({
        relationshipStatus: customerRelationshipStatus,
        invitationStatus: customerInvitationStatus
      })
    : 'Not in customer book'

  const doesNotHaveRelationship =
    customerRelationshipStatus !== RELATIONSHIP_STATUS.VERIFIED

  const shouldShowCustomersDetailsButton =
    !doesNotHaveRelationship && isAppoCustomerDetailsButtonEnabled?.active

  const buttonText = doesNotHaveRelationship
    ? 'Invite Customer'
    : 'View Details'
  const buttonLink = doesNotHaveRelationship
    ? '/customers/invite'
    : `/customers/details/${customerId}`

  const emailOptOutStatus = customerNordstromData?.customer?.optoutEmail
  const shouldSeeEmailButton =
    !isGetCustomerDataLoading &&
    !isCustomerNordstromDataLoading &&
    !doesNotHaveRelationship &&
    !emailOptOutStatus

  const customerMobilePhone =
    customerNordstromData?.customer?.mobile || customerPhone
  const shouldSeeTextButton =
    !isGetCustomerDataLoading &&
    !isCustomerNordstromDataLoading &&
    customerNordstromData?.customer?.mobile &&
    !doesNotHaveRelationship

  const onClickEmailButton = () => {
    setCopiedToClipBoard({ status: false, text: '' })
    copyToClipboard(customerEmail)
      .then((result) =>
        setCopiedToClipBoard({
          status: result,
          text: EMAIL_COPIED_TO_CLIPBOARD
        })
      )
      .catch((error) => {
        throw error
      })
  }

  const onClickTextButton = async () => {
    try {
      if (conversation) {
        return history.push(`/messages/${conversation.sid}`)
      }
      if (customerMobilePhone && customerId) {
        const newOrExistingConversationSid = (
          await createConversation({
            customerId: customerId
          }).unwrap()
        ).conversationSid
        refetchCustomerData()
        history.push(`/messages/${newOrExistingConversationSid}`)
      }
    } catch (e) {
      setHasCreateConversationError(true)
    }
  }

  return (
    <>
      <LoadingBackdrop open={isCreateConversationLoading} />
      <List
        sx={{
          paddingBottom: '16px',
          bgcolor: 'white'
        }}
      >
        <ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              mb: 2
            }}
          >
            <Box>
              <ListItemText
                primary={
                  <>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      sx={{ color: 'text.primary' }}
                    >
                      Customer
                    </Typography>
                  </>
                }
              />
              <Typography variant="body2">{customerName}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {statusText}
              </Typography>
            </Box>
            <Box>
              {!isGetCustomerDataLoading &&
                (doesNotHaveRelationship ||
                  shouldShowCustomersDetailsButton) && (
                  <Button
                    variant="outlined"
                    data-testid="customerButton"
                    onClick={() => history.push(buttonLink)}
                  >
                    {buttonText}
                  </Button>
                )}
            </Box>
          </Box>
        </ListItem>
        {isGetCustomerDataError && (
          <Box sx={{ mt: 0, mb: 2 }}>
            <PageError
              errorTitle="Error retrieving customer details - "
              errorDetails={{
                errorData: getCustomerDataError,
                endpoint: getCustomerDataEndpointName
              }}
            />
          </Box>
        )}
        <Divider />

        <ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              mb: 2
            }}
          >
            <Box>
              <ListItemText
                primary={
                  <>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      sx={{ color: 'text.primary' }}
                    >
                      Mobile
                    </Typography>
                  </>
                }
              />
              <Typography variant="body2">
                {formatPhoneNumber(customerMobilePhone)}
              </Typography>
            </Box>
            <Box>
              {shouldSeeTextButton && (
                <Button
                  sx={{ mt: 1 }}
                  variant="outlined"
                  data-testid="customerButton"
                  onClick={onClickTextButton}
                >
                  Text
                </Button>
              )}
            </Box>
          </Box>
        </ListItem>
        {customerEmail && (
          <ListItem>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                mb: 2
              }}
            >
              <Box>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{ color: 'text.primary' }}
                      >
                        Email
                      </Typography>
                    </>
                  }
                />
                <Typography variant="body2">{customerEmail}</Typography>
              </Box>
              <Box>
                {shouldSeeEmailButton && (
                  <Button
                    sx={{ mt: 1 }}
                    variant="outlined"
                    data-testid="customerButton"
                    onClick={onClickEmailButton}
                  >
                    Email
                  </Button>
                )}
              </Box>
            </Box>
          </ListItem>
        )}
        {customerId && (
          <DossierWrapper
            customerId={customerId}
            appointmentId={appointmentId}
          />
        )}
      </List>
      {copiedToClipBoard.status && (
        <BaseSnackbar message={copiedToClipBoard.text} />
      )}
      <BaseSnackbar open={hasCreateConversationError}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setHasCreateConversationError(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>
            <>
              <strong>Error creating conversation.</strong>
              {hasCreateConversationError && (
                <CopyErrorData
                  errorDetails={{
                    endpoint: createConversationEndpointName,
                    errorData: createConversationError,
                    originalArgs: createConversationOriginalArgs
                  }}
                />
              )}
            </>
          </AlertTitle>
        </Alert>
      </BaseSnackbar>
    </>
  )
}

export default CustomerInfo
