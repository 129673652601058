export const EDIT_DESCRIPTION = 'edit-description'
export const EDIT_TITLE = 'edit-title'
export const ITEM_NOTE_LABEL = 'Notes (optional)'
export const MAX_DESCRIPTION_LENGTH = 1000
export const MAX_TITLE_LENGTH = 125
export const RESET_EDITS = 'reset-edits'
export const SET_DEACTIVATED_CURATION_PRODUCT =
  'set-deactivated-curation-product-id'
export const SET_REACTIVATED_CURATION_PRODUCT =
  'set-reactivated-curation-product-id'
export const TITLE_MAX_LENGTH_ERROR_TEXT = `Title cannot exceed ${MAX_TITLE_LENGTH} characters`
export const TITLE_REQUIRED_TEXT = 'Title is required'
export const SET_INITIAL_DESCRIPTION = 'set-initial-description'
export const SET_INITIAL_NOTES = 'set-initial-notes'
export const SET_INITIAL_TITLE = 'set-initial-title'
export const UPDATE_EDIT_NOTES = 'update-edit-notes'
export const UPDATE_CHECKOUT_BAG_DATA_NOTES = 'update-checkout-bag-data-notes'
export const SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN =
  'set-is-fashion-map-search-drawer-open'
export const SET_SEARCH_DRAWER_OPEN = 'set-search-drawer-open'
export const SET_IS_QUICK_LINKS_DIALOG_OPEN = 'set-is-quick-links-dialog-open'
export const SET_IS_RECENT_ITEMS_DRAWER_OPEN = 'set-is-recent-items-drawer-open'
export const SET_IS_SCANNING_DRAWER_OPEN = 'set-is-scanning-drawer-open'
export const SET_DUPLICATE_SKUS = 'set-duplicate-skus'
export const IFRAME_PATH = '/edit/build'
export const UPDATE_WISHLIST_ADDS = 'update-wishlist-adds'
export const UPDATE_SCANNER_ADDS = 'update-scanner-adds'
export const UPDATE_SCANNER_VIEWS = 'update-scanner-views'
export const UPDATE_FASHION_MAP_VIEWS = 'update-fashion-map-views'
export const SET_IN_EDIT_CURATION = 'set-in-edit-curation'
export const SET_EDIT_SHOPPING_SESSION_ID = 'set-edit-shopping-session-id'
export const SET_EDIT_SHOPPING_TOKEN = 'set-edit-shopping-token'
export const UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS =
  'update-snapshot-purchase-history-adds'
export const UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS =
  'update-snapshot-purchase-history-views'
export const UPDATE_MINI_PDP_RECENT_ITEM_ADDS =
  'update-mini-pdp-recent-item-adds'
export const UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS =
  'update-mini-pdp-beauty-restock-adds'
export const UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS =
  'update-mini-pdp-scanner-drawer-adds'
export const UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS =
  'update-mini-pdp-search-drawer-adds'
