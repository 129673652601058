/* eslint-disable react/prop-types */
import { ReactElement } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Button, Typography } from '@mui/material'

import RecentPurchasesCard from '../RecentPurchasesCard'
import { OrderT } from 'types/Orders'
import LoadingRecentPurchases from './LoadingRecentPurchases'
import PageError from 'components/PageError'
import oktaTokenStorage from 'utils/okta-token-utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'

export type PropsT = {
  customerId: string
  orders?: Array<OrderT>
  isLoading: boolean
  isError: boolean
  endpointName?: string
  error: unknown
}

const RecentPurchases = (props: PropsT): ReactElement => {
  const { customerId, orders, isLoading, isError, endpointName, error } = props
  const curationDetails = useAppSelector(curationSelector)
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const purchaseHistoryErrorTitle = 'Issue getting purchase history.'

  const RecentOrders = () => {
    return (
      <>
        {orders?.slice(0, 2).map((order: OrderT) => (
          <RecentPurchasesCard
            order={order}
            key={order.orderId}
            employeeId={employeeId}
          />
        ))}
        <Button
          component={ReactRouterLink}
          to={{
            pathname: `/purchase-history`
          }}
          variant="outlined"
          fullWidth
          onClick={() => {
            const attributes = {
              curationId: curationDetails?.id,
              ocpId: customerId,
              type: curationDetails?.type,
              preferredEmployeeId: curationDetails?.preferredEmployee,
              shoppingSessionId: curationDetails?.shoppingSessionId
            }
            generateNewRelicLogs('seeMorePurchasesClick', attributes)
          }}
        >
          See More Purchases
        </Button>
      </>
    )
  }

  const NoOrders = () => (
    <Typography variant="body2" color="text.secondary">
      No purchases
    </Typography>
  )

  const showRecentOrders = orders && orders.length > 0
  return (
    <>
      <Typography fontWeight="bold" mb={2}>
        Purchases
      </Typography>
      {isLoading ? (
        <LoadingRecentPurchases />
      ) : (
        <>
          {isError ? (
            <PageError
              isInlineAlert={true}
              errorTitle={purchaseHistoryErrorTitle}
              errorDetails={{
                endpoint: endpointName,
                errorData: error,
                identifiers: {
                  customerId: customerId,
                  curationId: 'NO-DATA',
                  employeeId,
                  shoppingSessionId: 'NO-DATA'
                }
              }}
            />
          ) : (
            <>{showRecentOrders ? <RecentOrders /> : <NoOrders />}</>
          )}
        </>
      )}
    </>
  )
}

export default RecentPurchases
