import { ReactElement } from 'react'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import RecentItemsNavigation from 'pages/RecentItemsDrawer/components/RecentItemsNavigation'
import BeautyRestocks from './components/BeautyRestocks/BeautyRestocks'
import { BeautyRestockOrderItemT } from 'types/Orders'
import { OnlinePurchasableStatusesByWebStyleIdT } from 'types/Product'
import { Alert, Box, CircularProgress } from '@mui/material'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  onClose: () => void
  open: boolean
  beautyPurchaseHistoryData: BeautyRestockOrderItemT[]
  onlinePurchasableStatusesByWebStyleId: OnlinePurchasableStatusesByWebStyleIdT
  isOnlinePurchasableStatusLoading: boolean
  isGetProductsByWebStyleError: boolean
}

const BeautyRestocksDrawer = (props: PropsT): ReactElement | null => {
  const {
    handleClickForMiniPDP,
    onClose,
    open,
    beautyPurchaseHistoryData,
    onlinePurchasableStatusesByWebStyleId,
    isOnlinePurchasableStatusLoading,
    isGetProductsByWebStyleError
  } = props

  return (
    <Drawer onClose={onClose} open={open}>
      <BoxWithGladlySpace sx={{ px: 2 }}>
        <RecentItemsNavigation
          title="Recent Beauty Purchases"
          onClick={onClose}
        />
        {isOnlinePurchasableStatusLoading && (
          <Box sx={{ width: '100%' }} mb={2}>
            <Alert severity={'info'} icon={false}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1.8}
                ml={1}
                height="40px"
              >
                <CircularProgress size="25px" />
                Checking product availability...
              </Box>
            </Alert>
          </Box>
        )}
        <BeautyRestocks
          handleClickForMiniPDP={handleClickForMiniPDP}
          beautyRestockData={beautyPurchaseHistoryData}
          onlinePurchasableStatusesByWebStyleId={
            onlinePurchasableStatusesByWebStyleId
          }
          isOnlinePurchasableStatusLoading={isOnlinePurchasableStatusLoading}
          isGetProductsByWebStyleError={isGetProductsByWebStyleError}
        />
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default BeautyRestocksDrawer
