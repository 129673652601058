import { ReactElement } from 'react'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useGetPurchaseHistoryQuery } from 'services/employeeExperienceApi'
import ErrorBoundary from 'pages/SnapshotDrawer/components/ErrorBoundary/ErrorBoundary'
import { OrderT } from 'types/Orders'
import moment from 'moment'
import { Box } from '@mui/material'
import CustomerDossier from 'pages/SnapshotDrawer/components/CustomerDossier'
import LoadingSpinner from 'components/LoadingSpinner'

type PropsT = {
  customerId: string
  appointmentId: string
}

const MIN_ORDERS_FOR_DOSSIER = 10
const MAX_ORDER_AGE_FOR_DOSSIER_IN_DAYS = 730

const DossierWrapper = ({
  customerId,
  appointmentId
}: PropsT): ReactElement => {
  const { isAppointmentDetailsDossierEnabled } = useFeatureFlags()
  const shouldShowAppointmentDetailsDossier =
    isAppointmentDetailsDossierEnabled?.active

  const {
    orders,
    isError: phIsError,
    isLoading: phIsLoading
  } = useGetPurchaseHistoryQuery(
    { customerId: customerId },
    {
      selectFromResult: ({
        data,
        isError,
        isLoading,
        error,
        endpointName
      }) => ({
        orders: data?.orders,
        isLoading,
        isError,
        error,
        endpointName
      }),
      skip: !shouldShowAppointmentDetailsDossier
    }
  )

  const enoughOrdersForDossier = (orders?: OrderT[]) => {
    const recentOrdersForDossier = (orders: OrderT[]) =>
      orders.filter(
        (order) =>
          moment().diff(order.orderDate, 'days') <=
          MAX_ORDER_AGE_FOR_DOSSIER_IN_DAYS
      )

    if (!orders) {
      return false
    }
    if (recentOrdersForDossier(orders).length < MIN_ORDERS_FOR_DOSSIER) {
      return false
    }

    return true
  }

  if (!shouldShowAppointmentDetailsDossier) {
    return <></>
  }

  return (
    <Box>
      <ErrorBoundary>
        <>
          {phIsLoading && <LoadingSpinner />}
          {!phIsLoading && (
            <CustomerDossier
              dossierOrigin={'appointment'}
              dossierIdentifier={appointmentId}
              dossierLogsAttributes={{ appointmentId, customerId }}
              customerId={customerId}
              enoughRecentOrdersForDossier={
                // allow generate even if ph call fails
                enoughOrdersForDossier(orders) || phIsError
              }
            />
          )}
        </>
      </ErrorBoundary>
    </Box>
  )
}

export default DossierWrapper
