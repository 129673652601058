import { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  Container,
  Box,
  Typography,
  Divider,
  Stack,
  Button
} from '@mui/material'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { useNavigation } from 'contexts/NavigationContext'
import SnapshotNavigation from '../../components/SnapshotNavigation'
import OrderItem from '../../components/OrderItem'
import OrderInformation from '../../components/OrderInformation'
import { useGetPurchaseHistoryQuery } from 'services/employeeExperienceApi'
import LoadingPurchaseDetails from './LoadingPurchaseDetails'
import {
  ProductsAndStatusT,
  PHOrderSchemaT,
  OrderT,
  AttributedItemT
} from 'types/Orders'
import { pdpLink } from 'utils/pdpLink'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PDP,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_TRACK_PACKAGE
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { useGetConsentedCustomerPurchaseHistoryQuery } from 'services/clientelingApi'
import oktaTokenStorage from 'utils/okta-token-utils'
import { getAttributedItemsFromOrder } from 'pages/SnapshotDrawer/utils'

const OrderStatus = ({
  status,
  trackingUrl,
  isFromCustomerDetails,
  nrCustomerDetailsEventAttributes
}: {
  status: string
  trackingUrl?: string
  isFromCustomerDetails: boolean
  nrCustomerDetailsEventAttributes: {
    page: string
    ocpId: string
    orderId: string
  }
}) => (
  <Box mt={2}>
    <Typography variant="overline">{status}</Typography>
    {trackingUrl && (
      <Box mt={2} mb={1}>
        <Button
          onClick={() => {
            isFromCustomerDetails &&
              generateNewRelicLogs(
                NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_TRACK_PACKAGE,
                nrCustomerDetailsEventAttributes
              )
          }}
          component="a"
          href={trackingUrl}
          target="_blank"
          rel="noopener"
          variant="outlined"
          sx={{
            fontSize: '0.75rem'
          }}
        >
          Track Package
        </Button>
      </Box>
    )}
  </Box>
)

export type ParamType = {
  orderNumber: string
}

export type PropsT = {
  handleClickForPdp: (webStyleId: string) => void
  customerId: string
  routedFromSnapshot?: boolean
  shopperId?: string
  token?: string
}

type LocationStateT = {
  attributedItems?: AttributedItemT[]
}
const RecentPurchaseDetails = (props: PropsT): ReactElement => {
  const {
    handleClickForPdp,
    customerId,
    routedFromSnapshot = false,
    shopperId,
    token
  } = props
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const { orderNumber }: ParamType = useParams()
  const history = useHistory<{ customerId: string; page: number }>()
  const ORDER_COUNT_OFFSET = 10
  const { setDisplayAppBar } = useNavigation()
  const [itemsAdded, setItemsAdded] = useState(new Set<string>())
  const curationDetails = useAppSelector(curationSelector)

  const historyPage = history.location.state?.page || 1
  const schema: PHOrderSchemaT = {
    customerId: customerId || history.location.state?.customerId,
    orderCount: ORDER_COUNT_OFFSET,
    skip: (historyPage - 1) * ORDER_COUNT_OFFSET
  }
  const { state: locationState }: { state?: LocationStateT } = useLocation()
  const { attributedItems: attributedItemsFromLocationState = [] } =
    locationState || {}

  const nrCustomerDetailsEventAttributes = {
    page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
    ocpId: schema?.customerId,
    orderId: orderNumber
  }
  const totalAttributedItemsCountFromLocationState =
    attributedItemsFromLocationState.length
  const hasAttributedItemsFromLocationState =
    !!totalAttributedItemsCountFromLocationState

  const onClickForPDP = (styleId?: string) => {
    if (!styleId) {
      return
    }
    // From Snapshot
    if (handleClickForPdp) {
      return handleClickForPdp(styleId.toString())
    }
    // From Customer Details
    generateNewRelicLogs(NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PDP, {
      ...nrCustomerDetailsEventAttributes,
      webStyleId: styleId
    })
    return window.open(pdpLink(styleId), '_blank')
  }

  const { attributedOrder, attributedOrderIsLoading, attributedOrderIsError } =
    useGetConsentedCustomerPurchaseHistoryQuery(
      {
        customerId: schema.customerId
      },
      {
        selectFromResult: ({ data, isLoading, isError }) => ({
          attributedOrder: data?.orders.find(
            (order) => order.orderId === orderNumber
          ),
          attributedOrderIsLoading: isLoading,
          attributedOrderIsError: isError
        }),
        skip: !hasAttributedItemsFromLocationState && !!locationState
      }
    )

  const attributedItemsFromFetch = attributedOrder
    ? getAttributedItemsFromOrder(attributedOrder, employeeId)
    : []

  const totalAttributedItemsCount =
    totalAttributedItemsCountFromLocationState ||
    attributedItemsFromFetch.length ||
    0
  const hasAttributedItems = !!totalAttributedItemsCount

  const {
    nonAttributedOrder,
    nonAttributedOrderIsLoading,
    nonAttributedOrderIsError
  } = useGetPurchaseHistoryQuery(schema, {
    selectFromResult: ({ data, isLoading, isError }) => ({
      nonAttributedOrder: data?.orders.find(
        (order: OrderT) => order.orderId === orderNumber
      ),
      nonAttributedOrderIsLoading: isLoading,
      nonAttributedOrderIsError: isError
    }),
    skip: hasAttributedItems
  })

  const { order, isLoading, isError } = hasAttributedItems
    ? {
        order: attributedOrder,
        isLoading: attributedOrderIsLoading,
        isError: attributedOrderIsError
      }
    : {
        order: nonAttributedOrder,
        isLoading: nonAttributedOrderIsLoading,
        isError: nonAttributedOrderIsError
      }

  useEffect(() => {
    setDisplayAppBar(false)
    return () => {
      setDisplayAppBar(true)
    }
  }, [setDisplayAppBar])

  const onItemAdded = useCallback(
    (rmsSkuId: string) => {
      setItemsAdded(itemsAdded.add(rmsSkuId))

      generateNewRelicLogs('wishlistItemAddClick', {
        rmsSku: rmsSkuId,
        ...mapCurationSliceToNewRelicLog(curationDetails)
      })
    },
    [curationDetails, itemsAdded, setItemsAdded]
  )

  if (isLoading) {
    return <LoadingPurchaseDetails />
  }

  const ErrorMessage = () => (
    <Typography mt={2} align="center" variant="body2" color="error">
      Oops. Something is not right.
    </Typography>
  )

  const handleBackButtonClick = () => {
    routedFromSnapshot ? history.push('/purchase-history') : history.goBack()
  }

  return (
    <>
      <Container>
        <SnapshotNavigation
          title="Purchase Details"
          onClick={handleBackButtonClick}
        />
        <Box mt={3}>
          {order && !isError && (
            <OrderInformation
              order={order}
              totalAttributedItems={totalAttributedItemsCount}
            />
          )}
        </Box>
      </Container>
      {isError ? (
        <ErrorMessage />
      ) : (
        <>
          {order ? (
            <Stack divider={<Divider />}>
              <Container>
                {order.productsAndStatus.map(
                  (status: ProductsAndStatusT, indexOf) => {
                    return (
                      <div key={indexOf}>
                        <OrderStatus
                          status={status.deliveryStatus}
                          trackingUrl={status.trackingUrl}
                          isFromCustomerDetails={!routedFromSnapshot}
                          nrCustomerDetailsEventAttributes={
                            nrCustomerDetailsEventAttributes
                          }
                        />
                        {status.products.map((product, index) => {
                          const isItemAdded =
                            itemsAdded && itemsAdded.has(product.id)
                          const isAttributedItem =
                            product.commissionSalespersonId === employeeId
                          return (
                            <OrderItem
                              customerId={schema.customerId}
                              product={product}
                              key={`${index}${product.id}`}
                              onClickForPDP={onClickForPDP}
                              isItemAdded={!!isItemAdded}
                              onItemAdded={onItemAdded}
                              routedFromSnapshot={routedFromSnapshot}
                              shopperId={shopperId}
                              token={token}
                              isAttributedItem={isAttributedItem}
                            />
                          )
                        })}
                        {indexOf !== order.productsAndStatus.length - 1 && (
                          <Divider />
                        )}
                      </div>
                    )
                  }
                )}
              </Container>
            </Stack>
          ) : (
            <ErrorMessage />
          )}
        </>
      )}
    </>
  )
}

export default RecentPurchaseDetails
