import { ShopButtonTextOptionsTV2 } from 'components/ShopButton/ShopButtonV2'

export const determineShopButtonTextV2 = ({
  isShoppingTokenExpired,
  shoppingToken,
  isEdit
}: {
  isShoppingTokenExpired: boolean
  shoppingToken: string | null
  isEdit?: boolean
}): ShopButtonTextOptionsTV2 => {
  if (isShoppingTokenExpired) {
    return 'Renew Session'
  }
  if (!shoppingToken || isEdit) {
    return 'Browse N.com'
  }
  return 'Keep Shopping'
}
