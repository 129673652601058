import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import { OrderT } from 'types/Orders'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { formatItemAsSingularOrPlural } from 'pages/SnapshotDrawer/utils'

export type PropsT = {
  displayItemCount?: boolean
  totalAttributedItems?: number
  order: OrderT
}

const isMobileDevice = checkBrowserClient.isMobile()

const OrderInformation = (props: PropsT): ReactElement => {
  const { displayItemCount = true, totalAttributedItems, order } = props
  const {
    storeName,
    orderType,
    orderId,
    orderDate,
    totalPrice,
    totalItemCount
  } = order
  const featureFlags = useFeatureFlags()
  const { isPurchasesWithYouEnabled } = featureFlags

  const formattedDate = new Date(orderDate).toLocaleDateString()
  return (
    <Box display="inline-block">
      <Typography
        variant="subtitle2"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: isMobileDevice ? 'vertical' : 'unset',
          WebkitLineClamp: displayItemCount ? 2 : 1
        }}
      >
        Purchased {storeName || orderType}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {`Order #${orderId} | ${formattedDate} | $${Number(totalPrice).toFixed(
          2
        )}`}
      </Typography>
      {isPurchasesWithYouEnabled?.active && !!totalAttributedItems && (
        <Box
          sx={{
            backgroundColor: 'rgba(0, 107, 226, 0.12)',
            maxHeight: '24px',
            maxWidth: '256px',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '4px'
          }}
        >
          <Typography fontSize="12px" variant="overline" textAlign="center">
            {`Includes ${totalAttributedItems} ${formatItemAsSingularOrPlural(
              totalAttributedItems
            )} purchased with you`}
          </Typography>
        </Box>
      )}
      {displayItemCount && (
        <Typography marginTop="16px" color="text.secondary">
          {`${totalItemCount} ${formatItemAsSingularOrPlural(totalItemCount)}`}
        </Typography>
      )}
    </Box>
  )
}

export default OrderInformation
