import { Box, Typography, Grid } from '@mui/material'
import { twilioSelectors } from 'services/twilioSlice'
import { useGetFollowUpOpportunitiesQuery } from 'services/clientelingApi'
import { useAppSelector } from 'app/hooks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import Card from 'pages/Home/components/Customers/components/Card'
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

interface CardContent {
  count: number
  copy: string
  nrEvent: string
  buttonPath: string
  buttonCopy: string
  isError: boolean
  isLoading: boolean
  renderIcon: () => React.ReactNode
  iconBackgroundColor: string
}

const Customers = (): JSX.Element => {
  const { isFollowUpsFeatureEnabled } = useFeatureFlags()

  const isUnreadMessagesLoading = useAppSelector(
    twilioSelectors.isLoadingSelector
  )
  const unreadMessagesCount: number | null = useAppSelector(
    twilioSelectors.allUnreadMessagesCountSelector
  )
  const errorLoadingUnreadMessages = unreadMessagesCount === null

  const {
    data: followUpData,
    isLoading: followUpDataIsLoading,
    isError: followUpDataIsError
  } = useGetFollowUpOpportunitiesQuery(undefined, {
    skip: !isFollowUpsFeatureEnabled?.active
  })
  const followUpCustomerCount = Object.keys(followUpData ?? {}).length

  const messagesContent: CardContent = {
    count: unreadMessagesCount,
    copy:
      unreadMessagesCount === 1
        ? `${unreadMessagesCount} unread message`
        : `${unreadMessagesCount} unread messages`,
    nrEvent: 'unreadViewMessagesClick',
    buttonPath: '/messages',
    buttonCopy: 'View Messages',
    isError: errorLoadingUnreadMessages,
    isLoading: isUnreadMessagesLoading,
    renderIcon: () => (
      <MarkChatUnreadIcon
        sx={{
          fill: unreadMessagesCount === 0 ? '#6B777B' : '#482FE9',
          fontSize: '18px'
        }}
      />
    ),
    iconBackgroundColor: '#e9e6f7'
  }

  const followUpContent: CardContent = {
    count: followUpCustomerCount,
    copy:
      followUpCustomerCount === 1
        ? `Follow up with ${followUpCustomerCount} customer`
        : `Follow up with ${followUpCustomerCount} customers`,
    nrEvent: 'customerFollowUpClick',
    buttonPath: '/customers/followups',
    buttonCopy: 'Visit Follow Ups',
    isError: followUpDataIsError,
    isLoading: followUpDataIsLoading,
    renderIcon: () => (
      <TaskAltIcon
        sx={{
          fill: followUpCustomerCount === 0 ? '#6B777B' : '#006BE2',
          fontSize: '18px'
        }}
      />
    ),
    iconBackgroundColor: '#ecf5fd'
  }

  return (
    <Box px={2} mt={3}>
      <Typography variant="h6">Customers</Typography>
      <Typography variant="body2">Stay connected with customers</Typography>
      <Grid container spacing={2} mt={2}>
        {isFollowUpsFeatureEnabled && <Card content={followUpContent} />}
        <Card content={messagesContent} />
      </Grid>
    </Box>
  )
}

export default Customers
