import { Box, Button, Typography } from '@mui/material'
import { ExtendedFollowUpT } from 'types/FollowUps'
import { useRef, useState } from 'react'
import FollowUpCards from './FollowUpCards'
import ShowAllFollowUpsButton from './ShowAllFollowUpsButton'
import NoFollowUps from './NoFollowUps'
import { CopyErrorDataT } from 'types/CopyErrorData'
import FollowUpsError from './FollowUpsError'
import { useMarkFollowUpCompletedMutation } from 'services/clientelingApi'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CUSTOMER_DETAILS_PAGE_MARK_AS_COMPLETE } from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'

type PropsT = {
  followUps: ExtendedFollowUpT[]
  customerId: string
  completedFollowUpsCount: number
  error?: CopyErrorDataT
  isPostMVPEnabled?: boolean
}

const MAX_COLLAPSED_FOLLOW_UPS = 3

const FollowUpSuggestions = ({
  followUps,
  customerId,
  completedFollowUpsCount,
  error,
  isPostMVPEnabled
}: PropsT): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null)
  const [isListExpanded, setIsListExpanded] = useState(false)
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const [scrollPosition, setScrollPosition] = useState<number | undefined>(0)

  const featureFlags = useFeatureFlags()
  const {
    isClientelingAnniversaryStylingAppEnabled,
    isCustomerDetailsWithAdditionalIdsEnabled,
    isFollowUpsFeatureEnabled
  } = featureFlags

  const toggleIsListExpanded = () => {
    if (!isListExpanded) {
      setScrollPosition(listRef.current?.scrollHeight)
    } else {
      const clientHeight = listRef.current?.clientHeight
      const top =
        scrollPosition && clientHeight
          ? scrollPosition - clientHeight
          : undefined

      window.scrollTo({ top, behavior: 'smooth' })
    }

    setIsListExpanded(!isListExpanded)
  }

  const [markFollowUpCompleted] = useMarkFollowUpCompletedMutation()

  const checkFollowUp = (
    customerId: string,
    customerFollowUp: ExtendedFollowUpT
  ) => {
    setTimeout(async () => {
      await markFollowUpCompleted({
        employeeId,
        customerId,
        kind: customerFollowUp.followUpKind,
        meta: customerFollowUp.meta?.orderId
          ? { orderId: customerFollowUp.meta.orderId }
          : undefined,
        wasCalledFromDetailsPage: true,
        getCustomerQueryParams: {
          retrieveQualification:
            !!isClientelingAnniversaryStylingAppEnabled?.active,
          retrieveSavedSizes: true,
          retrieveShoppedBrands: true,
          retrieveFollowUps: !!isFollowUpsFeatureEnabled?.active,
          fetchAdditionalCustomerIds:
            !!isCustomerDetailsWithAdditionalIdsEnabled?.active
        }
      })

      generateNewRelicLogs(NR_CUSTOMER_DETAILS_PAGE_MARK_AS_COMPLETE, {
        completedFollowUpKind: customerFollowUp.followUpKind,
        page: NR_CLIENTELING_PAGES.CUSTOMER_DETAILS_PAGE,
        ocpId: customerId,
        employeeId,
        orderId: customerFollowUp.meta?.orderId
      })
    }, 2000)
  }

  return (
    <Box ref={listRef} sx={{ px: '16px', pb: '12px' }}>
      <Typography variant="subtitle2" mt="12px">
        Follow up
      </Typography>

      {followUps.length > 0 ? (
        <FollowUpCards
          followUps={
            isListExpanded
              ? followUps
              : followUps.slice(0, MAX_COLLAPSED_FOLLOW_UPS)
          }
          customerId={customerId}
          onCheckFollowUp={checkFollowUp}
        />
      ) : !error ? (
        <NoFollowUps />
      ) : (
        <FollowUpsError
          error={error}
          customerId={customerId}
          employeeId={employeeId}
        />
      )}

      {followUps.length > MAX_COLLAPSED_FOLLOW_UPS && (
        <ShowAllFollowUpsButton
          isListExpanded={isListExpanded}
          toggleIsListExpanded={toggleIsListExpanded}
        />
      )}

      {completedFollowUpsCount > 0 && isPostMVPEnabled && (
        <Button fullWidth variant="outlined">
          View Completed
        </Button>
      )}
    </Box>
  )
}

export default FollowUpSuggestions
