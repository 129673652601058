import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import FashionMapSearchResultCard from 'pages/FashionMapSearchDrawer/components/FashionMapSearchResultCard'
import SearchError from '../SearchError'
import { FashionMapSearchResultT } from 'types/FashionMapSearch'
import { PolarisSearchResultT } from 'types/PolarisProduct'
import VerticalProductImage from '../../../../components/VerticalProductImage'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

interface PropsT {
  error: unknown
  endpoint: string
  handleClickForPdp?: (styleId: string) => void
  isError: boolean
  isLoading: boolean
  miniPDPOpenedFrom?: MiniPDPLocationsT
  handleClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  polarisResults?: PolarisSearchResultT[]
  results?: FashionMapSearchResultT[]
  shouldShowWideWidths?: boolean
}

const NoSearchResults = (): ReactElement => {
  return (
    <Box mt={2}>
      <Typography fontWeight="bold" variant="subtitle2">
        No search results
      </Typography>
      <Typography
        color="secondary"
        variant="subtitle2"
        sx={{ fontWeight: 'regular' }}
      >
        Check the spelling or try a different term
      </Typography>
    </Box>
  )
}

const ProductSearchResults = (props: PropsT): ReactElement => {
  const {
    error,
    handleClickForPdp,
    isError,
    isLoading,
    endpoint,
    miniPDPOpenedFrom,
    polarisResults,
    handleClickForMiniPDP,
    results,
    shouldShowWideWidths
  } = props

  if (isLoading) {
    return <LoadingSpinner hideText={true} />
  }

  if (isError) {
    return <SearchError endpoint={endpoint} error={error} />
  }

  if (
    (results && results.length === 0) ||
    (polarisResults && polarisResults.length == 0)
  ) {
    return <NoSearchResults />
  }

  return (
    <Box mt={2}>
      <Typography color="secondary" variant="caption">
        {results && results.length === 1
          ? '1 search result'
          : results &&
            results?.length > 0 &&
            `${results.length} search results`}
      </Typography>
      <Box mt={2}>
        {handleClickForPdp &&
          results?.map(({ brand, image, itemName, styleId, price }) => (
            <FashionMapSearchResultCard
              key={styleId}
              brand={brand}
              onClickForPdp={handleClickForPdp}
              image={image}
              itemName={itemName}
              styleId={styleId}
              price={price}
            />
          ))}
        {polarisResults && (
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent={polarisResults.length < 3 ? 'flex-start' : 'center'}
          >
            {polarisResults?.map(
              ({ brandName, imgUrl, price, productName, webStyleId }) => (
                <VerticalProductImage
                  key={webStyleId}
                  brandName={brandName}
                  imgUrl={imgUrl}
                  miniPDPOpenedFrom={miniPDPOpenedFrom}
                  handleClickForMiniPDP={handleClickForMiniPDP}
                  price={price}
                  productName={productName}
                  webStyleId={webStyleId}
                  wideWidth={shouldShowWideWidths}
                />
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ProductSearchResults
