import { useState } from 'react'
import { Card, CardHeader, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { formatFollowUpText } from './formatText'
import { ExtendedFollowUpT } from 'types/FollowUps'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

type PropsT = {
  followUp: ExtendedFollowUpT
  customerId: string
  onClick?: () => void
  onCheckFollowUp: (
    customerId: string,
    customerFollowUp: ExtendedFollowUpT
  ) => void
}

const FollowUpCard = ({
  followUp,
  customerId,
  onClick,
  onCheckFollowUp
}: PropsT): JSX.Element => {
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState<boolean>(false)

  return (
    <Card
      variant="outlined"
      sx={{
        width: 1
      }}
    >
      <CardHeader
        avatar={
          <IconButton
            onClick={() => {
              setIsCheckBoxChecked(!isCheckBoxChecked)
              onCheckFollowUp(customerId, followUp)
            }}
            disabled={isCheckBoxChecked}
          >
            {isCheckBoxChecked ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </IconButton>
        }
        title={formatFollowUpText(followUp.followUpKind)}
        titleTypographyProps={{
          color: isCheckBoxChecked ? 'text.secondary' : 'text.primary'
        }}
        action={
          onClick ? (
            <IconButton data-testid="follow-up-chevron" onClick={onClick}>
              <ChevronRightIcon />
            </IconButton>
          ) : undefined
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          }
        }}
      />
    </Card>
  )
}

export default FollowUpCard
