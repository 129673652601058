import { ReactElement } from 'react'
import { emDashCharacter } from 'pages/constants'
import PageError from 'components/PageError'

interface PropsT {
  endpoint: string
  error: unknown
}

export const ERROR_TITLE = `Issue getting search results ${emDashCharacter} try again`

const SearchError = (props: PropsT): ReactElement => {
  const { endpoint, error } = props

  return (
    <PageError
      errorTitle={ERROR_TITLE}
      errorDetails={{
        endpoint,
        errorData: error
      }}
      isErrorTitlePersonalized={true}
      isFullWidth={true}
      isInlineAlert={true}
    />
  )
}

export default SearchError
