import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppBar,
  Box,
  Container,
  IconButton,
  List,
  ListSubheader,
  Toolbar,
  Typography,
  Card
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CircularProgress from '@mui/material/CircularProgress'
import PageError from 'components/PageError'
import SingleFollowUp from 'pages/FollowUps/components/SingleFollowUp'
import { useCompletedFollowUps } from './useCompletedFollowUps'

const PAGE_SIZE = 50

const CompletedFollowUps = () => {
  const history = useHistory()

  const {
    sectionedFollowUps,
    isFetching,
    isError,
    endpointName,
    error,
    hasMorePages,
    loadMoreFollowUps,
    deleteFollowUp,
    isDeleteCompletedFollowUpLoading
  } = useCompletedFollowUps(PAGE_SIZE)

  const lastFollowUpElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && !isFetching && hasMorePages) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              loadMoreFollowUps()
              observer.disconnect()
            }
          },
          { threshold: 1.0 }
        )

        observer.observe(node)
      }
    },
    [isFetching, hasMorePages, loadMoreFollowUps]
  )

  const goBackAction = () => {
    history.goBack()
  }

  const onFollowUpClick = (customerId: string) => {
    history.push(`/customers/details/${customerId}`)
  }

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar sx={{ marginY: '8px' }}>
          <IconButton
            onClick={goBackAction}
            size="large"
            sx={{ marginRight: '16px' }}
            data-testid="goBackButton"
          >
            <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          <Typography variant="h6" color="text.primary">
            Completed follow ups
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ maxWidth: '1040px' }}>
        {isError ? (
          <PageError
            isFullWidth
            errorTitle="Issue getting your completed follow ups - "
            errorDetails={{ endpoint: endpointName, errorData: error }}
          />
        ) : (
          <>
            <List>
              {Object.keys(sectionedFollowUps).map((dateSection) => (
                <li key={dateSection}>
                  <ul style={{ padding: 0 }}>
                    <ListSubheader sx={{ padding: 0, marginBottom: '12px' }}>
                      <Typography variant="subtitle2" sx={{ color: 'black' }}>
                        {dateSection}
                      </Typography>
                    </ListSubheader>
                    {sectionedFollowUps[dateSection].map((followUp, index) => (
                      <Card
                        key={followUp.id}
                        ref={
                          index === sectionedFollowUps[dateSection].length - 1
                            ? lastFollowUpElementRef
                            : null
                        }
                        sx={{ marginBottom: '12px' }}
                      >
                        <SingleFollowUp
                          followUpKind={followUp.kind}
                          title={`${followUp.customer.firstName} ${followUp.customer.lastName}`}
                          isChecked
                          isDisabled={isDeleteCompletedFollowUpLoading}
                          onFollowUpClick={() =>
                            onFollowUpClick(followUp.customer.customerId)
                          }
                          onToggleFollowUp={() => deleteFollowUp(followUp.id)}
                        />
                      </Card>
                    ))}
                  </ul>
                </li>
              ))}
            </List>
            {isFetching && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export default CompletedFollowUps
