import { ReactElement } from 'react'
import { Box, Card } from '@mui/material'
import GridPanel from 'components/Tabs/GridPanel'
import OrderInformation from '../OrderInformation'

import { OrderT } from 'types/Orders'
import { useHistory } from 'react-router-dom'
import { getAttributedItemsFromOrder } from 'pages/SnapshotDrawer/utils'

export type PropsT = {
  order: OrderT
  employeeId: string
}

const RecentPurchasesCard = (props: PropsT): ReactElement => {
  const history = useHistory()
  const { order, employeeId } = props
  const products = order.productsAndStatus[0].products
  const attributedItems = getAttributedItemsFromOrder(order, employeeId)
  const handleOrderPreviewClick = () => {
    history.push({
      pathname: `/purchase-history/order/${order.orderId}`,
      state: { attributedItems }
    })
  }
  return (
    <Card
      variant="outlined"
      sx={{ mb: 2, py: 0 }}
      onClick={handleOrderPreviewClick}
    >
      <Box p={2}>
        <OrderInformation order={order} displayItemCount={false} />
      </Box>

      {products && <GridPanel products={products} />}
    </Card>
  )
}

export default RecentPurchasesCard
