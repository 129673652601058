import { useEffect, useMemo } from 'react'
import {
  List,
  Box,
  Container,
  IconButton,
  Typography,
  Toolbar,
  AppBar
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useNavigation } from 'contexts/NavigationContext'
import { useHistory } from 'react-router-dom'
import { checkBrowserClient } from 'utils/userAgentDetector'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { useGetArchivedCustomersQuery } from 'services/clientelingApi'
import { ArchivedCustomerT } from 'types/ConsentedCustomer'
import oktaTokenStorage from 'utils/okta-token-utils'
import { orderArchivedCustomerList } from './utils'
import ArchivedCustomersListItem from './components'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import { NR_CUSTOMER_BOOK_PAGE_VIEW_ARCHIVED_CUSTOMER } from 'constants/clienteling/newRelicEvents/nrCustomerBookPage'

const ArchivedCustomers: () => JSX.Element = () => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const { setDisplayAppBar } = useNavigation()
  const history = useHistory()
  const { data, isLoading, isError, endpointName } =
    useGetArchivedCustomersQuery()
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()

  const archiveCustomerListMap = useMemo(
    () =>
      data?.customers.length ? orderArchivedCustomerList(data?.customers) : [],
    [data]
  )

  const archivedCustomersErrorTitle = 'Error retrieving your archived customers'
  const archivedCustomersErrorDescription =
    'Still not working? Copy error info and paste in support chat.'

  useEffect(() => {
    setDisplayAppBar(false)
    return () => {
      setDisplayAppBar(true)
    }
  }, [setDisplayAppBar])

  const emptyState = () => (
    <Box sx={{ paddingX: isMobileDevice ? '16px' : 0 }}>
      <Typography fontSize={16}>
        Archived customers will be shown here.
      </Typography>
    </Box>
  )

  const renderHeader = () => (
    <AppBar
      position="static"
      style={{ backgroundColor: 'white', boxShadow: 'none' }}
      sx={{
        paddingLeft: '30px'
      }}
    >
      <Toolbar style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '10px',
            flex: 1
          }}
          onClick={() => history.push('/customers')}
        >
          <IconButton aria-label="Back" size="large">
            <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          <Typography ml={2} color="text.primary" variant="h6">
            Archived Customers
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )

  const goToDetails = (customerId: string) => {
    generateNewRelicLogs(NR_CUSTOMER_BOOK_PAGE_VIEW_ARCHIVED_CUSTOMER, {
      page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
      ocpId: customerId
    })
    history.push({
      pathname: `/customers/details/${customerId}`,
      state: { sourcePath: window.location.pathname }
    })
  }

  const archivedCustomersData = () => (
    <List
      sx={{
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto'
      }}
    >
      {archiveCustomerListMap.map((archivedCustomer: ArchivedCustomerT) => (
        <ArchivedCustomersListItem
          key={archivedCustomer.customerId}
          archivedCustomer={archivedCustomer}
          goToDetails={goToDetails}
        />
      ))}
    </List>
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <PageError
        errorTitle={archivedCustomersErrorTitle}
        errorDetails={{
          endpoint: endpointName,
          errorData: archivedCustomersErrorDescription,
          identifiers: {
            customerId: 'NO-DATA',
            curationId: 'NO-DATA',
            employeeId
          }
        }}
      />
    )
  }

  return (
    <>
      {renderHeader()}
      <Container
        sx={{
          maxWidth: 1040,
          height: '100%',
          paddingRight: isMobileDevice && 0,
          paddingLeft: isMobileDevice && 0,
          mt: '20px'
        }}
      >
        {data?.customers?.length ? archivedCustomersData() : emptyState()}
      </Container>
    </>
  )
}

export default ArchivedCustomers
