import { useState } from 'react'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import PolarisSearch from './components/PolarisSearch'
import FashionMapSearch from './components/FashionMapSearch'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import useCurationFashionMapFeatures from 'utils/useCurationFashionMapFeatures'

export enum SEARCH_OPTIONS {
  FASHION_MAP = 'fashion-map',
  POLARIS = 'polaris'
}

interface PropsT {
  handleClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  handleClickForPdp: (webStyleId: string) => void
  onClose: () => void
  open: boolean
}

const SearchToggleButtons = ({
  value,
  handleChange
}: {
  value: SEARCH_OPTIONS
  handleChange: (event: React.MouseEvent<HTMLElement>) => void
}): JSX.Element => {
  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Search platform"
    >
      <ToggleButton value={SEARCH_OPTIONS.POLARIS}>Search</ToggleButton>
      <ToggleButton value={SEARCH_OPTIONS.FASHION_MAP}>
        Concept Search
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const SearchDrawer = ({
  handleClickForMiniPDP,
  handleClickForPdp,
  onClose,
  open
}: PropsT): JSX.Element => {
  const [search, setSearch] = useState<SEARCH_OPTIONS>(SEARCH_OPTIONS.POLARIS)

  const shouldShowSearchToggle = useCurationFashionMapFeatures()

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>) => {
    setSearch((event.target as HTMLButtonElement).value as SEARCH_OPTIONS)
  }

  const handleClose = () => {
    setSearch(SEARCH_OPTIONS.POLARIS)
    onClose()
  }

  return (
    <Drawer onClose={handleClose} open={open}>
      <BoxWithGladlySpace>
        {search === SEARCH_OPTIONS.POLARIS && (
          <PolarisSearch
            handleClickForMiniPDP={handleClickForMiniPDP}
            miniPDPOpenedFrom="SearchDrawer"
            onClose={handleClose}
            searchToggleButtons={
              shouldShowSearchToggle ? (
                <SearchToggleButtons
                  value={search}
                  handleChange={handleToggleChange}
                />
              ) : null
            }
          />
        )}
        {search === SEARCH_OPTIONS.FASHION_MAP && (
          <FashionMapSearch
            handleClickForPdp={handleClickForPdp}
            onClose={handleClose}
            searchToggleButtons={
              shouldShowSearchToggle ? (
                <SearchToggleButtons
                  value={search}
                  handleChange={handleToggleChange}
                />
              ) : null
            }
          />
        )}
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default SearchDrawer
