import { useState, useEffect, useCallback, useMemo } from 'react'
import unionBy from 'lodash/unionBy'
import flow from 'lodash/fp/flow'
import {
  useGetCompletedFollowUpsForEmployeeQuery,
  useDeleteFollowUpOpportunityMutation
} from 'services/clientelingApi'
import { CompletedFollowUpsForEmployeeResponseT } from 'types/FollowUps'
import { getTime } from 'utils/date-utils'

export const useCompletedFollowUps = (pageSize: number) => {
  const [page, setPage] = useState(1)
  const [sectionedFollowUps, setSectionedFollowUps] = useState<
    Record<string, CompletedFollowUpsForEmployeeResponseT[]>
  >({})

  const {
    data: completedFollowUps,
    isFetching,
    isError,
    endpointName,
    error
  } = useGetCompletedFollowUpsForEmployeeQuery({
    page,
    pageSize
  })

  const [
    deleteFollowUpTrigger,
    { isLoading: isDeleteCompletedFollowUpLoading }
  ] = useDeleteFollowUpOpportunityMutation()

  const groupFollowUpsByDate = useCallback(
    (followUps: CompletedFollowUpsForEmployeeResponseT[]) => {
      return followUps.reduce((groupedFollowUps, followUp) => {
        const formattedDate = getTime(followUp.createdAt)
        const followUpsForDate = groupedFollowUps.get(formattedDate) || []
        groupedFollowUps.set(formattedDate, [...followUpsForDate, followUp])
        return groupedFollowUps
      }, new Map<string, CompletedFollowUpsForEmployeeResponseT[]>())
    },
    []
  )

  const mergeWithPreviousState = useCallback(
    (
      newState: Map<string, CompletedFollowUpsForEmployeeResponseT[]>,
      prevState: Record<string, CompletedFollowUpsForEmployeeResponseT[]>
    ) => {
      return Array.from(newState).reduce((mergedState, [date, followUps]) => {
        const followUpsForDate = mergedState.get(date) || []
        const updatedFollowUpsForDate = unionBy(
          followUpsForDate,
          followUps,
          'id'
        )
        mergedState.set(date, updatedFollowUpsForDate)
        return mergedState
      }, new Map(Object.entries(prevState)))
    },
    []
  )

  const removeEmptyCategories = useCallback(
    (state: Map<string, CompletedFollowUpsForEmployeeResponseT[]>) => {
      return new Map<string, CompletedFollowUpsForEmployeeResponseT[]>(
        Array.from(state.entries()).filter(
          ([, followUps]) => followUps.length > 0
        )
      )
    },
    []
  )

  useEffect(() => {
    if (completedFollowUps?.data.length) {
      setSectionedFollowUps((prevState) =>
        flow([
          groupFollowUpsByDate,
          (groupedState) => mergeWithPreviousState(groupedState, prevState),
          removeEmptyCategories,
          Object.fromEntries
        ])(completedFollowUps.data)
      )
    }
  }, [
    completedFollowUps,
    groupFollowUpsByDate,
    mergeWithPreviousState,
    removeEmptyCategories
  ])

  const hasMorePages = useMemo(
    () => (completedFollowUps ? page < completedFollowUps.totalPages : false),
    [completedFollowUps, page]
  )

  const loadMoreFollowUps = useCallback(
    () =>
      setPage((prevPage) => {
        if (prevPage === completedFollowUps?.currentPage) {
          return prevPage + 1
        }
        return prevPage
      }),
    [completedFollowUps?.currentPage]
  )

  const removeDeletedItemFromState = (followUpId: number) => {
    setSectionedFollowUps((prevState) => {
      return Object.entries(prevState).reduce((acc, [date, followUps]) => {
        const deletedItem = followUps.find(
          (followUp) => followUp.id === followUpId
        )
        const updatedItems = followUps.filter(
          (followUp) => followUp !== deletedItem
        )
        if (updatedItems.length === 0) {
          delete acc[date]
        } else {
          acc[date] = updatedItems
        }
        return acc
      }, {} as Record<string, CompletedFollowUpsForEmployeeResponseT[]>)
    })
  }

  const deleteFollowUp = useCallback(
    async (followUpId: number) => {
      await deleteFollowUpTrigger({ id: followUpId })
      removeDeletedItemFromState(followUpId)
    },
    [deleteFollowUpTrigger]
  )

  return {
    sectionedFollowUps,
    isFetching,
    isError,
    endpointName,
    error,
    hasMorePages,
    loadMoreFollowUps,
    deleteFollowUp,
    isDeleteCompletedFollowUpLoading
  }
}
