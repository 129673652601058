import { CircularProgress, Grid } from '@mui/material'
import { ReactElement } from 'react'

interface PropsT {
  searchButton: ReactElement
  quickLinksButton: ReactElement
  recentItemsButton: ReactElement
  scanButton: ReactElement
  shopButton: ReactElement
  shouldShowLoadingButton: boolean
  shouldShowScanButton: boolean
  shouldShowSyncButton: boolean
  syncButton: ReactElement
  isSnapshotOpen: boolean
}

const renderSyncButton = (
  button: ReactElement,
  shouldShowButton: boolean
): ReactElement | null => {
  if (shouldShowButton) {
    return button
  }
  return null
}

const ReviewButtonGrid = (props: PropsT): ReactElement => {
  const {
    searchButton,
    quickLinksButton,
    recentItemsButton,
    scanButton,
    shopButton,
    shouldShowLoadingButton,
    shouldShowScanButton,
    shouldShowSyncButton,
    syncButton: syncButtonElement,
    isSnapshotOpen
  } = props

  const gridSize = isSnapshotOpen ? 5 : 4

  return (
    <Grid container flexDirection="column" alignItems="center" mb={3}>
      <Grid container item xs={12} sm={8} md={5} lg={gridSize} gap={1}>
        {searchButton}
        <Grid container flexDirection="row" justifyContent="center" spacing={1}>
          <Grid item xs={6}>
            {shopButton}
          </Grid>
          {shouldShowScanButton && (
            <Grid item xs={6}>
              {scanButton}
            </Grid>
          )}
          <Grid item xs={6}>
            {recentItemsButton}
          </Grid>
          <Grid item xs={6}>
            {quickLinksButton}
          </Grid>
          <Grid item>
            {shouldShowLoadingButton ? (
              <CircularProgress />
            ) : (
              renderSyncButton(syncButtonElement, shouldShowSyncButton)
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReviewButtonGrid
