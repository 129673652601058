import { TotalPriceRangeT } from './Product'

interface ImageT {
  url?: string
  altText?: string
  color?: string
}

export interface FashionMapSearchResultT {
  brand?: string
  image: ImageT
  itemName?: string
  styleId: string
  similarityScore?: number
  price?: TotalPriceRangeT
}

export interface FashionMapSearchResultsT {
  results: FashionMapSearchResultT[]
}

// TODO: Maybe we do not need this
export type GendersT = 'male' | 'female' | 'Male' | 'Female'

interface FashionMapFiltersT {
  productTypes: string[]
  brands: string[]
  genders: GendersT[]
}

export interface FashionMapFilterResultsT {
  results: FashionMapFiltersT
}

export type SelectFilterFunctionT = ({
  currentFilter,
  isSelected
}: {
  currentFilter: string
  isSelected: boolean
}) => void

export enum DEPARTMENTS {
  WOMENSWEAR = 'Womenswear',
  MENSWEAR = 'Menswear'
}

export type FilterOptionT = {
  id: number
  name: string
  isSelected: boolean
  label?: string
}
