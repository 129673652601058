import PageError from 'components/PageError'
import { CopyErrorDataT } from 'types/CopyErrorData'

const ERROR_MESSAGE = 'Issue getting follow ups - '

type PropsT = {
  error: CopyErrorDataT
  customerId: string
  employeeId: string
}

const FollowUpsError = ({
  error,
  customerId,
  employeeId
}: PropsT): JSX.Element => {
  return (
    <PageError
      isInlineAlert={true}
      errorTitle={ERROR_MESSAGE}
      errorDetails={{
        endpoint: error.endpoint,
        errorData: error.errorData,
        identifiers: {
          customerId,
          curationId: 'NO-DATA',
          employeeId,
          shoppingSessionId: 'NO-DATA'
        }
      }}
      customBoxStyle={{ mt: '0px', px: '0px' }}
    />
  )
}

export default FollowUpsError
