import { ReactElement, useEffect, useState } from 'react'
import { Alert, Box, CircularProgress, Grid } from '@mui/material'
import NoRecentItems from '../NoRecentItems'
import PageError from 'components/PageError'
import RecentItemCard from '../RecentItemCard'
import RecentItemsLoading from '../RecentItemsLoading'
import { useGetRecentProductsQuery as useGetRecentItemsQuery } from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  EmployeeProductT,
  OnlinePurchasableStatusesByWebStyleIdT
} from 'types/Product'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { useGetProductsByWebStyleMutation } from 'services/employeeExperienceApi'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
}

const RecentItems = (props: PropsT): ReactElement => {
  const { handleClickForMiniPDP } = props
  const [webStyleIds, setWebStyleIds] = useState<string[]>([])
  const [
    isStyleOnlinePurchasableByWebStyleId,
    setOnlinePurchasableStatusesByWebStyleId
  ] = useState(new Map())

  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: recentItemsData,
    endpointName: recentItemsEndpoint,
    error: recentItemsError,
    isError: isGetRecentItemsError,
    isLoading: isGetRecentItemsLoading
  } = useGetRecentItemsQuery(employeeId)

  const [
    getProductsByWebStyle,
    {
      isError: isGetProductsByWebStyleError,
      isLoading: isOnlinePurchasableStatusLoading
    }
  ] = useGetProductsByWebStyleMutation()

  useEffect(() => {
    if (recentItemsData) {
      const webStyleIdsFromRecentItems = recentItemsData.map(
        (item) => item.webStyleId
      ) as string[]
      if (webStyleIdsFromRecentItems.length > 0) {
        setWebStyleIds(webStyleIdsFromRecentItems)
      }
    }
  }, [recentItemsData])

  useEffect(() => {
    if (webStyleIds.length > 0) {
      getProductsByWebStyle({ webStyleIds })
        .unwrap()
        .then((response) => {
          if (response.offerData) {
            const statusByWebStyleId: OnlinePurchasableStatusesByWebStyleIdT =
              new Map()
            recentItemsData?.map((item) => {
              const style = response.offerData.find(
                (productStyle) =>
                  productStyle.ids.webStyle.id === item.webStyleId
              )
              if (style) {
                const webStyleId = style.ids.webStyle.id
                const isStyleOnlinePurchasable =
                  style.sellingControls[0].productAttributes.isOnlinePurchasable
                statusByWebStyleId.set(webStyleId, { isStyleOnlinePurchasable })
              }
            })
            setOnlinePurchasableStatusesByWebStyleId(statusByWebStyleId)
          }
          return
        })
        .catch(() => null)
    }
  }, [webStyleIds, recentItemsData, getProductsByWebStyle])

  if (isGetRecentItemsLoading) {
    return <RecentItemsLoading />
  }

  if (isGetRecentItemsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: recentItemsEndpoint,
          errorData: recentItemsError,
          identifiers: {
            employeeId
          }
        }}
      />
    )
  }

  if (recentItemsData && recentItemsData.length === 0) {
    return <NoRecentItems />
  }

  return (
    <>
      {isOnlinePurchasableStatusLoading && (
        <Box sx={{ width: '100%' }} mb={2}>
          <Alert severity={'info'} icon={false}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1.8}
              ml={1}
              height="40px"
            >
              <CircularProgress size="25px" />
              Checking product availability...
            </Box>
          </Alert>
        </Box>
      )}
      <Grid container>
        {recentItemsData?.map((item: EmployeeProductT, index) => {
          if (item.rmsSku || item.webStyleId) {
            const isStyleOnlinePurchasable =
              isStyleOnlinePurchasableByWebStyleId.get(
                item.webStyleId
              )?.isStyleOnlinePurchasable
            return (
              <RecentItemCard
                key={`${item.rmsSku}-${index}`}
                brandName={item.brandName}
                clearancePrice={item.clearancePrice}
                color={item.color}
                currentPriceType={item.currentPriceType}
                enticements={item.enticements}
                imageUrl={item.imageUrl}
                itemName={item.itemName}
                itemNumber={item.itemNumber}
                onClickForMiniPDP={handleClickForMiniPDP}
                promotionPrice={item.promotionPrice}
                regularPrice={item.regularPrice}
                rmsSku={item.rmsSku}
                size={item.size}
                webStyleId={item.webStyleId}
                miniPDPOpenedFrom="RecentItemsDrawer"
                isStyleOnlinePurchasable={isStyleOnlinePurchasable}
                isOnlinePurchasableStatusLoading={
                  isOnlinePurchasableStatusLoading
                }
                isGetProductsByWebStyleError={isGetProductsByWebStyleError}
              />
            )
          }
        })}
      </Grid>
    </>
  )
}

export default RecentItems
