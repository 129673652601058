import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { CURATION_IN_PROGRESS_ERROR_MESSAGE } from 'pages/CurationHome/constants'
import { OrderBy } from 'pages/FinishedBoards/components/FinishedBoardsContent'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'
import {
  AbandonCurationArgsT,
  AbandonCurationMutationDataT,
  CreateCurationMutationDataT,
  CurationT,
  CurationItemT,
  CurationRecordT,
  CURATION_TYPE,
  GetCurationsQueryDataT,
  GetCurationsQueryParamsT,
  GetCurationByIdT,
  GetDraftCurationsQueryDataT,
  GetEditCurationQueryDataT,
  GetEditRequestQueueQueryDataT,
  GetPublishedCurationsQueryDataT,
  SaveCurationAsDraftArgsT,
  SaveCurationAsDraftMutationDataT,
  PUBLISHED_CURATION_TYPE,
  FormDataT,
  ResumeCurationMutationDataT
} from 'types/Curation'
import {
  EditItemsProductT,
  EmployeeProductT,
  FavoriteItemsResponseT,
  FavoriteProductT,
  PriceFormatTypes,
  ServerResponseT
} from 'types/Product'
import { CheckoutPageT } from 'types/Checkout'
import { formatProductPrice } from 'utils/formatPrice'
import {
  formatOfferProductToEmployeeProduct,
  parsePrice
} from 'utils/formatOfferProduct'

const transformResponseToEmployeeProduct = (response: ServerResponseT) => {
  const productsPayload = response.products
  const products = productsPayload.map((product) => {
    return formatOfferProductToEmployeeProduct(product)
  })
  return products
}

const transformResponseToFavoriteEmployeeProduct = (
  response: FavoriteItemsResponseT
) => {
  const productsFavoritePayload = response.favorites
  const products = productsFavoritePayload.map((product) => {
    const dollarsMax =
      product?.sellingControls[0].price.totalPriceRange?.max.units
    const dollarsMin =
      product?.sellingControls[0].price.totalPriceRange?.min.units

    const nanosMax =
      product?.sellingControls[0].price.totalPriceRange?.max.nanos

    const nanosMin =
      product?.sellingControls[0].price.totalPriceRange?.min.nanos

    const priceMax = formatProductPrice(dollarsMax, nanosMax)
    const priceMin = formatProductPrice(dollarsMin, nanosMin)
    const item: FavoriteProductT = {
      brandName: product?.productAttributes?.vendor?.labelName,
      name: product?.productAttributes?.name,
      imageUrl:
        product?.mediaExperiences?.carouselsByColor[0]?.orderedShots[0]?.url,
      priceMax: priceMax,
      priceMin: priceMin,
      webStyleId: product?.ids?.webStyle?.id
    }
    return item
  })
  return products
}

// RTK Query has built-in retry logic - we'll default to 2
// see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-retries for more details
const baseQueryWithRetries = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: process.env.REACT_APP_CURATION_SVC_API_URL,
      prepareHeaders: (headers) => {
        setStandardHeaders({ headers: headers })
        const accessToken = oktaTokenStorage.getOktaAccessToken()
        const idToken = oktaTokenStorage.getOktaIdToken()

        if (accessToken) {
          const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
          headers.set('Employee-Id', employeeId)
          headers.set('Nord-Okta-Id-Token', idToken)
          headers.set('Nord-Okta-Token', accessToken)
          headers.set('Authorization', `Bearer ${accessToken}`)
        }

        return headers
      }
    })(args, api, extraOptions)

    // We can also bail out of retries if we know we don't want them to happen
    // In this case we'll bail if the stylist has an in-progress curation
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
    if (result) {
      if (result.error) {
        const nordRequestId =
          result.meta?.request.headers.get('nord-request-id')
        const errorWithMetaData = {
          error: { ...result.error, meta: { nordRequestId } }
        }

        if (result.error.status === 400) {
          retry.fail(result.error)
        }

        if ('status' in result.error) {
          const errorMessage = JSON.stringify(result.error)

          if (errorMessage?.includes(CURATION_IN_PROGRESS_ERROR_MESSAGE)) {
            retry.fail(result.error)
          }
        }

        // prevent retries in test environment
        if (process.env.NODE_ENV === 'test') {
          retry.fail(result.error)
        }

        return errorWithMetaData
      }
    }

    return result
  },
  {
    maxRetries: 2
  }
)

export const curationApi = createApi({
  reducerPath: 'curationApi',
  baseQuery: baseQueryWithRetries,
  tagTypes: [
    'Curations',
    'CurationItems',
    'PublishedCurations',
    'Favorites',
    'EditCurations',
    'DraftCurations'
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    startCuration: builder.mutation<
      CurationT,
      { employeeId: string; lifoEnabled?: boolean }
    >({
      query: (body) => ({
        url: `/curations/start`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['Curations']
    }),
    getFormData: builder.query<FormDataT, void>({
      query: () => {
        return {
          url: `/form-data`,
          method: 'GET'
        }
      }
    }),
    getEditRequestsInQueue: builder.query<GetEditRequestQueueQueryDataT, void>({
      query: () => {
        return {
          url: `/curations/in-edit-requests-queue`,
          method: 'GET'
        }
      }
    }),
    getCurationById: builder.query<GetCurationByIdT, { curationId: number }>({
      query: (arg) => {
        const { curationId } = arg
        return {
          url: `/curations/${curationId}`,
          method: 'GET'
        }
      }
    }),
    getCurations: builder.query<
      GetCurationsQueryDataT,
      Partial<GetCurationsQueryParamsT> &
        Pick<GetCurationsQueryParamsT, 'state'>
    >({
      query: (arg) => {
        const {
          state,
          employeeId,
          customerId,
          orderBy,
          preferredEmployeeId,
          allRecords = undefined,
          perPage = undefined,
          page = undefined
        } = arg
        return {
          url: `/curations`,
          method: 'GET',
          params: {
            state,
            employeeId,
            ocpId: customerId,
            preferredEmployeeId,
            orderBy,
            allRecords,
            perPage,
            page
          }
        }
      },
      providesTags: ['Curations']
    }),
    getCheckoutPage: builder.query({
      query: (arg: { shopperId: string; token: string }) => {
        const { shopperId, token } = arg
        return {
          url: `/curations/shopping-bag/page`,
          headers: {
            'Nord-Checkout-Token': token
          },
          params: {
            shoppingSessionId: shopperId
          }
        }
      },
      providesTags: ['CurationItems'],
      transformResponse: (response: CheckoutPageT): CurationItemT[] => {
        if (!response.items || !response.items.length) {
          return []
        }

        // prevent products with duplicate skus from rendering multiple times
        const curationItemsBySku = response.items.reduce<CurationRecordT>(
          (curationItemsBySku, item) => {
            curationItemsBySku[item.rmsSku] = {
              id: item.rmsSku,
              brandName: response?.skuDetails?.[item.rmsSku]?.brand || '',
              color: response?.skuDetails?.[item.rmsSku]?.style?.color || '',
              enticements: response?.enticements?.[item.id] || [],
              imageUrl:
                response?.skuDetails?.[item.rmsSku]?.images?.[0]?.url || '',
              itemId: item.id,
              allItemIds: curationItemsBySku[item.rmsSku]?.allItemIds
                ? curationItemsBySku[item.rmsSku]?.allItemIds
                : [],
              price: {
                current: response?.pricing?.[item.id]?.current,
                original: response?.pricing?.[item.id]?.original
              },
              productName: response?.skuDetails?.[item.rmsSku]?.name || '',
              rmsSku: item.rmsSku,
              sizeLabel:
                response?.skuDetails?.[item.rmsSku]?.style?.sizeLabel || '',
              size: response?.skuDetails?.[item.rmsSku]?.style?.size || '',
              imageAltText:
                response?.skuDetails?.[item.rmsSku]?.images?.[0]?.altText || '',
              itemNumber:
                response?.skuDetails?.[item.rmsSku]?.style?.number || '',
              webStyleId: response?.skuDetails?.[item.rmsSku]?.webStyleId || '',
              itemAddedTimestamp: item.itemAddedTimestamp,
              description: response?.skuDetails?.[item.rmsSku]?.description,
              productCategory:
                response?.skuDetails?.[item.rmsSku]?.productCategory,
              productGender: response?.skuDetails?.[item.rmsSku]?.gender
            }
            curationItemsBySku[item.rmsSku].allItemIds?.push(item.id)

            return curationItemsBySku
          },
          {}
        )
        const arrayForSort = [...Object.values(curationItemsBySku)]
        const DEFAULT_SORT_DATE = Date.parse('01 Jan 1970 00:00:00 GMT')
        const itemsSortedByTimeAdded = arrayForSort.sort(
          (a, b) =>
            (a.itemAddedTimestamp
              ? Date.parse(a.itemAddedTimestamp)
              : DEFAULT_SORT_DATE) -
            (b.itemAddedTimestamp
              ? Date.parse(b.itemAddedTimestamp)
              : DEFAULT_SORT_DATE)
        )

        return itemsSortedByTimeAdded
      }
    }),
    getPublishedCurations: builder.query<
      GetPublishedCurationsQueryDataT,
      {
        employeeId: string
        orderBy?: OrderBy
        page?: number
        perPage?: number
        version: string
        type?: PUBLISHED_CURATION_TYPE
      }
    >({
      query: (arg) => {
        const { employeeId, orderBy, page, perPage, type, version } = arg
        return {
          url: `/published-curations`,
          method: 'GET',
          params: {
            employeeId,
            orderBy,
            page,
            perPage,
            type,
            version
          }
        }
      },
      providesTags: ['PublishedCurations']
    }),
    publishCuration: builder.mutation<
      CurationT,
      {
        curationId: number
        description: string
        products: Array<{ rmsSku: string; note?: string; productOrder: number }>
        title: string
      }
    >({
      query: (arg) => {
        const { curationId, description, products, title } = arg
        return {
          url: `curations/${curationId}/publish`,
          method: 'POST',
          body: {
            description,
            products,
            title
          }
        }
      }
    }),
    createCuration: builder.mutation<
      CreateCurationMutationDataT,
      {
        curationType: CURATION_TYPE
        employeeId: string
        ocpId?: string | null
      }
    >({
      query: ({ curationType, employeeId, ocpId }) => {
        return {
          url: 'curations/create',
          method: 'POST',
          body: {
            curationType,
            employeeId,
            ocpId
          }
        }
      },
      invalidatesTags: ['Curations']
    }),
    abandonCuration: builder.mutation<
      AbandonCurationMutationDataT,
      AbandonCurationArgsT
    >({
      query: (abandonCurationArgs) => ({
        url: `/curations/abandon`,
        method: 'POST',
        body: abandonCurationArgs
      }),
      invalidatesTags: ['DraftCurations']
    }),
    saveCurationAsDraft: builder.mutation<
      SaveCurationAsDraftMutationDataT,
      Omit<SaveCurationAsDraftArgsT, 'notes' | 'orderedItems'>
    >({
      query: ({ description, title, curationId }) => ({
        url: `/curations/${curationId}/save-for-later`,
        method: 'POST',
        body: { description, title }
      })
    }),
    getRecentProducts: builder.query<EmployeeProductT[], string>({
      query: (employeeId) => {
        return {
          url: `/products`,
          method: 'GET',
          params: { employeeId }
        }
      },
      transformResponse: transformResponseToEmployeeProduct
    }),
    getEmployeeFavoriteItems: builder.query<
      FavoriteProductT[],
      { employeeId: string }
    >({
      query: ({ employeeId }) => ({
        url: `/employee-favorite-items`,
        method: 'GET',
        params: {
          employeeId
        }
      }),
      providesTags: ['Favorites'],
      transformResponse: transformResponseToFavoriteEmployeeProduct
    }),
    addEmployeeFavoriteItems: builder.mutation<void, { webStyleIds: string[] }>(
      {
        query: (body) => ({
          url: `/employee-favorite-items/add`,
          method: 'POST',
          body
        }),
        invalidatesTags: ['Favorites']
      }
    ),
    removeEmployeeFavoriteItems: builder.mutation<
      void,
      { webStyleIds: string[] }
    >({
      query: (body) => ({
        url: `/employee-favorite-items/remove`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Favorites']
    }),
    addItemToBag: builder.mutation({
      query: (arg: { shopperId: string; token: string; rmsSku: string }) => {
        const { shopperId, token, rmsSku } = arg

        return {
          url: `/add-to-bag`,
          method: 'POST',
          headers: {
            'nord-checkout-token': token
          },
          body: { rmsSku, shoppingSessionId: shopperId }
        }
      },
      invalidatesTags: ['CurationItems']
    }),
    removeFromBag: builder.mutation({
      query: (arg: {
        itemIds: string[]
        shoppingSessionId: string
        token: string
      }) => {
        const { itemIds, shoppingSessionId, token } = arg

        return {
          url: '/remove-from-bag',
          method: 'DELETE',
          headers: {
            'nord-checkout-token': token
          },
          body: { itemIds, shoppingSessionId }
        }
      },
      invalidatesTags: ['CurationItems']
    }),
    unpublishCuration: builder.mutation({
      query: (curationId) => {
        return {
          url: `/curations/${curationId}/unpublish`,
          method: 'POST'
        }
      },
      invalidatesTags: ['PublishedCurations']
    }),
    getEditCurationData: builder.query({
      query: (arg: { curationId: number }) => {
        const { curationId } = arg
        return {
          url: `/curations/${curationId}/for-edit`,
          method: 'GET'
        }
      },
      providesTags: ['EditCurations'],
      transformResponse: (response: GetEditCurationQueryDataT) => {
        const productsPayload = response.products.map((product) => {
          const editItem: EditItemsProductT = {
            imageUrl:
              product.mediaExperiences?.carouselsByColor[0]?.orderedShots[0]
                ?.url,
            brandName: product.productAttributes?.vendor?.labelName,
            productName: product.productAttributes?.name,
            clearancePrice: parsePrice(product, PriceFormatTypes.CLEARANCE),
            promotionPrice: parsePrice(product, PriceFormatTypes.PROMOTION),
            regularPrice: parsePrice(product, PriceFormatTypes.REGULAR),
            currentPriceType:
              product.sku?.sellingControls[0]?.price.currentPriceType,
            enticements:
              product?.sku?.sellingControls[0]?.marketingAttributes
                ?.enticements,
            size: product?.sku?.productAttributes?.size?.name,
            sizeLabel: product?.productAttributes?.sizeLabel,
            color: product?.mediaExperiences?.carouselsByColor[0]?.colorName,
            rmsSku: product?.sku?.ids?.rmsSku?.id,
            webStyleId: product?.ids?.webStyle?.id
          }
          return editItem
        })
        return {
          curation: response.curation,
          products: productsPayload,
          editRequestId: response.editRequestId
        }
      }
    }),
    completeEdit: builder.mutation<
      void,
      {
        curationId: number
        deactivatedCurationProductIds?: number[]
        updatedProducts?: Record<string, Record<string, string>>
        updatedTitle?: string
        updatedDescription?: string
        newProducts?: { rmsSku: string; note: string }[]
        editRequestId?: number | null
      }
    >({
      query: ({
        curationId,
        deactivatedCurationProductIds,
        updatedProducts,
        updatedTitle,
        updatedDescription,
        newProducts,
        editRequestId
      }) => {
        return {
          url: `/curations/${curationId}/for-edit/complete`,
          method: 'POST',
          body: {
            deactivatedCurationProductIds,
            updatedProducts,
            updatedTitle,
            updatedDescription,
            newProducts,
            editRequestId
          }
        }
      },
      invalidatesTags: ['EditCurations', 'CurationItems']
    }),
    getDraftCurations: builder.query<GetDraftCurationsQueryDataT, void>({
      query: () => {
        return {
          url: '/curations/drafts',
          method: 'GET'
        }
      },
      providesTags: ['DraftCurations']
    }),
    resumeCuration: builder.mutation<
      ResumeCurationMutationDataT,
      {
        curationId: string
      }
    >({
      query: ({ curationId }) => {
        return {
          url: `curations/${curationId}/resume`,
          method: 'POST'
        }
      },
      invalidatesTags: ['Curations']
    })
  })
})

export const {
  useAbandonCurationMutation,
  useAddItemToBagMutation,
  useCreateCurationMutation,
  useGetCheckoutPageQuery,
  useGetEditRequestsInQueueQuery,
  useGetCurationsQuery,
  useGetCurationByIdQuery,
  useGetDraftCurationsQuery,
  useGetEditCurationDataQuery,
  useGetEmployeeFavoriteItemsQuery,
  useGetPublishedCurationsQuery,
  useGetRecentProductsQuery,
  usePublishCurationMutation,
  useRemoveFromBagMutation,
  useResumeCurationMutation,
  useSaveCurationAsDraftMutation,
  useStartCurationMutation,
  useUnpublishCurationMutation,
  useRemoveEmployeeFavoriteItemsMutation,
  useAddEmployeeFavoriteItemsMutation,
  useCompleteEditMutation,
  useGetFormDataQuery
} = curationApi
