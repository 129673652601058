import { ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface PropsT {
  onClick: () => void
  selectedFiltersCount: number
  isLoading: boolean
}

const CurationFiltersButton = (props: PropsT): ReactElement => {
  const { onClick, selectedFiltersCount, isLoading } = props

  return (
    <LoadingButton
      loading={isLoading}
      variant={selectedFiltersCount ? 'contained' : 'outlined'}
      color="secondary"
      startIcon={<FilterListIcon />}
      endIcon={<ExpandMoreIcon />}
      sx={{ minWidth: '127px' }}
      onClick={onClick}
    >
      Filters {selectedFiltersCount > 0 && `(${selectedFiltersCount})`}
    </LoadingButton>
  )
}

export default CurationFiltersButton
