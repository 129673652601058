import {
  AttributedItemT,
  CommissionAttributedOrderT,
  OrderT
} from 'types/Orders'

export const formatItemAsSingularOrPlural = (itemAmount: number): string => {
  return 'item' + (itemAmount > 1 ? 's' : '')
}

export const getAttributedItemsFromOrder = (
  attributedOrder: OrderT | CommissionAttributedOrderT,
  employeeId: string
): AttributedItemT[] =>
  (attributedOrder as CommissionAttributedOrderT)?.productsAndStatus?.flatMap(
    (status) =>
      status.products
        .filter((product) => product.commissionSalespersonId === employeeId)
        .map((product) => ({
          itemId: product.id,
          commissionSalespersonId: product.commissionSalespersonId
        }))
  )
