import {
  ListItemText,
  Box,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Button
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { ExtendedFollowUpT, SingleCustomerData } from 'types/FollowUps'
import { FOLLOW_UP_TEXT } from 'constants/clienteling/followUpsText'
import { Dispatch, SetStateAction } from 'react'

type PropsT = {
  customerData: SingleCustomerData
  customerId: string
  customerFollowUpsWithIds: { followUp: ExtendedFollowUpT; id: string }[]
  onFollowUpClick: () => void
  isChecked: Record<string, boolean>
  setIsChecked: Dispatch<SetStateAction<Record<string, boolean>>>
  onMarkingComplete: (customerId: string, followUp: ExtendedFollowUpT) => void
  onMarkingAllComplete: (customerId: string) => void
}

const MultipleFollowUps = ({
  customerData,
  customerId,
  customerFollowUpsWithIds,
  onFollowUpClick,
  isChecked,
  setIsChecked,
  onMarkingComplete,
  onMarkingAllComplete
}: PropsT): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <CardHeader
        title={
          <>
            <strong>{`${customerData.firstName} ${customerData.lastName}`}</strong>{' '}
            has multiple follow-up suggestions
          </>
        }
        titleTypographyProps={{ variant: 'body2' }}
        action={
          <IconButton onClick={onFollowUpClick}>
            <ChevronRightIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {customerFollowUpsWithIds.map(({ followUp, id }, index) => (
          <Box
            key={id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#F2F5F6',
              height: '58px',
              marginBottom:
                index !== customerData.followUps.length - 1 ? '12px' : 0,
              alignItems: 'center'
            }}
          >
            <IconButton
              sx={{
                marginLeft: '16px',
                marginRight: '14px',
                color: 'primary.main',
                '&.Mui-disabled': {
                  color: 'primary.main'
                }
              }}
              onClick={() => {
                setIsChecked((prevState) => ({
                  ...prevState,
                  [id]: !prevState[id]
                }))
                onMarkingComplete(customerId, followUp)
              }}
              disabled={isChecked[id]}
            >
              {isChecked[id] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
            </IconButton>
            <ListItemText
              sx={{ color: isChecked[id] ? 'text.secondary' : 'text.primary' }}
              key={followUp.followUpKind}
              primary={FOLLOW_UP_TEXT[followUp.followUpKind]}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </Box>
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          sx={{ paddingTop: 0 }}
          onClick={() => onMarkingAllComplete(customerId)}
        >
          Mark All Complete
        </Button>
      </CardActions>
    </Box>
  )
}

export default MultipleFollowUps
