import { CardHeader, IconButton } from '@mui/material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { FollowUpKindValueT } from 'types/FollowUps'
import { FOLLOW_UP_TEXT } from 'constants/clienteling/followUpsText'

type PropsT = {
  followUpKind: FollowUpKindValueT
  title: string
  onFollowUpClick: () => void
  onToggleFollowUp: () => void
  isChecked: boolean
  isDisabled?: boolean
}

const SingleFollowUp = ({
  followUpKind,
  title,
  isChecked,
  onFollowUpClick,
  onToggleFollowUp,
  isDisabled
}: PropsT) => {
  return (
    <CardHeader
      avatar={
        <IconButton
          onClick={onToggleFollowUp}
          disabled={isDisabled}
          data-testid="mark-complete-action"
          sx={{
            color: isChecked ? 'primary.main' : 'primary',
            '&.Mui-disabled': {
              color: isChecked ? 'primary.main' : 'primary'
            }
          }}
        >
          {isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </IconButton>
      }
      title={title}
      titleTypographyProps={{
        variant: 'subtitle2',
        color: isChecked ? 'text.secondary' : 'text.primary'
      }}
      subheader={FOLLOW_UP_TEXT[followUpKind]}
      subheaderTypographyProps={{
        variant: 'body2',
        color: isChecked ? 'text.secondary' : 'text.primary'
      }}
      action={
        <IconButton onClick={onFollowUpClick} data-testid="follow-up-action">
          <ChevronRightIcon />
        </IconButton>
      }
    />
  )
}

export default SingleFollowUp
