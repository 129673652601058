import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@mui/material'
import type { SelectChangeEvent } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { LoadingButton } from '@mui/lab'
import { useAddItemToBagMutation } from 'services/curationSvc'
import { useGetProductByWebStyleIdQuery } from 'services/employeeExperienceApi'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import DrawerNavigation from 'components/DrawerNavigation'
import ItemImage from 'components/ItemImage'
import LoadingSpinner from 'components/LoadingSpinner'
import ProductPrice from 'components/ProductPrice'
import { ValidationStatus } from 'types/FormValidation'
import { SkuDetailsT, StyleProductT } from 'types/Product'
import BasePageAlert from 'components/BasePageAlert'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import { isStyleSoldOut } from 'components/ProductPrice/helpers'
import {
  UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS,
  UPDATE_MINI_PDP_RECENT_ITEM_ADDS,
  UPDATE_MINI_PDP_SEED_ITEM_REC_ADDS,
  UPDATE_MINI_PDP_START_BOARD_REC_ADDS,
  UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS,
  UPDATE_MINI_PDP_HOLIDAY_GIFTS_ADDS,
  UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS
} from 'pages/CurationReview/constants'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

type ValidEditActionsT =
  | typeof UPDATE_MINI_PDP_RECENT_ITEM_ADDS
  | typeof UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS
  | typeof UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS

export type PropsT = {
  handleClickForPdp?: (webstyleId: string) => void
  handleSubmit?: (rmsSku: string) => void
  syncItems?: () => void
  onClose: () => void
  webStyleId: string
  isMiniPDPOpen: boolean
  shopperId?: string
  token?: string
  refetchToken?: () => void
  isPublicBoard: boolean
  onAddItemSuccess?: (productBrandAndName: string) => void
  miniPDPOpenedFrom?: MiniPDPLocationsT
  isEdit: boolean | null
  rmsSku?: string
  imgUrl?: string
}

const notAvailableMessage = '- Not available in'
const MINI_PDP_IMAGE_HEIGHT = '213px'
const MINI_PDP_IMAGE_WIDTH = '142px'

const MiniPDPDrawer = (props: PropsT): ReactElement => {
  const {
    handleClickForPdp,
    handleSubmit,
    syncItems,
    onClose,
    webStyleId,
    isMiniPDPOpen,
    shopperId,
    token,
    refetchToken,
    isPublicBoard,
    onAddItemSuccess,
    miniPDPOpenedFrom,
    isEdit,
    rmsSku,
    imgUrl
  } = props

  const [selectedSize, setSelectedSize] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [selectedSku, setSelectedSku] = useState<SkuDetailsT | undefined>()
  const [selectedWidth, setSelectedWidth] = useState('')
  const [loadingItemId, setLoadingItemId] = useState<string | undefined>(
    undefined
  )
  const [addedItemUnavailable, setAddedItemUnavailable] = useState(false)
  const [checkoutTokenExpired, setCheckoutTokenExpired] = useState(false)
  const [retryNeeded, setRetryNeeded] = useState(false)
  const [sizeRequiredError, setSizeRequiredError] = useState('')
  const [widthRequiredError, setWidthRequiredError] = useState('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const [showComboUnavailableSnackbar, setShowComboUnavailableSnackbar] =
    useState(false)
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)

  const theme = useTheme()

  const {
    data: productData,
    isLoading: isGetProductByWebStyleIdLoading,
    isError: isGetProductByWebStyleIdError,
    error: getProductByWebStyleIdError,
    endpointName: getProductByWebStyleIdEndpoint
  } = useGetProductByWebStyleIdQuery(webStyleId, { skip: !webStyleId })

  const colors = productData?.colors
  const widths = productData?.widths
  const sizes = productData?.sizes

  const findImageUrlForColor = ({
    colorName,
    productData
  }: {
    colorName?: string
    productData?: StyleProductT
  }) => {
    let defaultProductImageUrl = ''
    if (!productData) {
      return ''
    }

    const carouselsByColor = productData.mediaExperiences?.carouselsByColor

    if (!colorName && carouselsByColor) {
      defaultProductImageUrl = carouselsByColor[0]?.orderedShots[0]?.url || ''
      return defaultProductImageUrl
    }

    const carouselForSelectedColor = carouselsByColor?.find(
      (mediaExperience) => mediaExperience.colorName === colorName
    )

    return carouselForSelectedColor?.orderedShots[0]?.url || ''
  }

  const findColorForImgUrl = ({
    imgUrl,
    productData
  }: {
    imgUrl: string
    productData: StyleProductT
  }) => {
    const carouselsByColor = productData.mediaExperiences?.carouselsByColor

    return carouselsByColor.find((carousel) =>
      carousel.orderedShots.find((url) => url.url === imgUrl)
    )?.colorName
  }

  useEffect(() => {
    if (productData && productData.skus && !selectedColor) {
      if (imgUrl) {
        const color = findColorForImgUrl({ imgUrl, productData })
        color && setSelectedColor(color)
        setImageUrl(imgUrl)
        return
      }
      const sku = rmsSku
        ? productData.skus.find((product) => product.rmsSku === rmsSku)
        : productData.skus.find(
            (product) => !!product.color && product.isOnlinePurchasable
          )
      sku?.color && setSelectedColor(sku.color)
      rmsSku && sku?.size && setSelectedSize(sku.size)

      const imageUrlForSelectedColor = findImageUrlForColor({
        colorName: sku?.color,
        productData
      })
      setImageUrl(imageUrlForSelectedColor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])

  const [
    addItemToBag,
    {
      error: addItemError,
      isError: isAddItemError,
      isLoading: isAddItemLoading
    }
  ] = useAddItemToBagMutation()

  const validateForm = useCallback(() => {
    if (!isPublicBoard) {
      if (widths && widths.length > 0 && !selectedWidth) {
        setWidthRequiredError('Select a width to add to board')
      }
      if (!selectedSize) {
        setSizeRequiredError('Select a size to add to board')
      }
      if ((widths && widths.length > 0 && !selectedWidth) || !selectedSize) {
        return { validationStatus: ValidationStatus.FAIL }
      }
      if (!selectedSku) {
        setShowComboUnavailableSnackbar(true)
        return { validationStatus: ValidationStatus.FAIL }
      }
    }
    if (showComboUnavailableSnackbar) {
      setShowComboUnavailableSnackbar(false)
    }
    return { validationStatus: ValidationStatus.PASS }
  }, [
    isPublicBoard,
    showComboUnavailableSnackbar,
    widths,
    selectedWidth,
    selectedSize,
    selectedSku
  ])

  const handleAddToBag = useCallback(
    (rmsSku: string | undefined) => {
      const validationResult = validateForm()
      if (validationResult.validationStatus === ValidationStatus.FAIL) {
        return
      }

      if (
        validationResult.validationStatus === ValidationStatus.PASS &&
        rmsSku &&
        handleSubmit
      ) {
        handleSubmit(rmsSku)
        return
      }

      if (
        validationResult.validationStatus === ValidationStatus.PASS &&
        rmsSku &&
        shopperId &&
        token
      ) {
        addItemToBag({
          shopperId,
          token,
          rmsSku
        })
          .unwrap()
          .then((fulfilled) => {
            const brand = fulfilled.skuDetails[rmsSku]?.brand
            const name = fulfilled.skuDetails[rmsSku]?.name
            const productBrandAndName =
              !!brand && !!name ? `${brand} ${name}` : 'Item'

            const chooseActionTypeFromMiniPDPOpenedFrom = (
              miniPDPOpenedFrom?: MiniPDPLocationsT
            ):
              | typeof UPDATE_MINI_PDP_RECENT_ITEM_ADDS
              | typeof UPDATE_MINI_PDP_SEED_ITEM_REC_ADDS
              | typeof UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS
              | typeof UPDATE_MINI_PDP_START_BOARD_REC_ADDS
              | typeof UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS
              | typeof UPDATE_MINI_PDP_HOLIDAY_GIFTS_ADDS
              | typeof UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS => {
              switch (miniPDPOpenedFrom) {
                case 'RecentItemsDrawer':
                  return UPDATE_MINI_PDP_RECENT_ITEM_ADDS
                case 'SeedItemRecommendations':
                  return UPDATE_MINI_PDP_SEED_ITEM_REC_ADDS
                case 'BeautyRestocksDrawer':
                  return UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS
                case 'StartBoardRecommendations':
                  return UPDATE_MINI_PDP_START_BOARD_REC_ADDS
                case 'ScannerDrawer':
                  return UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS
                case 'HolidayGiftRecommendations':
                  return UPDATE_MINI_PDP_HOLIDAY_GIFTS_ADDS
                case 'SearchDrawer':
                  return UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS
                default:
                  return UPDATE_MINI_PDP_RECENT_ITEM_ADDS
              }
            }

            const isValidEditAction = (
              actionType: string
            ): actionType is ValidEditActionsT => {
              return (
                actionType === UPDATE_MINI_PDP_RECENT_ITEM_ADDS ||
                actionType === UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS ||
                actionType === UPDATE_MINI_PDP_SEARCH_DRAWER_ADDS
              )
            }

            const actionType =
              chooseActionTypeFromMiniPDPOpenedFrom(miniPDPOpenedFrom)
            setRetryNeeded(false)
            !isEdit &&
              dispatch &&
              dispatch({
                type: actionType,
                payload: { rmsSku }
              })
            isEdit &&
              editDispatch &&
              isValidEditAction(actionType) &&
              editDispatch &&
              editDispatch({
                type: actionType,
                payload: { rmsSku }
              })
            syncItems && syncItems()
            onAddItemSuccess && onAddItemSuccess(productBrandAndName)
            onClose()
            return
          })
          .catch(() => null)
      }
    },
    [
      validateForm,
      handleSubmit,
      addItemToBag,
      shopperId,
      token,
      miniPDPOpenedFrom,
      isEdit,
      dispatch,
      editDispatch,
      syncItems,
      onAddItemSuccess,
      onClose
    ]
  )

  const onAddToBag = (rmsSku: string | undefined) => {
    setRetryNeeded(true)
    setLoadingItemId(rmsSku)
    handleAddToBag(rmsSku)
  }

  const refetchTokenAndRetry = useCallback(() => {
    refetchToken && refetchToken()
    setRetryNeeded(false)
    loadingItemId && handleAddToBag(loadingItemId)
  }, [handleAddToBag, loadingItemId, refetchToken])

  useEffect(() => {
    if (addItemError) {
      if ('status' in addItemError) {
        const errorMessage = JSON.stringify(addItemError.data)
        const itemUnavailable = errorMessage?.includes(
          'One or more items is not purchasable online'
        )
        setAddedItemUnavailable(itemUnavailable)
        if (retryNeeded) {
          setRetryNeeded(false)
          const tokenExpired = errorMessage?.includes('Invalid token')
          setCheckoutTokenExpired(tokenExpired)
        }
      }
    }
  }, [addItemError, retryNeeded])

  useEffect(() => {
    if (checkoutTokenExpired) {
      refetchTokenAndRetry()
      setCheckoutTokenExpired(false)
    }
  }, [checkoutTokenExpired, refetchTokenAndRetry])

  useEffect(() => {
    if (isPublicBoard && selectedColor) {
      const sku =
        productData &&
        productData.skus.find(
          (sku) => sku.color === selectedColor && sku.isOnlinePurchasable
        )
      if (sku) {
        setSelectedSku(sku)
        if (showComboUnavailableSnackbar) {
          setShowComboUnavailableSnackbar(false)
        }
      }
      if (!sku) {
        setSelectedSku(undefined)
      }
    }
    if (!isPublicBoard) {
      if (!widths || widths?.length === 0) {
        if (selectedSize && selectedColor) {
          const sku =
            productData &&
            productData.skus.find(
              (sku) =>
                sku.size === selectedSize &&
                sku.color === selectedColor &&
                sku.isOnlinePurchasable
            )

          if (sku) {
            setSelectedSku(sku)
            if (showComboUnavailableSnackbar) {
              setShowComboUnavailableSnackbar(false)
            }
          }
          if (!sku) {
            setSelectedSku(undefined)
          }
        }
      }
      if (widths && widths.length > 0) {
        if (selectedSize && selectedColor && selectedWidth) {
          const sku =
            productData &&
            productData.skus.find(
              (sku) =>
                sku.size === selectedSize &&
                sku.color === selectedColor &&
                sku.width === selectedWidth &&
                sku.isOnlinePurchasable
            )
          if (sku) {
            setSelectedSku(sku)
            setShowComboUnavailableSnackbar(false)
          }
          if (!sku) {
            setSelectedSku(undefined)
          }
        }
      }
    }
  }, [
    selectedSize,
    selectedColor,
    selectedWidth,
    productData,
    widths,
    isPublicBoard,
    showComboUnavailableSnackbar
  ])

  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    setSelectedSize(event.target.value || '')
    sizeRequiredError && setSizeRequiredError('')
  }

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    setSelectedColor(event.target.value)
    const imageUrlForSelectedColor = findImageUrlForColor({
      colorName: event.target.value,
      productData
    })
    setImageUrl(imageUrlForSelectedColor)
  }

  const handleWidthChange = (event: SelectChangeEvent<string>) => {
    setSelectedWidth(event.target.value)
    widthRequiredError && setWidthRequiredError('')
  }

  const isProductAvailable = ({
    size,
    color,
    width
  }: {
    size: string
    color: string
    width?: string
  }) => {
    if (productData && productData.skus) {
      const availIndex = productData.skus.findIndex(
        (product) =>
          product.isOnlinePurchasable === true &&
          product.size === size &&
          product.color === color &&
          (width ? product.width === width : true)
      )
      return availIndex > -1
    }
    return false
  }

  const isColorAvailableInSize = (colorChip: string) => {
    return isProductAvailable({
      size: selectedSize,
      color: colorChip,
      width: selectedWidth
    })
  }

  const toTitleCase = (name: string) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(' ')
  }

  const colorHasAvailableSizes = (sizes: string[]) => {
    return sizes.some((size) =>
      isProductAvailable({ size, color: selectedColor, width: selectedWidth })
    )
  }

  const isSizeAvailableInColor = (sizeChip: string) => {
    return isProductAvailable({
      size: sizeChip,
      color: selectedColor,
      width: selectedWidth
    })
  }

  const isWidthAvailableInCombination = (widthChip: string) => {
    return isProductAvailable({
      size: selectedSize,
      color: selectedColor,
      width: widthChip
    })
  }

  const isSingleSizeAvailable = (sizes: Record<string, string[]>) => {
    const sizeGroups = Object.keys(sizes || {})
    const onlySizeGroup = sizes[sizeGroups[0]]
    return sizeGroups.length === 1 && onlySizeGroup.length === 1
  }

  useEffect(() => {
    if (sizes && isSingleSizeAvailable(sizes)) {
      const sizeGroups = Object.keys(sizes || {})
      const firstAvailableSize = sizes[sizeGroups[0]][0]
      setSelectedSize(firstAvailableSize)
    }
    if (widths && widths.length === 1) {
      setSelectedWidth(widths[0])
    }
  }, [sizes, widths])

  const isWebStyleSoldOut = productData?.price
    ? isStyleSoldOut(productData.price)
    : false

  return (
    <Drawer open={isMiniPDPOpen} onClose={onClose}>
      <Box sx={{ px: 2 }}>
        <DrawerNavigation
          headerEndButton={null}
          onClick={onClose}
          title="Quick view"
          showBackButton={false}
        />
      </Box>
      {isGetProductByWebStyleIdLoading && <LoadingSpinner />}
      {isGetProductByWebStyleIdError && (
        <Container sx={{ mt: 2 }}>
          <BasePageAlert
            alertTitle={"Page didn't load."}
            shouldShowRefresh={true}
            errorDetails={{
              errorData: getProductByWebStyleIdError,
              endpoint: getProductByWebStyleIdEndpoint
            }}
            setCopyToClipboardSuccess={setCopyToClipboardSuccess}
          />
          {copyToClipboardSuccess && (
            <CopiedErrorSnackbar
              setCopyToClipboardSuccess={setCopyToClipboardSuccess}
            />
          )}
        </Container>
      )}
      {productData && (
        <BoxWithGladlySpace sx={{ px: 2 }}>
          <Box display="flex" flexDirection="column">
            <Box
              height={MINI_PDP_IMAGE_HEIGHT}
              width={MINI_PDP_IMAGE_WIDTH}
              mb={2}
            >
              <ItemImage
                imageUrl={imageUrl}
                imageAltText={productData.brandName}
              />
            </Box>
            <Typography variant="subtitle2">{productData.brandName}</Typography>
            <Typography variant="body2">{productData.productName}</Typography>
            {productData.price && (
              <ProductPrice
                isPublicBoard={isPublicBoard}
                selectedSku={selectedSku}
                stylePrice={productData.price}
                isStyleSoldOut={isWebStyleSoldOut}
              />
            )}
            <Typography variant="caption" color="text.secondary" mb={2}>
              Item #{productData.itemNumber}
            </Typography>
            {!isWebStyleSoldOut && (
              <>
                <Typography variant="caption">
                  {productData.description}
                </Typography>
                <FormControl fullWidth sx={{ mt: 3 }} size="small">
                  <InputLabel>Color</InputLabel>
                  <Select
                    value={selectedColor}
                    onChange={handleColorChange}
                    labelId="color-select-label"
                    id="color-select"
                    label="Color"
                    renderValue={(selected) => (
                      <Typography
                        color={
                          isColorAvailableInSize(selected) || !selectedSize
                            ? 'text.primary'
                            : 'text.secondary'
                        }
                      >
                        {toTitleCase(selected)}
                      </Typography>
                    )}
                  >
                    {colors &&
                      colors.map((color) => {
                        const isAvailable = isColorAvailableInSize(color)
                        const textColor =
                          isAvailable || !selectedSize
                            ? 'text.primary'
                            : 'text.secondary'
                        return (
                          <MenuItem
                            key={color}
                            value={color}
                            sx={{ color: textColor }}
                          >
                            {!isAvailable && selectedSize
                              ? `${toTitleCase(
                                  color
                                )} ${notAvailableMessage} ${selectedSize}`
                              : `${toTitleCase(color)}`}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </>
            )}
            {!isPublicBoard && !isWebStyleSoldOut && (
              <FormControl
                fullWidth
                sx={{ mt: 3 }}
                error={!!sizeRequiredError}
                size="small"
              >
                <InputLabel id="size-select-label">Size</InputLabel>
                <Select
                  labelId="size-select-label"
                  id="size-select"
                  label="Size"
                  value={selectedSize}
                  onChange={(event) => handleSizeChange(event)}
                  error={!!sizeRequiredError}
                  renderValue={(selected) => (
                    <Typography
                      color={
                        isSizeAvailableInColor(selected)
                          ? 'text.primary'
                          : 'text.secondary'
                      }
                    >
                      {!isSizeAvailableInColor(selected)
                        ? `${selected} ${notAvailableMessage} ${
                            selectedWidth ? 'this combination' : selectedColor
                          }`
                        : `${selected}`}
                    </Typography>
                  )}
                  sx={{
                    color: sizeRequiredError ? 'error' : 'default'
                  }}
                >
                  {sizes &&
                    Object.keys(sizes).map((sizeGroup) => {
                      const selectListOptions = []

                      if (Object.keys(sizes).length > 1) {
                        selectListOptions.push(
                          <ListSubheader
                            key={`${sizeGroup}-subheader`}
                            sx={{
                              backgroundColor: theme.palette.grey[100],
                              fontWeight: 'bold'
                            }}
                          >
                            {sizeGroup}
                          </ListSubheader>
                        )
                      }
                      sizes[sizeGroup].forEach((size) => {
                        const isAvailable = isSizeAvailableInColor(size)
                        const textColor = isAvailable
                          ? 'text.primary'
                          : 'text.secondary'
                        selectListOptions.push(
                          <MenuItem
                            key={`${sizeGroup}-${size}`}
                            value={size}
                            sx={{ color: textColor }}
                          >
                            {!isAvailable
                              ? `${size} ${notAvailableMessage} ${
                                  selectedWidth
                                    ? 'in this combination'
                                    : selectedColor
                                }`
                              : `${size}`}
                          </MenuItem>
                        )
                      })

                      return selectListOptions
                    })}
                </Select>
                <Typography variant="caption" color="red">
                  {sizeRequiredError}
                </Typography>
              </FormControl>
            )}
            {!isPublicBoard &&
              !isWebStyleSoldOut &&
              widths &&
              widths.length > 0 && (
                <FormControl
                  fullWidth
                  sx={{ mt: 3 }}
                  size="small"
                  error={!!widthRequiredError}
                >
                  <InputLabel>Width</InputLabel>
                  <Select
                    value={selectedWidth}
                    label="Width"
                    labelId="width-select-label"
                    id="width-select"
                    error={!!widthRequiredError}
                    onChange={handleWidthChange}
                    sx={{
                      color: widthRequiredError ? 'error' : 'default'
                    }}
                    renderValue={(selected) => (
                      <Typography
                        color={
                          isWidthAvailableInCombination(selected) ||
                          !selectedSize
                            ? 'text.primary'
                            : 'text.secondary'
                        }
                      >
                        {selected}
                      </Typography>
                    )}
                  >
                    {widths &&
                      widths.map((width) => {
                        const isAvailable = isWidthAvailableInCombination(width)
                        const textColor =
                          isAvailable || !selectedSize
                            ? 'text.primary'
                            : 'text.secondary'
                        return (
                          <MenuItem
                            key={width}
                            value={width}
                            sx={{ color: textColor }}
                          >
                            {width}
                          </MenuItem>
                        )
                      })}
                  </Select>
                  <Typography variant="caption" color="red">
                    {widthRequiredError}
                  </Typography>
                </FormControl>
              )}
            {isPublicBoard && sizes && (
              <Typography mt={2} variant="caption">
                Available Sizes:{' '}
                {Object.keys(sizes)
                  .map((sizeGroup) => {
                    return colorHasAvailableSizes(sizes[sizeGroup])
                      ? sizes[sizeGroup].join(', ')
                      : 'None'
                  })
                  .join(', ')}
              </Typography>
            )}
            {!isWebStyleSoldOut && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingButton
                  onClick={() => {
                    onAddToBag(selectedSku?.rmsSku)
                  }}
                  loading={isAddItemLoading}
                  fullWidth
                  sx={{ mt: 4, mb: 2 }}
                  variant="contained"
                >
                  Add to Board
                </LoadingButton>
              </Box>
            )}
            {isWebStyleSoldOut && (
              <Box mt={2}>
                <BasePageAlert
                  severity="info"
                  errorMessage="This item is sold out."
                />
              </Box>
            )}
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                onClick={() => {
                  generateNewRelicLogs('viewFullDetails', {
                    miniPDPOpenedFrom,
                    webStyleId
                  })
                  handleClickForPdp && handleClickForPdp(webStyleId)
                }}
                startIcon={<LaunchIcon />}
                sx={{
                  maxWidth: '162px'
                }}
              >
                View Full Details
              </Button>
            </Box>
          </Box>
        </BoxWithGladlySpace>
      )}
      {isAddItemError && (
        <AddItemErrorSnackbar itemUnavailable={addedItemUnavailable} />
      )}
      {showComboUnavailableSnackbar && (
        <AddItemErrorSnackbar itemUnavailable={true} />
      )}
    </Drawer>
  )
}

export default MiniPDPDrawer
