import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Box, Button } from '@mui/material'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import DrawerNavigation from 'components/DrawerNavigation'
import FashionMapSearchBar from './components/FashionMapSearchBar'
import FashionMapSearchResults from './components/FashionMapSearchResults'
import CurationFiltersButton from 'components/CurationFiltersButton'
import FilterOptions from './components/FilterOptions'
import { emDashCharacter } from 'pages/constants'
import PageError from 'components/PageError'
import {
  useFashionMapFiltersMutation,
  useFashionMapSearchMutation
} from 'services/employeeExperienceApi'
import { DEPARTMENTS, FilterOptionT, GendersT } from 'types/FashionMapSearch'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import FashionMapSearchHelperText from './components/FashionMapSearchHelperText'

interface FashionMapSearchDrawerT {
  handleClickForPdp: (styleId: string) => void
  onClose: () => void
  open: boolean
}

const DRAWER_NAVIGATION_TITLE = 'Concept search'
const FILTERS_NAVIGATION_TTILE = 'Filters'
const SEARCH_BAR_PLACE_HOLDER = 'Search for products'
export const FILTER_ERROR_TITLE = `Issue getting filters ${emDashCharacter} try again`
const DEFAULT_FASHION_MAP_SEARCH_RESULT_SIZE = 20

const mapSelectedDepartmentToGender = (
  filters: string[]
): GendersT | undefined => {
  if (
    filters?.includes(DEPARTMENTS.MENSWEAR) &&
    filters?.includes(DEPARTMENTS.WOMENSWEAR)
  ) {
    return undefined
  } else if (filters?.includes(DEPARTMENTS.MENSWEAR)) {
    return 'male'
  } else if (filters?.includes(DEPARTMENTS.WOMENSWEAR)) {
    return 'female'
  }
}

const ClearAllFiltersButton = ({
  onClear
}: {
  onClear: () => void
}): ReactElement => <Button onClick={onClear}>Clear All</Button>

const FashionMapSearchDrawer = (
  props: FashionMapSearchDrawerT
): ReactElement => {
  const { handleClickForPdp, onClose, open } = props
  const curationDetails = useAppSelector(curationSelector)
  const resultSize =
    Number(useFeatureFlags().fashionMapSearchResultSize?.payload) ||
    DEFAULT_FASHION_MAP_SEARCH_RESULT_SIZE

  const [query, setQuery] = useState<string>('')
  const [showingFilters, setShowingFilters] = useState(false)
  const [selectedBrandFilters, setSelectedBrandFilters] = useState<string[]>([])
  const [selectedDepartmentFilters, setSelectedDepartmentFilters] = useState<
    string[]
  >([])
  const [selectedProductTypeFilters, setSelectedProductTypeFilters] = useState<
    string[]
  >([])

  const [search, { data, error, isError, isLoading, reset }] =
    useFashionMapSearchMutation()

  const [
    retrieveFilters,
    {
      data: filterData,
      endpointName: filterEndpoint,
      error: filterError,
      isError: isFilterError,
      isLoading: isFilterLoading
    }
  ] = useFashionMapFiltersMutation()

  const memoizedDepartmentsFilters: FilterOptionT[] = useMemo(() => {
    return Object.values(DEPARTMENTS).map((option, index) => {
      return {
        id: index,
        name: option,
        isSelected: selectedDepartmentFilters.includes(option)
      }
    })
  }, [selectedDepartmentFilters])

  const memoizedBrandsFilters: FilterOptionT[] = useMemo(() => {
    return (
      filterData?.results.brands.map((option, index) => {
        return {
          id: index,
          name: option,
          isSelected: selectedBrandFilters.includes(option)
        }
      }) || []
    )
  }, [filterData?.results.brands, selectedBrandFilters])

  const memoizedProductTypesFilters = useMemo(() => {
    return (
      filterData?.results.productTypes.map((option, index) => {
        return {
          id: index,
          name: option,
          isSelected: selectedProductTypeFilters.includes(option)
        }
      }) || []
    )
  }, [filterData?.results.productTypes, selectedProductTypeFilters])

  const clearSelectedFilters = () => {
    setSelectedBrandFilters([])
    setSelectedDepartmentFilters([])
    setSelectedProductTypeFilters([])
  }

  const handleDrawerClickaway = () => {
    if (showingFilters) {
      setShowingFilters(false)
    }
    onClose()
  }

  const onFiltersButtonClick = () => {
    if (!filterData) {
      retrieveFilters({})
        .unwrap()
        .then(() => setShowingFilters(true))
        .catch(() => null)
    } else {
      setShowingFilters(true)
    }
  }

  const onFiltersBackButtonClick = () => {
    if (query) {
      const attributes = {
        curationId: curationDetails?.id,
        type: curationDetails?.type,
        shoppingSessionId: curationDetails?.shoppingSessionId,
        searchTerm: query,
        hasBrandFilters: selectedBrandFilters.length > 0,
        hasDepartmentFilters: selectedDepartmentFilters.length > 0,
        hasProductTypeFilters: selectedProductTypeFilters.length > 0
      }
      generateNewRelicLogs('searchFMS', attributes)
      search({
        searchText: query,
        filterByProductTypes: selectedProductTypeFilters,
        filterByBrands: selectedBrandFilters,
        gender: mapSelectedDepartmentToGender(selectedDepartmentFilters),
        resultSize
      })
    }
    setShowingFilters(false)
  }
  const onSelectFilter = useCallback(
    ({
      currentFilter,
      isSelected,
      setSelectedFilters
    }: {
      currentFilter: string
      isSelected: boolean
      setSelectedFilters: (value: React.SetStateAction<string[]>) => void
    }) => {
      if (isSelected) {
        setSelectedFilters((prev) => [...prev, currentFilter])
      } else {
        setSelectedFilters((prev) => {
          const unselectedFilterIndex = prev.indexOf(currentFilter)
          prev.splice(unselectedFilterIndex, 1)
          return [...prev]
        })
      }
    },
    []
  )

  const onSelectDepartment = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedDepartmentFilters
      })
    },
    [onSelectFilter]
  )

  const onSelectBrand = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedBrandFilters
      })
    },
    [onSelectFilter]
  )

  const onSelectProductType = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedProductTypeFilters
      })
    },
    [onSelectFilter]
  )

  const resetSearch = () => {
    setQuery('')
    reset()
  }

  return (
    <Drawer onClose={handleDrawerClickaway} open={open}>
      <BoxWithGladlySpace>
        <Box sx={{ px: 2 }}>
          <DrawerNavigation
            headerEndButton={
              showingFilters ? (
                <ClearAllFiltersButton onClear={clearSelectedFilters} />
              ) : null
            }
            onClick={showingFilters ? onFiltersBackButtonClick : onClose}
            showBackButton={showingFilters}
            title={
              showingFilters
                ? FILTERS_NAVIGATION_TTILE
                : DRAWER_NAVIGATION_TITLE
            }
          />
        </Box>

        {showingFilters ? (
          <FilterOptions
            brandFilters={memoizedBrandsFilters}
            departmentFilters={memoizedDepartmentsFilters}
            productTypeFilters={memoizedProductTypesFilters}
            onSelectBrand={onSelectBrand}
            onSelectDepartment={onSelectDepartment}
            onSelectProductType={onSelectProductType}
          />
        ) : (
          <Box sx={{ px: 2 }}>
            <FashionMapSearchBar
              query={query}
              resetSearch={resetSearch}
              setQuery={setQuery}
              placeholder={SEARCH_BAR_PLACE_HOLDER}
              isLoading={!!isLoading}
              onClick={() => {
                const attributes = {
                  curationId: curationDetails?.id,
                  type: curationDetails?.type,
                  shoppingSessionId: curationDetails?.shoppingSessionId,
                  searchTerm: query,
                  hasBrandFilters: selectedBrandFilters.length > 0,
                  hasDepartmentFilters: selectedDepartmentFilters.length > 0,
                  hasProductTypeFilters: selectedProductTypeFilters.length > 0
                }
                generateNewRelicLogs('searchFMS', attributes)
                search({
                  searchText: query,
                  filterByBrands: selectedBrandFilters,
                  filterByProductTypes: selectedProductTypeFilters,
                  gender: mapSelectedDepartmentToGender(
                    selectedDepartmentFilters
                  ),
                  resultSize
                })
              }}
            />
            <CurationFiltersButton
              onClick={onFiltersButtonClick}
              selectedFiltersCount={
                selectedBrandFilters.length +
                selectedDepartmentFilters.length +
                selectedProductTypeFilters.length
              }
              isLoading={isFilterLoading}
            />
            {isFilterError && (
              <PageError
                errorTitle={FILTER_ERROR_TITLE}
                errorDetails={{
                  endpoint: filterEndpoint,
                  errorData: filterError
                }}
                isErrorTitlePersonalized={true}
                isFullWidth={true}
                isInlineAlert={true}
              />
            )}
            {!isLoading && !isError && !data?.results && (
              <FashionMapSearchHelperText />
            )}
            <FashionMapSearchResults
              error={error}
              handleClickForPdp={handleClickForPdp}
              isLoading={isLoading}
              isError={isError}
              results={data?.results}
            />
          </Box>
        )}
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default FashionMapSearchDrawer
