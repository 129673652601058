import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocalStorage } from 'react-use'
import { Box, Button, Typography } from '@mui/material'
import { useProductsSearchMutation } from 'services/employeeExperienceApi'
import CurationFiltersButton from 'components/CurationFiltersButton'
import PolarisSearchFilters from '../PolarisSearchFilters'
import ClearAllFiltersButton from '../ClearAllFiltersButton'
import ProductSearchBar from '../ProductSearchBar'
import ProductSearchResults from '../ProductSearchResults'
import SearchHelperText from '../SearchHelperText'
import { SEARCH_OPTIONS } from 'pages/SearchDrawer/SearchDrawer'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import DrawerNavigation from 'components/DrawerNavigation'
import { curationSelector } from 'app/curationSlice'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isFeatureEnabledForUser } from 'utils/userPermissions'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'

enum SearchDropDownCategories {
  RECENT_SEARCHES = 'Recent Searches'
}

interface PropsT {
  miniPDPOpenedFrom?: MiniPDPLocationsT
  handleClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  onClose: () => void
  searchToggleButtons: JSX.Element | null
}
const RelatedSearchTerms = ({
  onClick,
  searchTerms
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  searchTerms: { phrase: string }[]
}) => {
  return (
    <Box mt={1}>
      <Typography variant="subtitle2">Related search terms:</Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {searchTerms.map((search, i) => (
          <Button size="small" variant="text" onClick={onClick} key={i}>
            {search.phrase}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

const PolarisSearch = ({
  handleClickForMiniPDP,
  miniPDPOpenedFrom,
  searchToggleButtons,
  onClose
}: PropsT): JSX.Element => {
  const curationDetails = useSelector(curationSelector)

  const { isSearchFilterFeatureEnabled } = useFeatureFlags()
  const shouldShowFilters = isFeatureEnabledForUser(
    isSearchFilterFeatureEnabled?.payload as AccessGroupsFeatureFlagT
  )

  const [query, setQuery] = useState('')
  const [showingFilters, setShowingFilters] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: boolean
  }>({})
  const [recentSearches, setRecentSearchesInLocalStorage] = useLocalStorage<
    string[]
  >('recent-searches', [])
  const { popularSearchSuggestions } = useFeatureFlags()
  const { payload } = popularSearchSuggestions || {}

  let popularSearches = undefined
  if (payload !== null && typeof payload === 'object') {
    if ('phrases' in payload) {
      popularSearches = payload.phrases.map((searchTerm) => ({
        category: payload.heading,
        label: searchTerm.phrase
      }))
    }
  }

  const handleSelectFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, subCategory?: string) => {
      const filterName = subCategory
        ? `${subCategory}_${e.target.name}`
        : e.target.name
      setSelectedFilters((prevSelectedFilters) => ({
        ...prevSelectedFilters,
        [filterName]: !prevSelectedFilters[filterName]
      }))
    },
    [setSelectedFilters]
  )

  const handleRemoveChip = useCallback((filterName: string) => {
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterName]: false
    }))
  }, [])

  const [search, { data, error, isError, isLoading, reset }] =
    useProductsSearchMutation()

  const getSelectedFilterQueryParams = useCallback(() => {
    const filters: string[] = []
    data?.aggregates.forEach((filter) => {
      filter.values.forEach((value) => {
        if (selectedFilters[value.value]) {
          filters.push(value.queryParam)
        }
      })
    })
    return filters
  }, [data, selectedFilters])

  const selectedFiltersCount = useMemo(() => {
    return Object.values(selectedFilters).filter((isSelected) => isSelected)
      .length
  }, [selectedFilters])

  const dropdownOptions = useMemo(() => {
    try {
      if (recentSearches?.length === 0) {
        return popularSearches
      }
      return recentSearches
        ?.slice()
        .reverse()
        .map((searchTerm) => ({
          category: SearchDropDownCategories.RECENT_SEARCHES,
          label: searchTerm
        }))
    } catch (error) {
      let errorMessage = 'Unknown error'

      if (error instanceof Error) {
        errorMessage = error.message
      } else {
        try {
          errorMessage = JSON.stringify(error)
        } catch (jsonError) {
          errorMessage = 'Error stringifying the error object'
        }
      }

      generateNewRelicLogs('errorGeneratingDropdownOptions', {
        error: errorMessage
      })
      return []
    }
  }, [popularSearches, recentSearches])

  const saveSearchTerm = useCallback(
    (searchTerm: string) => {
      if (!searchTerm) return
      if (!Array.isArray(recentSearches)) {
        setRecentSearchesInLocalStorage([])
      }

      const maxTerms = 5
      let searchTerms = recentSearches ?? []

      searchTerms = searchTerms.filter(
        (term) => term.toLowerCase() !== searchTerm.toLowerCase()
      )

      if (searchTerms.length >= maxTerms) {
        searchTerms.shift()
      }

      searchTerms.push(searchTerm)
      setRecentSearchesInLocalStorage(searchTerms)
    },
    [recentSearches, setRecentSearchesInLocalStorage]
  )

  const removeAllRecentSearches = useCallback(() => {
    setRecentSearchesInLocalStorage([])
  }, [setRecentSearchesInLocalStorage])

  const executeSearch = useCallback(
    (searchTerm: string) => {
      const filterQueryParams = getSelectedFilterQueryParams()
      generateNewRelicLogs('searchPolarisClick', {
        searchTerm,
        filtersSelected: filterQueryParams.length > 0,
        ...mapCurationSliceToNewRelicLog(curationDetails)
      })
      search({
        searchTerm,
        resultsPerSearch: 100,
        filters: filterQueryParams
      })
      saveSearchTerm(searchTerm)
    },
    [getSelectedFilterQueryParams, curationDetails, search, saveSearchTerm]
  )

  const onBackButtonFiltersClick = useCallback(() => {
    executeSearch(query)
    setShowingFilters(false)
  }, [executeSearch, query])

  const resetSearch = () => {
    reset()
    setQuery('')
    setSelectedFilters({})
  }

  return (
    <Box sx={{ px: 2 }}>
      <DrawerNavigation
        headerEndButton={
          selectedFiltersCount > 0 ? (
            <ClearAllFiltersButton
              onClear={
                showingFilters ? () => setSelectedFilters({}) : resetSearch
              }
            />
          ) : null
        }
        onClick={showingFilters ? () => onBackButtonFiltersClick() : onClose}
        showBackButton={showingFilters}
        title={showingFilters ? 'Filters' : 'Search'}
      />
      {showingFilters && data?.aggregates ? (
        <PolarisSearchFilters
          filters={data.aggregates}
          handleSelectFilter={handleSelectFilter}
          handleRemoveChip={handleRemoveChip}
          selectedFilters={selectedFilters}
        />
      ) : (
        <>
          <ProductSearchBar
            dropdownOptions={dropdownOptions}
            onClick={(searchValue) => executeSearch(searchValue)}
            placeholder={'Search for products'}
            query={query}
            resetSearch={resetSearch}
            setQuery={setQuery}
            isLoading={isLoading}
            onRecentSearchesClearClick={removeAllRecentSearches}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={searchToggleButtons ? 'space-between' : 'flex-end'}
          >
            {searchToggleButtons}
            {shouldShowFilters && data?.aggregates && (
              <CurationFiltersButton
                onClick={() => setShowingFilters(true)}
                selectedFiltersCount={selectedFiltersCount}
                isLoading={false}
              />
            )}
          </Box>

          {!isLoading && !isError && !data?.products && (
            <SearchHelperText searchType={SEARCH_OPTIONS.POLARIS} />
          )}
          {!isLoading && !isError && data?.products && data?.relatedSearches && (
            <RelatedSearchTerms
              searchTerms={data.relatedSearches}
              onClick={(event) => {
                const searchTerm = event.currentTarget.textContent
                searchTerm &&
                  generateNewRelicLogs('relatedSearchTermClick', {
                    ...mapCurationSliceToNewRelicLog(curationDetails),
                    searchTerm
                  })
                searchTerm && setQuery(searchTerm)
                searchTerm && executeSearch(searchTerm)
              }}
            />
          )}
          <ProductSearchResults
            endpoint="Polaris"
            error={error}
            handleClickForMiniPDP={handleClickForMiniPDP}
            isError={isError}
            isLoading={isLoading}
            miniPDPOpenedFrom={miniPDPOpenedFrom}
            polarisResults={data?.products}
            shouldShowWideWidths={true}
          />
        </>
      )}
    </Box>
  )
}

export default PolarisSearch
