import { Card } from '@mui/material'
import { ExtendedFollowUpT, SingleCustomerData } from 'types/FollowUps'
import MultipleFollowUps from '../MultipleFollowUps'
import SingleFollowUp from '../SingleFollowUp'
import { useMemo, useState } from 'react'

type PropsT = {
  customerId: string
  customerData: SingleCustomerData
  hasMultipleFollowUps: boolean
  onFollowUpClick: () => void
  onMarkingComplete: (customerId: string, followUp: ExtendedFollowUpT) => void
  onMarkingAllComplete: (customerId: string) => void
}

const FollowUpCard = ({
  customerId,
  customerData,
  hasMultipleFollowUps,
  onFollowUpClick,
  onMarkingComplete,
  onMarkingAllComplete
}: PropsT): JSX.Element => {
  const [isChecked, setIsChecked] = useState<Record<string, boolean>>({})

  const customerFollowUpsWithIds = useMemo(
    () =>
      customerData.followUps.map((followUp) => ({
        followUp,
        id: JSON.stringify(followUp)
      })),
    [customerData]
  )

  return (
    <Card
      key={customerId}
      data-testid="follow-up-card"
      sx={{
        marginBottom: '16px'
      }}
    >
      {hasMultipleFollowUps ? (
        <MultipleFollowUps
          customerId={customerId}
          customerData={customerData}
          customerFollowUpsWithIds={customerFollowUpsWithIds}
          onFollowUpClick={onFollowUpClick}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          onMarkingComplete={onMarkingComplete}
          onMarkingAllComplete={onMarkingAllComplete}
        />
      ) : (
        <SingleFollowUp
          followUpKind={customerData.followUps[0].followUpKind}
          title={`${customerData.firstName} ${customerData.lastName}`}
          isChecked={isChecked[customerFollowUpsWithIds[0].id]}
          isDisabled={isChecked[customerFollowUpsWithIds[0].id]}
          onFollowUpClick={onFollowUpClick}
          onToggleFollowUp={() => {
            setIsChecked({
              ...isChecked,
              [customerFollowUpsWithIds[0].id]:
                !isChecked[customerFollowUpsWithIds[0].id]
            })
            onMarkingComplete(customerId, customerData.followUps[0])
          }}
        />
      )}
    </Card>
  )
}

export default FollowUpCard
