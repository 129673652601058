import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  Typography,
  IconButton,
  Box,
  Stack,
  Alert,
  AlertTitle
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useAppSelector } from 'app/hooks'
import { filterByQuerySearch } from 'pages/CustomerHome/utils/customers'
import { useLazyGetConsentedCustomerListQuery } from 'services/clientelingApi'
import { useCreateConversationMutation } from 'services/twilioApi'
import { conversationsSelector } from 'services/twilioSlice/selectors'
import { ConsentedCustomerListItemT } from 'types/ConsentedCustomer'
import SearchBar from 'pages/CustomerHome/components/SearchBar'
import { checkBrowserClient } from 'utils/userAgentDetector'
import CustomerList from 'pages/CustomerHome/components/CustomerList'
import { getConversationSidByCustomerId } from 'services/twilioSlice/utils'
import LoadingBackdrop from 'components/LoadingBackdrop'
import BaseSnackbar from 'components/BaseSnackbar'
import { CONVERSATION_FAILED_MESSAGE } from 'pages/CustomerDetails/constants'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import LoadingSpinner from 'components/LoadingSpinner'

export type PropsT = {
  onClose: () => void
  isDialogOpen: boolean
  isCreateStyleBoard?: boolean
  rmsSku?: string
  productSource?: string
}

const CustomersDialog: (props: PropsT) => JSX.Element = (props: PropsT) => {
  const { isDialogOpen, onClose, isCreateStyleBoard, rmsSku, productSource } =
    props
  const [query, setQuery] = useState<string>('')
  const [customers, setCustomers] = useState<ConsentedCustomerListItemT[]>([])
  const [isFullPageDisabled, setIsFullPageDisabled] = useState(false)
  const [didConversationCreationFail, setDidConversationCreationFailed] =
    useState(false)
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const isMobileDevice = checkBrowserClient.isMobile()
  const history = useHistory()

  const dialogMessage = isCreateStyleBoard
    ? 'Create style board'
    : 'Create message'

  const conversations = useAppSelector(conversationsSelector)

  const [lazyGetCustomers, { isLoading, data }] =
    useLazyGetConsentedCustomerListQuery()

  useEffect(() => {
    if (isDialogOpen) {
      lazyGetCustomers({ retrieveFilters: true, retrieveQualification: true })
    }
  }, [isDialogOpen, lazyGetCustomers])

  const [createConversation, { error: createConversationError }] =
    useCreateConversationMutation()

  useEffect(() => {
    if (!data?.customers.length) {
      return
    }
    let customers = data.customers
    if (query) {
      const searchResults = filterByQuerySearch(query, data.customers)
      customers = searchResults
    }
    setCustomers(customers)
  }, [query, data])

  const createOrNavigateToConversation = async (customerId: string) => {
    setIsFullPageDisabled(true)
    const conversationSid = getConversationSidByCustomerId(
      conversations,
      customerId
    )
    try {
      if (conversationSid) {
        history.push(`/messages/${conversationSid}`)
      } else {
        const newOrExistingConversationSid = (
          await createConversation({
            customerId
          }).unwrap()
        ).conversationSid
        history.push(`/messages/${newOrExistingConversationSid}`)
      }
    } catch (error) {
      setDidConversationCreationFailed(true)
    } finally {
      setIsFullPageDisabled(false)
    }
  }

  const createStyleBoard = (customerId: string) => {
    history.push({
      pathname: '/review',
      search: rmsSku
        ? `?createFor=${customerId}&rmsSku=${rmsSku}&productSource=${productSource}`
        : `?createFor=${customerId}`
    })
  }

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        fullScreen={!!isMobileDevice}
        fullWidth
        data-testid="customersDialog"
      >
        <LoadingBackdrop open={isFullPageDisabled} />
        <Box sx={{ px: '24px', paddingBottom: '20px' }}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'left',
                  py: '16px'
                }}
              >
                <IconButton sx={{ px: 0 }} onClick={onClose}>
                  <CloseIcon sx={{ color: 'text.primary' }} />
                </IconButton>
                <Typography variant="h6" sx={{ marginLeft: '24px' }}>
                  {dialogMessage}
                </Typography>
              </Box>
              <Stack paddingX={isMobileDevice ? '20px' : 0}>
                <SearchBar
                  query={query}
                  setQuery={setQuery}
                  placeholder="Search by name, phone, or email"
                  testId="searchCustomer"
                />
              </Stack>
              <CustomerList
                customers={customers}
                customerCardAction={
                  isCreateStyleBoard
                    ? createStyleBoard
                    : createOrNavigateToConversation
                }
                query={query}
                displayEmptySearchResults={!!query && !customers.length}
              />
            </>
          )}
        </Box>
      </Dialog>
      <BaseSnackbar open={didConversationCreationFail}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDidConversationCreationFailed(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>
            <>
              <strong>{CONVERSATION_FAILED_MESSAGE}</strong>
              {didConversationCreationFail && (
                <CopyErrorData
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  errorDetails={{ errorData: createConversationError }}
                />
              )}
            </>
          </AlertTitle>
        </Alert>
      </BaseSnackbar>
      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </>
  )
}

export default CustomersDialog
