export const determineSearchParams = (search: string): string => {
  const searchParams = new URLSearchParams(search)
  const rmsSku = searchParams.get('rmsSku')
  const productSource = searchParams.get('productSource')
  const newSearch = new URLSearchParams()
  if (rmsSku) {
    newSearch.append('rmsSku', rmsSku)
  }
  if (productSource) {
    newSearch.append('productSource', productSource)
  }

  return newSearch.toString()
}
