import { List, ListItem } from '@mui/material'
import { ExtendedFollowUpT } from 'types/FollowUps'
import FollowUpCard from './FollowUpCard'
import { useHistory } from 'react-router-dom'
import { isPurchaseBasedFollowUp } from 'utils/determineFollowUpKind'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CUSTOMER_DETAILS_PAGE_CLICKED_FOLLOW_UP_PURCHASE_DETAILS } from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'

type PropsT = {
  followUps: ExtendedFollowUpT[]
  customerId: string
  onCheckFollowUp: (
    customerId: string,
    customerFollowUp: ExtendedFollowUpT
  ) => void
}

const FollowUpCards = ({
  followUps,
  customerId,
  onCheckFollowUp
}: PropsT): JSX.Element => {
  const history = useHistory()

  const goToPurchaseDetails = (orderId: string) => () => {
    generateNewRelicLogs(
      NR_CUSTOMER_DETAILS_PAGE_CLICKED_FOLLOW_UP_PURCHASE_DETAILS,
      {
        orderId,
        page: NR_CLIENTELING_PAGES.CUSTOMER_DETAILS_PAGE,
        ocpId: customerId
      }
    )
    history.push({
      pathname: `/purchase-history/order/${orderId}`,
      state: {
        customerId
      }
    })
  }

  const followUpWithIds = followUps.map((followUp) => ({
    followUp,
    id: crypto.randomUUID()
  }))

  return (
    <List>
      {followUpWithIds.map(({ followUp, id }) => (
        <ListItem key={id} sx={{ paddingX: '0', paddingY: '6px' }}>
          <FollowUpCard
            customerId={customerId}
            followUp={followUp}
            onCheckFollowUp={onCheckFollowUp}
            onClick={
              isPurchaseBasedFollowUp(followUp.followUpKind)
                ? goToPurchaseDetails(followUp.meta?.orderId ?? '')
                : undefined
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default FollowUpCards
