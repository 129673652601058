import { Box, Divider, ListItem, ListItemText, Typography } from '@mui/material'
import { ReactNode } from 'react'

type PropsT = {
  title: ReactNode
  children?: ReactNode
}
const AppointmentDetailsItem: (props: PropsT) => JSX.Element = (
  props: PropsT
) => {
  return (
    <Box bgcolor={'#FFFFFF'}>
      <ListItem component="div">
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                variant="subtitle2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                {props.title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                {props.children}
              </Typography>
            </>
          }
          secondaryTypographyProps={{ component: 'span' }}
        ></ListItemText>
      </ListItem>
      <Divider></Divider>
    </Box>
  )
}

export default AppointmentDetailsItem
