import FiltersAccordion from 'pages/FashionMapSearchDrawer/components/FiltersAccordion'
import SelectedFilters from 'pages/FashionMapSearchDrawer/components/SelectedFilters'
import { useMemo } from 'react'
import { PolarisAggregateT } from 'types/PolarisProduct'

interface PropsT {
  filters: PolarisAggregateT[]
  handleRemoveChip: (filterName: string) => void
  handleSelectFilter: (
    e: React.ChangeEvent<HTMLInputElement>,
    subCategory?: string
  ) => void
  selectedFilters: { [key: string]: boolean }
}

const PolarisSearchFilters = (props: PropsT): JSX.Element => {
  const { filters, handleRemoveChip, handleSelectFilter, selectedFilters } =
    props

  const memoizedFilters = useMemo(() => {
    return filters.map((filter) => {
      const subCategories: {
        subcategory: string
        values: { name: string; id: number; isSelected: boolean }[]
      }[] = []

      filter.values.forEach((value, i) => {
        if (value.value.includes('_')) {
          const [subcategory, name] = value.value.split('_')
          let subcategoryGroup = subCategories.find(
            (group) => group.subcategory === subcategory
          )
          if (!subcategoryGroup) {
            subcategoryGroup = { subcategory, values: [] }
            subCategories.push(subcategoryGroup)
          }
          subcategoryGroup.values.push({
            name,
            id: i,
            isSelected: !!selectedFilters[value.value]
          })
        }
      })

      return {
        ...filter,
        subCategories
      }
    })
  }, [filters, selectedFilters])

  return (
    <>
      <SelectedFilters
        selectedSearchFilters={Object.keys(selectedFilters)
          .map((filter, i) => {
            if (selectedFilters[filter]) {
              let label
              if (filter.includes('_')) {
                label = filter.split('_')[1]
              }
              return { id: i, name: filter, label, isSelected: true }
            }
          })
          .filter((value) => !!value)}
        handleRemoveSearchFilter={handleRemoveChip}
      />
      {memoizedFilters.map((filter, i) => (
        <FiltersAccordion
          key={i}
          filters={filter.values.map((value, i) => ({
            name: value.value,
            id: i,
            isSelected: !!selectedFilters[value.value]
          }))}
          subCategories={filter.subCategories}
          onSelect={handleSelectFilter}
          showSearchBox={filter.name === 'Brand' ? true : false}
          summaryTitle={filter.name}
        />
      ))}
    </>
  )
}

export default PolarisSearchFilters
