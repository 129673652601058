import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

const FollowUpEmptyState = (): ReactElement => {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        mt={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={200}
        width={235}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={80}
          height={80}
          borderRadius="50%"
          bgcolor="grey.200"
        >
          <TaskAltIcon fontSize="large" color="secondary" />
        </Box>
        <Typography variant="h6" color="text.primary" mt={2}>
          No follow ups
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          mt={1}
          align="center"
        >
          Follow ups for new customers and purchases will appear here
        </Typography>
      </Box>
    </Box>
  )
}

export default FollowUpEmptyState
