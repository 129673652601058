import Carousel from 'components/Carousel'
import { PolarisSearchProductsT } from 'types/PolarisProduct'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import VerticalProductImage from 'components/VerticalProductImage'

interface PropsT {
  data: PolarisSearchProductsT
  onClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  miniPDPOpenedFrom: MiniPDPLocationsT
}

const ProductImageCarousel = (props: PropsT): JSX.Element => {
  const { data, onClickForMiniPDP, miniPDPOpenedFrom } = props
  const isMobile = checkBrowserClient.isMobile()

  return (
    <Carousel displayArrows={!isMobile} closeSpacing={true}>
      {data &&
        data.products &&
        data.products.map((item) => (
          <VerticalProductImage
            key={item.webStyleId}
            brandName={item.brandName}
            imgUrl={item.imgUrl}
            miniPDPOpenedFrom={miniPDPOpenedFrom}
            handleClickForMiniPDP={onClickForMiniPDP}
            price={item.price}
            webStyleId={item.webStyleId}
          />
        ))}
    </Carousel>
  )
}

export default ProductImageCarousel
