import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

const YourTeam = (): ReactElement => {
  const EmptyState = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingTop: 8
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '67px',
          height: '67px',
          backgroundColor: '#EBEFF1',
          borderRadius: '50px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SupervisedUserCircleIcon
          sx={{
            width: '35px',
            height: '35px',
            color: 'rgba(31, 33, 34, 0.54)'
          }}
        />
      </Box>
      <Typography
        variant="subtitle2"
        sx={{
          paddingTop: 2
        }}
      >
        No employees to show
      </Typography>
      <Typography
        variant="body2"
        sx={{
          paddingTop: 1,
          textAlign: 'center',
          color: '#6B777B'
        }}
      >
        Add your employees in order to see their reporting information.
      </Typography>
    </Box>
  )
  return (
    <Box px={2} mt={3}>
      <EmptyState />
    </Box>
  )
}

export default YourTeam
