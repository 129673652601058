import { Chip } from '@mui/material'
import { useState } from 'react'
import CategoryCarousel from './CategoryCarousel'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import RecommendationsTemplate from '../RecommendationsTemplate'
import { PriceRange } from '../RecommendationsTemplate/RecommendationsTemplate'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

type PriceChipLabel = 'Under $25' | 'Under $50' | 'Under $100' | 'Luxe'

const priceChipData = {
  All: { min: 0 },
  Luxe: { min: 100 },
  'Under $100': { min: 50, max: 100 },
  'Under $50': { min: 25, max: 50 },
  'Under $25': { max: 25 }
}

interface SelectablePriceChipT {
  handlePriceChipClick: (priceChipLabel: PriceChipLabel) => void
  priceChip: PriceChipLabel
  selectedPriceChip: PriceChipLabel
}

const SelectablePriceChip = ({
  handlePriceChipClick,
  priceChip,
  selectedPriceChip
}: SelectablePriceChipT) => {
  return (
    <Chip
      size="small"
      label={priceChip}
      onClick={() => handlePriceChipClick(priceChip)}
      sx={{
        backgroundColor:
          selectedPriceChip === priceChip ? 'primary.main' : 'default',
        color: selectedPriceChip === priceChip ? 'white' : 'black',
        '&:hover': {
          backgroundColor:
            selectedPriceChip === priceChip ? 'primary.main' : 'default',
          color: selectedPriceChip === priceChip ? 'white' : 'black'
        }
      }}
    />
  )
}

interface PropsT {
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  curationId: number
  trayTitle?: string
  useBackgroundColor?: boolean
  useTitle?: boolean
}

const HolidayGiftingRecommendations = ({
  onClickForMiniPDP,
  curationId,
  trayTitle,
  useBackgroundColor,
  useTitle
}: PropsT): JSX.Element => {
  const [selectedPriceChip, setSelectedPriceChip] = useState('All')
  const [minAndMax, setMinAndMax] = useState<PriceRange>({})

  const handlePriceChipClick = (priceChipLabel: PriceChipLabel) => {
    setSelectedPriceChip(priceChipLabel)
    setMinAndMax(priceChipData[priceChipLabel])
    generateNewRelicLogs('holidayGiftingPriceChipClicked', {
      priceChipLabel,
      curationId
    })
  }

  const onTabChange = (value: string) => {
    setSelectedPriceChip('All')
    setMinAndMax({})
    generateNewRelicLogs('holidayGiftingCategoryClicked', {
      category: recommendationCategories[Number(value)],
      curationId
    })
  }

  const recommendationCategories = [
    'Gifts for her',
    'Gifts for him',
    'Beauty gifts',
    'Grooming gifts',
    'Toy shop',
    'Jewelry and watch gifts',
    'Home gifts'
  ]
  const recommendationSearchTerms = [
    'Holiday gifts for her',
    'Holiday gifts for him',
    'Holiday beauty gifts',
    'Holiday grooming gifts for him',
    'Toy shop',
    'Jewelry and watch gifts',
    'Kitchen and home gifts'
  ]

  return (
    <RecommendationsTemplate
      title={trayTitle ? trayTitle : 'Holiday Gifting'}
      description={'Add items for holiday gift ideas'}
      categories={recommendationCategories}
      renderCategoryCarousel={(index) => (
        <CategoryCarousel
          onClickForMiniPDP={onClickForMiniPDP}
          curationId={curationId}
          searchTerm={recommendationSearchTerms[index]}
          minAndMax={minAndMax}
        />
      )}
      renderedFrom={'HolidayGiftRecommendations'}
      renderPriceChips={(priceChipData) =>
        Object.entries(priceChipData).map(
          ([key], subIndex) =>
            (
              <SelectablePriceChip
                key={subIndex}
                priceChip={key as PriceChipLabel}
                selectedPriceChip={selectedPriceChip as PriceChipLabel}
                handlePriceChipClick={handlePriceChipClick}
              />
            ) || []
        )
      }
      priceChipData={priceChipData}
      onTabChange={onTabChange}
      useBackgroundColor={useBackgroundColor}
      useTitle={useTitle}
    />
  )
}

export default HolidayGiftingRecommendations
