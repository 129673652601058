import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Box, Typography, Stack } from '@mui/material'
import { ProductT } from 'types/Product'
import ItemImage from '../../../../components/ItemImage'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { useAddItemToBagMutation } from 'services/curationSvc'
import { CurationEditDispatchContext } from 'pages/CurationEdit/CurationEditReducer'
import { CurationReviewDispatchContext } from 'pages/CurationReview/components/CurationReviewContent/CurationReviewReducer'
import {
  UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS,
  UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS
} from 'pages/CurationReview/constants'
import AddItemErrorSnackbar from 'components/AddItemErrorSnackbar'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_CREATE_BOARD_WITH_ITEM,
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

export type PropsT = {
  customerId?: string
  product: ProductT
  onClickForPDP: (webStyleId: string) => void
  onItemAdded?: (rmsSkuId: string) => void
  shopperId?: string
  token?: string
  isItemAdded: boolean
  isAttributedItem?: boolean
  routedFromSnapshot: boolean
}

const OrderItem = (props: PropsT): ReactElement => {
  const {
    customerId,
    product,
    onClickForPDP,
    onItemAdded,
    shopperId,
    token,
    isItemAdded,
    isAttributedItem,
    routedFromSnapshot
  } = props

  const { id: rmsSkuId, isSkuAvailable } = product

  const [addedItemUnavailable, setAddedItemUnavailable] = useState(false)
  const dispatch = useContext(CurationReviewDispatchContext)
  const editDispatch = useContext(CurationEditDispatchContext)
  const featureFlags = useFeatureFlags()
  const { isPurchasesWithYouEnabled } = featureFlags
  const history = useHistory()

  const [
    addItemToBag,
    {
      error: addItemError,
      isLoading: isAddItemLoading,
      isError: isAddItemError
    }
  ] = useAddItemToBagMutation()

  useEffect(() => {
    if (addItemError) {
      if ('status' in addItemError) {
        const errorMessage = JSON.stringify(addItemError.data)
        const itemUnavailable = errorMessage?.includes(
          'One or more items is not purchasable online'
        )
        setAddedItemUnavailable(itemUnavailable)
      }
    }
  }, [addItemError])

  const onClickCreateCuration = ({
    customerId,
    rmsSku
  }: {
    customerId: string
    rmsSku: string
  }) => {
    !routedFromSnapshot &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_CREATE_BOARD_WITH_ITEM,
        {
          page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
          ocpId: customerId,
          rmsSku: rmsSkuId
        }
      )

    generateNewRelicLogs('createCurationFromCustomerPurchaseHistoryClick', {
      rmsSku: rmsSku,
      ocpId: customerId
    })

    history.push({
      pathname: '/review',
      search: `?createFor=${customerId}&rmsSku=${rmsSku}&productSource=purchaseHistory`
    })
  }

  const onClickAdd = useCallback(
    (rmsSku: string) => {
      shopperId &&
        token &&
        addItemToBag({
          shopperId,
          token,
          rmsSku
        })
          .unwrap()
          .then(() => {
            onItemAdded && onItemAdded(rmsSku)
            dispatch &&
              dispatch({
                type: UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS,
                payload: { rmsSku }
              })
            editDispatch &&
              editDispatch({
                type: UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS,
                payload: { rmsSku }
              })
            return
          })
          .catch(() => null)
    },
    [onItemAdded, addItemToBag, dispatch, editDispatch, shopperId, token]
  )

  return (
    <>
      <Stack direction="row" my={2} spacing={2}>
        <Box sx={{ minWidth: '122px', width: '122px' }}>
          <Button
            aria-label="view product details"
            onClick={() => {
              if (product.style) {
                if (dispatch) {
                  dispatch({
                    type: UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS,
                    payload: { webStyleId: String(product.style) }
                  })
                }
                if (editDispatch) {
                  editDispatch({
                    type: UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS,
                    payload: { webStyleId: String(product.style) }
                  })
                }
              }
              !!product.style && onClickForPDP(String(product.style))
            }}
          >
            <ItemImage
              imageAltText={product.name}
              imageUrl={product.imageUrl}
            />
          </Button>
          {routedFromSnapshot
            ? onItemAdded &&
              isSkuAvailable && (
                <LoadingButton
                  sx={{ width: '100%' }}
                  disabled={isItemAdded}
                  onClick={() => onClickAdd(rmsSkuId)}
                  loading={isAddItemLoading}
                  loadingPosition="center"
                  startIcon={isItemAdded ? <CheckIcon /> : <AddIcon />}
                >
                  {isItemAdded ? 'Added' : 'Add'}
                </LoadingButton>
              )
            : customerId &&
              isSkuAvailable && (
                <Button
                  onClick={() =>
                    onClickCreateCuration({
                      customerId,
                      rmsSku: rmsSkuId
                    })
                  }
                  size="small"
                  sx={{ mt: 1 }}
                >
                  Create Board With Item
                </Button>
              )}
        </Box>
        <Box>
          <Typography variant="body2">
            {product.brandName} {product.name}
          </Typography>
          <Typography variant="body2" mt="4px">
            {product.size} {product.color}
          </Typography>
          <Typography variant="body2" mt="8px">
            ${Number(product.purchasePrice).toFixed(2)}
          </Typography>
          <Typography variant="body2" mt="8px">
            Item #{product.styleNumber}
          </Typography>
          {isPurchasesWithYouEnabled?.active && isAttributedItem && (
            <Box
              sx={{
                backgroundColor: 'rgba(0, 107, 226, 0.12)',
                maxHeight: '24px',
                maxWidth: '141px',
                marginTop: '7px',
                paddingLeft: '2px',
                paddingRight: '2px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Typography fontSize="12px" variant="overline" textAlign="center">
                PURCHASED WITH YOU
              </Typography>
            </Box>
          )}
        </Box>
      </Stack>
      {isAddItemError && (
        <AddItemErrorSnackbar itemUnavailable={addedItemUnavailable} />
      )}
    </>
  )
}

export default OrderItem
