import { ReactElement } from 'react'
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Typography
} from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import UpdateIcon from '@mui/icons-material/Update'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import { LoadingButton } from '@mui/lab'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import { useSelector } from 'react-redux'
import { curationSelector } from 'app/curationSlice'
import oktaTokenStorage from 'utils/okta-token-utils'
import ClearIcon from '@mui/icons-material/Clear'

export interface DropdownOptionT {
  category: string
  label: string
}

interface ProductSearchBarT {
  dropdownOptions?: DropdownOptionT[]
  isLoading: boolean
  onClick: (value: string) => void
  placeholder: string
  query: string
  resetSearch: () => void
  setQuery: (value: string) => void
  onRecentSearchesClearClick?: () => void
}

const iconMapping: { [key: string]: ReactElement } = {
  'Popular Searches': <TrendingUpIcon sx={{ mr: 1 }} />,
  'Recent Searches': <UpdateIcon sx={{ mr: 1 }} />
}

const ProductSearchBar = (props: ProductSearchBarT): ReactElement => {
  const {
    query,
    onClick,
    placeholder,
    resetSearch,
    setQuery,
    isLoading,
    dropdownOptions,
    onRecentSearchesClearClick
  } = props

  const curationDetails = useSelector(curationSelector)

  const onSearchTermSelect = (
    _event: unknown,
    selectedValue: string | DropdownOptionT | null
  ) => {
    const value =
      typeof selectedValue === 'string' ? selectedValue : selectedValue?.label

    if (!value) return

    selectedValue &&
      generateNewRelicLogs('recentSearchTermSelected', {
        ...mapCurationSliceToNewRelicLog(curationDetails),
        employeeId: oktaTokenStorage.getEmployeeNumberFromOktaToken(),
        searchTerm: value
      })
    selectedValue && setQuery(value)
    selectedValue && onClick(value)
  }

  return (
    <Grid container flexDirection="row" spacing={1} flexWrap="nowrap" mb={2}>
      <Grid item flexGrow={1}>
        {dropdownOptions && dropdownOptions.length > 0 ? (
          <Autocomplete
            filterOptions={(x) => x}
            freeSolo
            fullWidth
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.label
            }
            groupBy={(option) => option.category}
            inputValue={query}
            onChange={onSearchTermSelect}
            onInputChange={(_event, newInputValue) => setQuery(newInputValue)}
            options={dropdownOptions}
            placeholder={placeholder}
            size="small"
            renderGroup={(params) => (
              <List>
                <ListSubheader sx={{ color: 'text.primary' }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      {iconMapping[params.group]}
                      <Typography variant="subtitle2">
                        {params.group}
                      </Typography>
                    </Box>
                    {params.group === 'Recent Searches' && (
                      <IconButton onClick={onRecentSearchesClearClick}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </ListSubheader>
                {params.children}
              </List>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  )
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.label}>
                <Typography variant="body2">{option.label}</Typography>
              </ListItem>
            )}
          />
        ) : (
          <TextField
            fullWidth
            InputProps={{
              endAdornment: query && (
                <IconButton aria-label="clear search" onClick={resetSearch}>
                  <HighlightOffIcon />
                </IconButton>
              ),
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              )
            }}
            onChange={(e) => setQuery(e.target.value)}
            onKeyUp={(e) => e.key === 'Enter' && query && onClick(query)}
            placeholder={placeholder}
            size="small"
            value={query}
          />
        )}
      </Grid>
      <Grid item>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => query && onClick(query)}
          sx={{ height: '100%' }}
        >
          Search
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default ProductSearchBar
