import { ReactElement } from 'react'
import { Button, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ItemDetails from 'components/ItemDetails'
import { EnticementDetailsT, PriceTypes } from 'types/Product'
import { determineCurrentPrice } from 'utils/priceHelpers'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { Launch } from '@mui/icons-material'

export type PropsT = {
  brandName?: string
  clearancePrice?: number
  color?: string
  currentPriceType?: PriceTypes
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  itemName?: string
  itemNumber?: string
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  promotionPrice?: number
  regularPrice?: number
  rmsSku?: string
  size?: string
  webStyleId?: string
  miniPDPOpenedFrom: MiniPDPLocationsT
  lastPurchased?: string
  isStyleOnlinePurchasable?: boolean
  isOnlinePurchasableStatusLoading?: boolean
  isGetProductsByWebStyleError?: boolean
}

const RecentItemCard = (props: PropsT): ReactElement | null => {
  const {
    brandName,
    clearancePrice,
    color,
    currentPriceType,
    enticements,
    imageUrl,
    itemName,
    itemNumber,
    onClickForMiniPDP,
    promotionPrice,
    regularPrice,
    rmsSku,
    size,
    webStyleId,
    lastPurchased,
    miniPDPOpenedFrom,
    isStyleOnlinePurchasable,
    isOnlinePurchasableStatusLoading,
    isGetProductsByWebStyleError
  } = props

  if (!rmsSku && !webStyleId) {
    return null
  }

  const currentPrice = determineCurrentPrice({
    clearancePrice: clearancePrice,
    priceType: currentPriceType,
    promotionPrice: promotionPrice,
    regularPrice: regularPrice
  })

  const shouldShowAddButton =
    isStyleOnlinePurchasable ||
    isGetProductsByWebStyleError ||
    isOnlinePurchasableStatusLoading ||
    isStyleOnlinePurchasable === undefined

  return (
    <Grid container display="flex" alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={10} display="flex">
        <ItemDetails
          brandName={brandName}
          color={color}
          currentPrice={currentPrice}
          enticements={enticements}
          imageUrl={imageUrl}
          isStyleOnlinePurchasable={isStyleOnlinePurchasable}
          isOnlinePurchasableStatusLoading={isOnlinePurchasableStatusLoading}
          itemName={itemName}
          itemNumber={itemNumber}
          onClickForMiniPDP={onClickForMiniPDP}
          regularPrice={regularPrice}
          size={size}
          webStyleId={webStyleId}
          rmsSku={rmsSku}
          lastPurchased={lastPurchased}
          miniPDPOpenedFrom={miniPDPOpenedFrom}
        />
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={2}>
        <Button
          onClick={() =>
            webStyleId &&
            rmsSku &&
            onClickForMiniPDP({
              webStyleId,
              miniPDPOpenedFrom,
              rmsSku
            })
          }
          startIcon={shouldShowAddButton ? <AddIcon /> : <Launch />}
        >
          {shouldShowAddButton ? 'Add' : 'View'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default RecentItemCard
