import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import isEmpty from 'lodash/isEmpty'
import setStandardHeaders from 'utils/set-standard-headers'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  ConsentedCustomerT,
  GetConsentedCustomerQueryParamsT,
  ActivateCommunicationsResponseT,
  ConsentedCustomerListItemT,
  GetConsentedCustomerListResponseT,
  ArchivedCustomerT,
  ExtendedCustomerListByIdTransformedResponseT
} from 'types/ConsentedCustomer'
import { EmployeeT, GetEmployeeQueryParamsT } from 'types/Employee'
import { CustomerByIdentifierT, GetCustomerQueryParams } from 'types/Customer'
import {
  CustomerBookFilters,
  CustomerBookFilterOption
} from 'types/CustomerBookFilters'
import {
  initializeNordyClubFilter,
  initializeCardmemberFilter,
  initializeSavedBrandsFilter,
  initializeAnniversaryFilter,
  initializeNotesFilter,
  initializeTripsCountFilter,
  initializeLastMessageTimeFilter,
  getFilterFromStorage,
  setFilters,
  initializeLastPurchaseDateTimeFilter,
  initializeLastPurchaseDateWithEmployeeTimeFilter,
  initializeNordyClubBenefitsFilter
} from 'services/customerBookFiltersSlice'
import {
  CommissionAttributedOrderT,
  CommissionAttributedProductsAndStatusT,
  ConsentedCustomerOrderT,
  OrderStatusT
} from 'types/Orders'
import { getTransformedExtendedCustomerListResponse } from './utils'
import {
  CompletedFollowUpResponseT,
  FollowUpsResponse,
  FollowUpCustomersData,
  PaginatedDTO,
  CompletedFollowUpForCustomerResponseT,
  CompletedFollowUpsForEmployeeResponseT,
  ExtendedFollowUpT
} from 'types/FollowUps'
import { CommissionAttributedProductT } from 'types/Product'
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks'

const customErrorHandlerQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>
) => {
  const result = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CLIENTELING_URL,
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })

      const accessToken = oktaTokenStorage.getOktaAccessToken()
      if (accessToken) {
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        headers.set('employee-id', employeeId)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }
  })(args, api, extraOptions)
  if (result && result.error) {
    const nordRequestId = result.meta?.request.headers.get('nord-request-id')
    const traceContext = result.meta?.request.headers.get('TraceContext')

    // To bail out of retries if there is any timeout from server
    if (result.error?.status === 499 || result.error?.status === 504) {
      retry.fail(result.error)
    }

    const errorWithMetaData = {
      error: {
        ...result.error,
        errorMetaData: { nordRequestId, traceContext }
      }
    }

    // We don't want endpoints to retry when running unit tests. This section allows to do proper testing.
    if (process.env.NODE_ENV === 'test') {
      retry.fail(result.error)
    }

    return errorWithMetaData
  }

  return result
}

const baseQueryWithRetry = retry(customErrorHandlerQuery, { maxRetries: 3 })

export const clientelingApi = createApi({
  reducerPath: 'consentedCustomerListApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['FollowUps', 'CompletedFollowUps'],
  endpoints: (builder) => ({
    getConsentedCustomerList: builder.query<
      {
        customers: ConsentedCustomerListItemT[]
        filters: CustomerBookFilters
        hasArchivedCustomers: boolean
      },
      {
        retrieveFilters: boolean
        fetchAdditionalCustomerIds?: boolean
        retrieveQualification: boolean
      }
    >({
      query: (arg) => {
        const { retrieveFilters, retrieveQualification } = arg
        return {
          url: '/customers',
          params: {
            'retrieve-filters': retrieveFilters,
            'retrieve-qualification': retrieveQualification,
            'retrieve-shopped-brands': true,
            'retrieve-notes': true
          }
        }
      },
      transformResponse: (response: GetConsentedCustomerListResponseT) => {
        let filtersToApply = {} as CustomerBookFilters
        const storedFilters = getFilterFromStorage()
        if (isEmpty(storedFilters)) {
          const initializedFilters: CustomerBookFilters = {
            qualification: initializeAnniversaryFilter(),
            loyaltyStatus: initializeNordyClubFilter(),
            cardmember: initializeCardmemberFilter(),
            nordstromNotes: initializeNotesFilter(),
            benefits: initializeNordyClubBenefitsFilter(),
            tripsCount: initializeTripsCountFilter(),
            lastPurchaseDateWithEmployee:
              initializeLastPurchaseDateWithEmployeeTimeFilter(),
            lastPurchaseDate: initializeLastPurchaseDateTimeFilter(),
            lastMessage: initializeLastMessageTimeFilter(),
            brands: initializeSavedBrandsFilter()
          }
          const savedBrandsOptions: CustomerBookFilterOption[] =
            response.filters.brands.map((brand) => ({
              name: brand,
              checked: false
            }))
          initializedFilters.brands.options = savedBrandsOptions
          filtersToApply = initializedFilters
        } else {
          filtersToApply = storedFilters
          if (
            storedFilters.brands.options.length !==
            response.filters.brands.length
          ) {
            let optionsCounter = 0
            const availableFiltersOptions: CustomerBookFilterOption[] =
              response.filters.brands.map((brand) => {
                const brandInStoredFilter = storedFilters.brands.options.find(
                  (storedBrandOption) => storedBrandOption.name === brand
                )
                const isChecked = brandInStoredFilter?.checked || false
                optionsCounter = isChecked ? optionsCounter + 1 : optionsCounter
                return {
                  name: brand,
                  checked: isChecked
                }
              })
            filtersToApply.brands.options = availableFiltersOptions
            filtersToApply.brands.selectedOptionsCounter = optionsCounter
          }
        }
        return {
          customers: response.customers,
          filters: filtersToApply,
          hasArchivedCustomers: response.hasArchivedCustomers
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setFilters({ filters: data.filters }))
        } catch (error) {
          console.error('error: ', error)
          // return error
        }
      }
    }),
    getArchivedCustomers: builder.query<
      { customers: ArchivedCustomerT[] },
      void
    >({
      query: () => {
        return {
          url: '/customers/archived',
          method: 'GET'
        }
      }
    }),
    getConsentedCustomer: builder.query<
      { customer: ConsentedCustomerT },
      GetConsentedCustomerQueryParamsT
    >({
      query: (arg) => {
        const {
          customerId,
          retrieveQualification,
          retrieveShoppedBrands,
          retrieveSavedSizes,
          retrieveFollowUps,
          fetchAdditionalCustomerIds,
          relationshipBypass
        } = arg
        return {
          url: `/customers/${customerId}`,
          params: {
            'retrieve-qualification': retrieveQualification,
            'retrieve-shopped-brands': retrieveShoppedBrands,
            'retrieve-saved-sizes': retrieveSavedSizes
          },
          method: 'GET',
          headers: {
            'fetch-additional-customer-ids':
              fetchAdditionalCustomerIds?.toString(),
            'retrieve-follow-ups': retrieveFollowUps?.toString(),
            ...(relationshipBypass && {
              'relationship-bypass': relationshipBypass.toString()
            })
          }
        }
      },
      transformResponse: (response: ConsentedCustomerT) => {
        return {
          customer: response
        }
      }
    }),
    getConsentedCustomerPurchaseHistory: builder.query<
      { numberOfTrips: number; orders: CommissionAttributedOrderT[] },
      Pick<GetConsentedCustomerQueryParamsT, 'customerId'>
    >({
      query: (arg) => {
        const { customerId } = arg
        return {
          url: `/customers/${customerId}/purchase-history`,
          method: 'GET'
        }
      },
      transformResponse: (response: {
        numberOfTrips: number
        orders: ConsentedCustomerOrderT[]
      }): { numberOfTrips: number; orders: CommissionAttributedOrderT[] } => ({
        numberOfTrips: response.numberOfTrips,
        orders: response.orders.map((order) => {
          const productsAndStatus = order.status.map(
            (status): CommissionAttributedProductsAndStatusT => {
              const products = status.items.map(
                (item): CommissionAttributedProductT => ({
                  name: item.productName || '',
                  brandName: item.brandName || '',
                  size: item.size || '',
                  color: item.color || '',
                  purchasePrice: item.purchasePrice || 0,
                  id: item.id || '',
                  styleNumber: item.styleNumber || '',
                  style: item.style || 0,
                  discount: item.discount || 0,
                  imageUrl: item.imageUrl || '',
                  description: item.description || '',
                  isSkuAvailable: item.isSkuAvailable || false,
                  quantity: item.quantity || 0,
                  commissionSalespersonId: item.commissionSalespersonId
                })
              )

              return {
                sortValue:
                  OrderStatusT[
                    (status.deliveryStatusCode ||
                      'UNKNOWN') as keyof typeof OrderStatusT
                  ] || OrderStatusT.UNKNOWN,
                deliveryStatus: status.deliveryStatus ?? '',
                products,
                trackingUrl: status.trackingUrl
              }
            }
          )

          productsAndStatus.sort(
            (first, second) => first.sortValue - second.sortValue
          )

          const formattedOrder: CommissionAttributedOrderT = {
            orderType: order.orderType || '',
            orderId: order.orderId || '',
            orderDate: order.orderDate || '',
            totalItemCount: productsAndStatus.reduce(
              (totalItemCount, p) => totalItemCount + p.products.length,
              0
            ),
            itemSubtotal: order.itemSubTotal || 0,
            shippingCharge: order.shippingCharge || 0,
            employeeDiscount: order.employeeDiscount || 0,
            salesTax: order.salesTax || 0,
            totalPrice: order.totalPrice || 0,
            storeName: order.purchaseStore?.name || '',
            billingInfo: {
              city: order.billingAddress?.city || '',
              state: order.billingAddress?.state || '',
              country: order.billingAddress?.country || '',
              tenderType: order.payments?.[0]?.tenderType || ''
            },
            productsAndStatus: productsAndStatus
          }

          return formattedOrder
        })
      })
    }),
    getExtendedCustomerListData: builder.query<
      ExtendedCustomerListByIdTransformedResponseT,
      {
        shouldFetchTripsCount: boolean
        shouldFetchLastPurchaseDate: boolean
        shouldFetchLastPurchaseDateWithEmployee: boolean
      }
    >({
      query: ({
        shouldFetchTripsCount,
        shouldFetchLastPurchaseDate,
        shouldFetchLastPurchaseDateWithEmployee
      }) => {
        return {
          url: '/customers/extended-summary',
          method: 'GET',
          headers: {
            'fetch-customer-list-trips': shouldFetchTripsCount.toString(),
            'fetch-last-purchase-date': shouldFetchLastPurchaseDate.toString(),
            'fetch-last-purchase-date-with-employee':
              shouldFetchLastPurchaseDateWithEmployee.toString()
          }
        }
      },
      transformResponse: getTransformedExtendedCustomerListResponse,
      keepUnusedDataFor: 28800 // 8 hours
    }),
    activateEmployeeCommunications: builder.mutation<
      ActivateCommunicationsResponseT,
      Pick<ActivateCommunicationsResponseT, 'employeeId'>
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: '/employees',
          method: 'POST',
          body: {
            employeeId
          }
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { employeeId } = args
        try {
          await queryFulfilled
          dispatch(
            clientelingApi.endpoints.userHasActiveCommunications.initiate(
              { employeeId },
              { forceRefetch: true }
            )
          )
        } catch (error) {
          // what to do with the error?
          console.log('error: ', error)
        }
      }
    }),
    userHasActiveCommunications: builder.query<
      { isActive: boolean },
      { employeeId: string }
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: `/employees/${employeeId}/active`,
          method: 'GET'
        }
      },
      extraOptions: { maxRetries: 0 }
    }),
    getEmployee: builder.query<
      { employee: EmployeeT },
      GetEmployeeQueryParamsT
    >({
      query: (arg) => {
        const { employeeId } = arg
        return {
          url: `/employees/${employeeId}`,
          method: 'GET'
        }
      },
      transformResponse: (response: EmployeeT) => {
        return {
          employee: response
        }
      }
    }),
    getConsentedCustomerListWithoutFilters: builder.query<
      {
        customers: ConsentedCustomerListItemT[]
      },
      void
    >({
      query: () => {
        return {
          url: '/customers'
        }
      },
      transformResponse: (response: GetConsentedCustomerListResponseT) => {
        return {
          customers: response.customers
        }
      }
    }),
    updateCustomerArchiveStatus: builder.mutation<
      { relationshipCustomerId: string; archived: boolean },
      {
        relationshipCustomerId: string
        archived: boolean
      }
    >({
      query: (arg) => {
        const { relationshipCustomerId, archived } = arg
        return {
          url: `/relationships/customers/${relationshipCustomerId}`,
          method: 'PATCH',
          body: {
            archived
          }
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            clientelingApi.endpoints.getConsentedCustomerList.initiate(
              {
                retrieveFilters: true,
                retrieveQualification: true
              },
              { forceRefetch: true }
            )
          )
          dispatch(
            clientelingApi.endpoints.getArchivedCustomers.initiate(undefined, {
              forceRefetch: true
            })
          )
        } catch (error) {
          console.log('error: ', error)
          throw error
        }
      }
    }),
    getCustomerByIdentifier: builder.mutation<
      CustomerByIdentifierT,
      GetCustomerQueryParams
    >({
      query: (arg) => {
        const { identifier, searchBy, retrieveQualification } = arg
        return {
          url: `consent/customers/${identifier}`,
          params: {
            searchBy,
            'retrieve-qualification': retrieveQualification
          },
          method: 'GET'
        }
      },
      extraOptions: { maxRetries: 0 }
    }),
    getCustomerByEmail: builder.query<CustomerByIdentifierT, { email: string }>(
      {
        query: ({ email }) => {
          return {
            url: `consent/customers/${email}?searchBy=EMAIL`,
            method: 'GET'
          }
        },
        extraOptions: { maxRetries: 0 }
      }
    ),
    postInvitation: builder.mutation<
      void,
      { customerId: string; employeeId: string; inviteType: string }
    >({
      query: ({ customerId, employeeId, inviteType }) => {
        return {
          url: `consent/invitation`,
          method: 'POST',
          body: {
            customerId,
            employeeId,
            type: inviteType
          }
        }
      },
      extraOptions: { maxRetries: 0 }
    }),
    getFollowUpOpportunities: builder.query<FollowUpCustomersData, void>({
      query: () => {
        return {
          url: 'follow-ups',
          method: 'GET'
        }
      },
      providesTags: ['FollowUps'],
      transformResponse: (response: FollowUpsResponse) => {
        const customersWithFollowUpsSortData = Object.entries(
          response.customers
        )
          .map(([customerId, customer]) => {
            const mostRecentDate = Math.max(
              new Date(customer.relationshipDate).getTime(),
              ...customer.followUps
                .filter((followUp) => !!followUp.meta?.purchaseDate)
                .map((followUp) =>
                  // NOTE: We know we're filtering by purchaseDate
                  // so the default date value is just to keep the compiler happy
                  new Date(followUp.meta?.purchaseDate ?? new Date()).getTime()
                )
            )

            return { customerId, customer, mostRecentDate }
          })
          .sort((a, b) => b.mostRecentDate - a.mostRecentDate)

        return Object.fromEntries(
          customersWithFollowUpsSortData.map(({ customerId, customer }) => [
            customerId,
            customer
          ])
        )
      }
    }),
    deleteFollowUpOpportunity: builder.mutation<void, { id: number }>({
      query: (arg) => {
        const { id } = arg
        return {
          url: `follow-ups/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['CompletedFollowUps', 'FollowUps']
    }),
    markFollowUpCompleted: builder.mutation<
      CompletedFollowUpResponseT,
      Omit<CompletedFollowUpResponseT, 'id'> & {
        isBatch?: boolean
        wasCalledFromDetailsPage?: boolean
      } & {
        getCustomerQueryParams?: Omit<
          GetConsentedCustomerQueryParamsT,
          'customerId'
        >
      }
    >({
      query: ({ employeeId, customerId, kind, meta }) => {
        return {
          url: `follow-ups/completed`,
          method: 'POST',
          body: {
            employeeId,
            customerId,
            kind,
            ...(meta && { meta })
          }
        }
      },
      invalidatesTags: ['FollowUps', 'CompletedFollowUps'],
      async onQueryStarted(
        {
          customerId,
          kind,
          isBatch = false,
          meta,
          wasCalledFromDetailsPage = false,
          getCustomerQueryParams
        },
        { dispatch, queryFulfilled }
      ) {
        type QueryToArgs = {
          getConsentedCustomer: GetConsentedCustomerQueryParamsT
          getFollowUpOpportunities: void
        }

        type QueryToUpdater = {
          getConsentedCustomer: Recipe<{ customer: ConsentedCustomerT }>
          getFollowUpOpportunities: Recipe<FollowUpCustomersData>
        }

        const updateFollowUps = async <T extends keyof QueryToArgs>(
          queryKey: keyof QueryToArgs,
          queryArgs: QueryToArgs[T],
          stateUpdater: QueryToUpdater[T]
        ) => {
          const localUpdateResult = dispatch(
            clientelingApi.util.updateQueryData(
              queryKey,
              queryArgs,
              stateUpdater as Recipe<
                { customer: ConsentedCustomerT } | FollowUpCustomersData
              >
            )
          )
          try {
            await queryFulfilled
          } catch {
            localUpdateResult.undo()
          }
        }

        const isNotTheCompletedFollowUp = (
          extendedFollowUp: ExtendedFollowUpT
        ) =>
          meta?.orderId
            ? extendedFollowUp.meta?.orderId !== meta?.orderId &&
              extendedFollowUp.followUpKind !== kind
            : extendedFollowUp.followUpKind !== kind

        const updateGetConsentedCustomerState: Recipe<{
          customer: ConsentedCustomerT
        }> = (draft) => {
          const remainingFollowUps = draft.customer.followUps?.filter(
            isNotTheCompletedFollowUp
          )

          if (remainingFollowUps?.length !== 0) {
            draft.customer.followUps = remainingFollowUps
          } else {
            delete draft.customer.followUps
          }
        }

        const updateGetFollowUpOpportunitiesState: Recipe<FollowUpCustomersData> =
          (draft) => {
            const remainingFollowUps = draft[customerId].followUps.filter(
              isNotTheCompletedFollowUp
            )

            if (remainingFollowUps.length !== 0) {
              Object.assign(draft, {
                ...draft,
                [customerId]: {
                  ...draft[customerId],
                  followUps: remainingFollowUps
                }
              })
            } else {
              delete draft[customerId]
            }
          }

        if (wasCalledFromDetailsPage) {
          await updateFollowUps(
            'getConsentedCustomer',
            { customerId, ...getCustomerQueryParams },
            updateGetConsentedCustomerState
          )
        } else {
          if (!isBatch) {
            await updateFollowUps(
              'getFollowUpOpportunities',
              undefined,
              updateGetFollowUpOpportunitiesState
            )
          } else {
            await queryFulfilled
          }
        }
      }
    }),
    getCompletedFollowUpsForCustomer: builder.query<
      PaginatedDTO<CompletedFollowUpForCustomerResponseT>,
      { customerId: string; page: number; pageSize: number }
    >({
      query: ({ customerId, page, pageSize }) => {
        return {
          url: `follow-ups/${customerId}/completed`,
          method: 'GET',
          params: {
            page,
            pageSize
          }
        }
      }
    }),
    getCompletedFollowUpsForEmployee: builder.query<
      PaginatedDTO<CompletedFollowUpsForEmployeeResponseT>,
      { page: number; pageSize: number }
    >({
      query: ({ page, pageSize }) => {
        return {
          url: `follow-ups/completed`,
          method: 'GET',
          params: {
            page,
            pageSize
          }
        }
      },
      providesTags: ['CompletedFollowUps']
    })
  })
})

export const {
  useGetConsentedCustomerListQuery,
  useLazyGetConsentedCustomerListQuery,
  useGetConsentedCustomerQuery,
  useGetConsentedCustomerPurchaseHistoryQuery,
  useLazyGetExtendedCustomerListDataQuery,
  useActivateEmployeeCommunicationsMutation,
  useUserHasActiveCommunicationsQuery,
  useGetEmployeeQuery,
  useGetConsentedCustomerListWithoutFiltersQuery,
  useGetArchivedCustomersQuery,
  useUpdateCustomerArchiveStatusMutation,
  useGetCustomerByIdentifierMutation,
  usePostInvitationMutation,
  useGetFollowUpOpportunitiesQuery,
  useDeleteFollowUpOpportunityMutation,
  useMarkFollowUpCompletedMutation,
  useGetCompletedFollowUpsForCustomerQuery,
  useGetCompletedFollowUpsForEmployeeQuery,
  useGetCustomerByEmailQuery
} = clientelingApi
