import { ReactElement } from 'react'
import SubPageTemplateWithPageAppBar from 'components/SubPageTemplateWithPageAppBar'
import { Container, List, Stack } from '@mui/material'
import AppointmentDetailsItem from '../components/AppointmentDetailsItem'
import { useGetCustomerByEmailQuery } from 'services/clientelingApi'
import { useGetAppointmentQuery } from 'services/appointmentsService'
import LoadingSpinner from 'components/LoadingSpinner'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import CustomerInfo from './components/CustomerInfo'
import PageError from 'components/PageError'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import CustomerDetailsTabs from './components/CustomerDetailsTabs'

const AppointmentDetails = (): ReactElement => {
  const { appointmentId }: { appointmentId: string } = useParams()
  const { isAppointmentDetailsTabsEnabled } = useFeatureFlags()

  const {
    data: appointment,
    isLoading: isAppointmentDetailsLoading,
    isError: isAppointmentDetailsError,
    error,
    endpointName
  } = useGetAppointmentQuery({ appointmentId: appointmentId })

  const {
    data: customerData,
    isLoading: isGetCustomerDataLoading,
    isError: isGetCustomerDataError,
    error: getCustomerDataError,
    endpointName: getCustomerDataEndpointName
  } = useGetCustomerByEmailQuery(
    {
      email: appointment?.client?.email || ''
    },
    { skip: !appointment?.client?.email }
  )

  const isOtherThan404Error =
    isGetCustomerDataError &&
    getCustomerDataError &&
    'status' in getCustomerDataError &&
    getCustomerDataError.status !== 404

  const appointmentDate = moment(appointment?.startAt).calendar(null, {
    sameDay: '[Today], LL',
    nextDay: '[Tomorrow], LL',
    lastDay: '[Yesterday], LT',
    sameElse: 'LL'
  })

  const formatTimeRange = (startTime: Date, endTime: Date): string => {
    const start = moment(startTime)
    const end = moment(endTime)
    const format = 'h:mm'
    let startFormat =
      start.minute() === 0 ? start.format('h') : start.format(format)
    let endFormat = end.minute() === 0 ? end.format('h') : end.format(format)

    if (start.format('a') === end.format('a')) {
      endFormat += end.format('a')
    } else {
      startFormat += start.format('a')
      endFormat += end.format('a')
    }

    return `${startFormat}–${endFormat}`
  }

  if (isAppointmentDetailsLoading) {
    return <LoadingSpinner />
  }

  if (isAppointmentDetailsError) {
    return (
      <SubPageTemplateWithPageAppBar title={'Appointment details'}>
        <PageError
          errorTitle="Error retrieving appointment details - "
          errorDetails={{ endpoint: endpointName, errorData: error }}
        />
      </SubPageTemplateWithPageAppBar>
    )
  }

  return (
    <SubPageTemplateWithPageAppBar title={'Appointment details'}>
      <Container>
        <Stack spacing={0} bgcolor={'#F9FAFA'}>
          <List sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
            <AppointmentDetailsItem title="Date">
              {appointmentDate}
            </AppointmentDetailsItem>
            <AppointmentDetailsItem title="Times & services">
              <table>
                <colgroup>
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '100%' }} />
                </colgroup>
                <tbody>
                  {appointment?.appointmentServices?.map((service, index) => (
                    <tr key={index}>
                      <td
                        style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}
                      >
                        {formatTimeRange(service.startAt, service.endAt)}
                      </td>
                      <td style={{ paddingLeft: '5px' }}>
                        {service?.service?.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AppointmentDetailsItem>
            <AppointmentDetailsItem title="Location">
              {appointment?.location?.name}
            </AppointmentDetailsItem>
            {appointment?.customFields?.occasion && (
              <AppointmentDetailsItem title="Occasion">
                {appointment.customFields?.occasion}
              </AppointmentDetailsItem>
            )}
            {appointment?.customFields?.style && (
              <AppointmentDetailsItem title="Style">
                {appointment.customFields?.style}
              </AppointmentDetailsItem>
            )}
            {(appointment?.customFields?.sizesTops ||
              appointment?.customFields?.sizesBottoms ||
              appointment?.customFields?.sizesShoes ||
              appointment?.customFields?.sizesDresses) && (
              <AppointmentDetailsItem title="Sizes">
                <Stack>
                  {appointment.customFields.sizesTops && (
                    <span>Tops: {appointment?.customFields?.sizesTops}</span>
                  )}
                  {appointment?.customFields?.sizesBottoms && (
                    <span>
                      Bottoms: {appointment?.customFields?.sizesBottoms}
                    </span>
                  )}
                  {appointment?.customFields?.sizesDresses && (
                    <span>
                      Dresses: {appointment?.customFields?.sizesDresses}
                    </span>
                  )}
                  {appointment?.customFields?.sizesShoes && (
                    <span>Shoes: {appointment?.customFields?.sizesShoes}</span>
                  )}
                </Stack>
              </AppointmentDetailsItem>
            )}
            {(appointment?.customFields?.budgetTops ||
              appointment?.customFields?.budgetBottoms ||
              appointment?.customFields?.budgetShoes ||
              appointment?.customFields?.budgetDresses) && (
              <AppointmentDetailsItem title="Budget">
                <Stack>
                  {appointment?.customFields?.budgetTops && (
                    <span>
                      Tops: up to {appointment?.customFields?.budgetTops}
                    </span>
                  )}
                  {appointment?.customFields?.budgetBottoms && (
                    <span>
                      Bottoms: up to {appointment?.customFields?.budgetBottoms}
                    </span>
                  )}
                  {appointment?.customFields?.budgetDresses && (
                    <span>
                      Dresses: up to {appointment?.customFields?.budgetDresses}
                    </span>
                  )}
                  {appointment?.customFields?.budgetShoes && (
                    <span>
                      Shoes: up to {appointment?.customFields?.budgetShoes}
                    </span>
                  )}
                </Stack>
              </AppointmentDetailsItem>
            )}
            {appointment?.customFields?.includeBeautyItems && (
              <AppointmentDetailsItem
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px'
                    }}
                  >
                    <span>Include beauty</span>
                    <CheckIcon
                      fontSize="small"
                      color="action"
                      style={{ marginTop: '2px' }}
                    />
                  </div>
                }
              ></AppointmentDetailsItem>
            )}
            {appointment?.customFields?.notes && (
              <AppointmentDetailsItem title="Notes">
                {appointment.customFields.notes}
              </AppointmentDetailsItem>
            )}
          </List>
          <CustomerInfo
            customerName={`${appointment?.client?.firstName} ${appointment?.client?.lastName}`}
            customerPhone={appointment?.client?.mobilePhone || ''}
            customerEmail={appointment?.client?.email || ''}
            appointmentId={appointmentId}
            customerId={customerData?.customerId}
            customerRelationshipStatus={customerData?.relationshipStatus}
            customerInvitationStatus={customerData?.invitationStatus}
            isGetCustomerDataLoading={isGetCustomerDataLoading}
            isGetCustomerDataError={isOtherThan404Error}
            getCustomerDataError={getCustomerDataError}
            getCustomerDataEndpointName={getCustomerDataEndpointName || ''}
          />
          {isAppointmentDetailsTabsEnabled?.active && !isOtherThan404Error && (
            <CustomerDetailsTabs
              customerId={customerData?.customerId || ''}
              isCustomerDataLoading={isGetCustomerDataLoading}
            />
          )}
        </Stack>
      </Container>
    </SubPageTemplateWithPageAppBar>
  )
}

export default AppointmentDetails
