import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardActions,
  Typography,
  Button,
  CardContent
} from '@mui/material'
import moment from 'moment'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { AppointmentListAppointmentT } from 'types/Appointments'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import oktaTokenStorage from 'utils/okta-token-utils'

const AppointmentCard = ({
  appointment
}: {
  appointment: AppointmentListAppointmentT
}): ReactElement => {
  const history = useHistory()
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const onClick = () => {
    generateNewRelicLogs('appointmentDetailsButtonClick', {
      employeeId,
      appointmentId: appointment.appointmentId,
      appointmentStartAt: appointment.startAt
    })
    history.push(`/appointments/${appointment.appointmentId}`)
  }

  return (
    <>
      <Typography fontWeight="bold" sx={{ mt: 3 }}>
        {`${moment(appointment.startAt).format('h:mma')}–${moment(
          appointment.endAt
        ).format('h:mma')}`}
      </Typography>
      <Card variant="outlined" sx={{ maxWidth: '418px', mt: 2 }}>
        <CardContent>
          <Typography fontWeight="bold">{appointment.clientName}</Typography>
          <Typography>{appointment.serviceName}</Typography>
        </CardContent>
        <CardActions sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClick}>
            Details
            <ArrowForwardIosIcon sx={{ ml: 1 }} />
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default AppointmentCard
