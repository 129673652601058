import { Box, Typography } from '@mui/material'
import PageError from 'components/PageError'
import { CopyErrorDataT } from 'types/CopyErrorData'
import { CONSENTED_CUSTOMER_TRIPS_TITLE } from './constants'

type PropsT = {
  errorDetails: CopyErrorDataT
}

const ConsentedCustomerTripsError = ({ errorDetails }: PropsT): JSX.Element => {
  return (
    <Box
      sx={{
        paddingY: '16px'
      }}
    >
      <Typography variant="subtitle2">
        {CONSENTED_CUSTOMER_TRIPS_TITLE}
      </Typography>
      <PageError
        errorTitle="Issue getting trips with you - try again"
        isInlineAlert={true}
        errorDetails={errorDetails}
        customBoxStyle={{ mt: '0px', px: '0px', py: '0px' }}
        customAlertStyle={{
          padding: '0px',
          backgroundColor: 'white',
          color: '#D6243D',
          '& .MuiAlert-icon': {
            color: '#D6243D'
          }
        }}
      />
    </Box>
  )
}

export default ConsentedCustomerTripsError
