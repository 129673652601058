import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { emDashCharacter } from 'pages/constants'
import PageError from 'components/PageError'
import {
  useFashionMapFiltersMutation,
  useFashionMapSearchMutation
} from 'services/employeeExperienceApi'
import { DEPARTMENTS, FilterOptionT, GendersT } from 'types/FashionMapSearch'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { curationSelector } from 'app/curationSlice'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import DrawerNavigation from 'components/DrawerNavigation'
import FilterOptions from 'pages/FashionMapSearchDrawer/components/FilterOptions'
import CurationFiltersButton from 'components/CurationFiltersButton'
import SearchHelperText from '../SearchHelperText'
import ProductSearchBar from '../ProductSearchBar'
import ProductSearchResults from '../ProductSearchResults'
import ClearAllFiltersButton from '../ClearAllFiltersButton'
import { SEARCH_OPTIONS } from 'pages/SearchDrawer/SearchDrawer'

interface PropsT {
  handleClickForPdp: (styleId: string) => void
  onClose: () => void
  searchToggleButtons: JSX.Element | null
}

const SEARCH_BAR_PLACE_HOLDER = 'Search for products'
export const FILTER_ERROR_TITLE = `Issue getting filters ${emDashCharacter} try again`
const DEFAULT_FASHION_MAP_SEARCH_RESULT_SIZE = 20

const mapSelectedDepartmentToGender = (
  filters: string[]
): GendersT | undefined => {
  if (
    filters?.includes(DEPARTMENTS.MENSWEAR) &&
    filters?.includes(DEPARTMENTS.WOMENSWEAR)
  ) {
    return undefined
  } else if (filters?.includes(DEPARTMENTS.MENSWEAR)) {
    return 'male'
  } else if (filters?.includes(DEPARTMENTS.WOMENSWEAR)) {
    return 'female'
  }
}

const FashionMapSearch = (props: PropsT): ReactElement => {
  const { handleClickForPdp, onClose, searchToggleButtons } = props
  const curationDetails = useAppSelector(curationSelector)
  const resultSize =
    Number(useFeatureFlags().fashionMapSearchResultSize?.payload) ||
    DEFAULT_FASHION_MAP_SEARCH_RESULT_SIZE

  const [query, setQuery] = useState<string>('')
  const [showingFilters, setShowingFilters] = useState(false)
  const [selectedBrandFilters, setSelectedBrandFilters] = useState<string[]>([])
  const [selectedDepartmentFilters, setSelectedDepartmentFilters] = useState<
    string[]
  >([])
  const [selectedProductTypeFilters, setSelectedProductTypeFilters] = useState<
    string[]
  >([])

  const [search, { data, error, isError, isLoading, reset }] =
    useFashionMapSearchMutation()

  const [
    retrieveFilters,
    {
      data: filterData,
      endpointName: filterEndpoint,
      error: filterError,
      isError: isFilterError,
      isLoading: isFilterLoading
    }
  ] = useFashionMapFiltersMutation()

  const memoizedDepartmentsFilters: FilterOptionT[] = useMemo(() => {
    return Object.values(DEPARTMENTS).map((option, index) => {
      return {
        id: index,
        name: option,
        isSelected: selectedDepartmentFilters.includes(option)
      }
    })
  }, [selectedDepartmentFilters])

  const memoizedBrandsFilters: FilterOptionT[] = useMemo(() => {
    return (
      filterData?.results.brands.map((option, index) => {
        return {
          id: index,
          name: option,
          isSelected: selectedBrandFilters.includes(option)
        }
      }) || []
    )
  }, [filterData?.results.brands, selectedBrandFilters])

  const memoizedProductTypesFilters = useMemo(() => {
    return (
      filterData?.results.productTypes.map((option, index) => {
        return {
          id: index,
          name: option,
          isSelected: selectedProductTypeFilters.includes(option)
        }
      }) || []
    )
  }, [filterData?.results.productTypes, selectedProductTypeFilters])

  const clearSelectedFilters = () => {
    setSelectedBrandFilters([])
    setSelectedDepartmentFilters([])
    setSelectedProductTypeFilters([])
  }

  const handleDrawerClickaway = () => {
    if (showingFilters) {
      setShowingFilters(false)
    }
    onClose()
  }

  const onFiltersButtonClick = () => {
    if (!filterData) {
      retrieveFilters({})
        .unwrap()
        .then(() => setShowingFilters(true))
        .catch(() => null)
    } else {
      setShowingFilters(true)
    }
  }

  const onFiltersBackButtonClick = () => {
    if (query) {
      const attributes = {
        curationId: curationDetails?.id,
        type: curationDetails?.type,
        shoppingSessionId: curationDetails?.shoppingSessionId,
        searchTerm: query,
        hasBrandFilters: selectedBrandFilters.length > 0,
        hasDepartmentFilters: selectedDepartmentFilters.length > 0,
        hasProductTypeFilters: selectedProductTypeFilters.length > 0
      }
      generateNewRelicLogs('searchFMS', attributes)
      search({
        searchText: query,
        filterByProductTypes: selectedProductTypeFilters,
        filterByBrands: selectedBrandFilters,
        gender: mapSelectedDepartmentToGender(selectedDepartmentFilters),
        resultSize
      })
    }
    setShowingFilters(false)
  }

  const onSelectFilter = useCallback(
    ({
      currentFilter,
      isSelected,
      setSelectedFilters
    }: {
      currentFilter: string
      isSelected: boolean
      setSelectedFilters: (value: React.SetStateAction<string[]>) => void
    }) => {
      if (isSelected) {
        setSelectedFilters((prev) => [...prev, currentFilter])
      } else {
        setSelectedFilters((prev) => {
          const unselectedFilterIndex = prev.indexOf(currentFilter)
          prev.splice(unselectedFilterIndex, 1)
          return [...prev]
        })
      }
    },
    []
  )

  const onSelectDepartment = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedDepartmentFilters
      })
    },
    [onSelectFilter]
  )

  const onSelectBrand = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedBrandFilters
      })
    },
    [onSelectFilter]
  )

  const onSelectProductType = useCallback(
    ({
      currentFilter,
      isSelected
    }: {
      currentFilter: string
      isSelected: boolean
    }) => {
      onSelectFilter({
        currentFilter,
        isSelected,
        setSelectedFilters: setSelectedProductTypeFilters
      })
    },
    [onSelectFilter]
  )

  const resetSearch = () => {
    setQuery('')
    reset()
  }

  return (
    <>
      <Box px={2}>
        <DrawerNavigation
          headerEndButton={
            <ClearAllFiltersButton onClear={clearSelectedFilters} />
          }
          onClick={
            showingFilters ? onFiltersBackButtonClick : handleDrawerClickaway
          }
          showBackButton={showingFilters}
          title={showingFilters ? 'Filters' : 'Search'}
        />
      </Box>
      {showingFilters ? (
        <FilterOptions
          brandFilters={memoizedBrandsFilters}
          departmentFilters={memoizedDepartmentsFilters}
          productTypeFilters={memoizedProductTypesFilters}
          onSelectBrand={onSelectBrand}
          onSelectDepartment={onSelectDepartment}
          onSelectProductType={onSelectProductType}
        />
      ) : (
        <Box sx={{ px: 2 }}>
          <ProductSearchBar
            query={query}
            resetSearch={resetSearch}
            setQuery={setQuery}
            placeholder={SEARCH_BAR_PLACE_HOLDER}
            isLoading={!!isLoading}
            onClick={() => {
              const attributes = {
                curationId: curationDetails?.id,
                type: curationDetails?.type,
                shoppingSessionId: curationDetails?.shoppingSessionId,
                searchTerm: query,
                hasBrandFilters: selectedBrandFilters.length > 0,
                hasDepartmentFilters: selectedDepartmentFilters.length > 0,
                hasProductTypeFilters: selectedProductTypeFilters.length > 0
              }
              generateNewRelicLogs('searchFMS', attributes)
              search({
                searchText: query,
                filterByBrands: selectedBrandFilters,
                filterByProductTypes: selectedProductTypeFilters,
                gender: mapSelectedDepartmentToGender(
                  selectedDepartmentFilters
                ),
                resultSize
              })
            }}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {searchToggleButtons}
            <CurationFiltersButton
              onClick={onFiltersButtonClick}
              selectedFiltersCount={
                selectedBrandFilters.length +
                selectedDepartmentFilters.length +
                selectedProductTypeFilters.length
              }
              isLoading={isFilterLoading}
            />
          </Box>
          {isFilterError && (
            <PageError
              errorTitle={FILTER_ERROR_TITLE}
              errorDetails={{
                endpoint: filterEndpoint,
                errorData: filterError
              }}
              isErrorTitlePersonalized={true}
              isFullWidth={true}
              isInlineAlert={true}
            />
          )}
          {!isLoading && !isError && !data?.results && (
            <SearchHelperText searchType={SEARCH_OPTIONS.FASHION_MAP} />
          )}
          <ProductSearchResults
            endpoint="FashionMap"
            error={error}
            handleClickForPdp={handleClickForPdp}
            isLoading={isLoading}
            isError={isError}
            results={data?.results}
          />
        </Box>
      )}
    </>
  )
}

export default FashionMapSearch
