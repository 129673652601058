import { Box, Chip, Typography } from '@mui/material'
import { ReactElement, memo } from 'react'
import { FilterOptionT, SelectFilterFunctionT } from 'types/FashionMapSearch'

interface PropsT {
  selectedBrandFilters?: FilterOptionT[]
  selectedDepartmentFilters?: FilterOptionT[]
  selectedProductTypeFilters?: FilterOptionT[]
  selectedSearchFilters?: FilterOptionT[]
  onSelectBrand?: SelectFilterFunctionT
  onSelectDepartment?: SelectFilterFunctionT
  onSelectProductType?: SelectFilterFunctionT
  handleRemoveSearchFilter?: (filterName: string) => void
}

interface BaseChipPropsT {
  filter: string
  label?: string
  onDelete: ({
    currentFilter,
    isSelected
  }: {
    currentFilter: string
    isSelected: boolean
  }) => void
}

const BaseChip = ({ filter, label, onDelete }: BaseChipPropsT) => {
  return (
    <Chip
      key={filter}
      label={label ? label : filter}
      data-testid={`${filter}-chip`}
      onDelete={() =>
        onDelete({
          currentFilter: filter,
          isSelected: false
        })
      }
      sx={{ mr: 1, mt: 1 }}
    />
  )
}

const renderFilterChips = (
  filters: FilterOptionT[],
  onDelete: ({
    currentFilter,
    isSelected
  }: {
    currentFilter: string
    isSelected: boolean
  }) => void
) => {
  return filters.map((filter) => (
    <BaseChip
      key={filter.id}
      filter={filter.name}
      label={filter.label}
      onDelete={onDelete}
    />
  ))
}

const SelectedFilters = (props: PropsT): ReactElement => {
  const {
    onSelectBrand,
    onSelectDepartment,
    onSelectProductType,
    handleRemoveSearchFilter,
    selectedBrandFilters = [],
    selectedDepartmentFilters = [],
    selectedProductTypeFilters = [],
    selectedSearchFilters = []
  } = props

  const hasFiltersSelected =
    selectedBrandFilters.length > 0 ||
    selectedDepartmentFilters.length > 0 ||
    selectedProductTypeFilters.length > 0 ||
    selectedSearchFilters.length > 0

  return (
    <>
      <Typography ml={2} mt={2} fontWeight="bold" variant="subtitle2">
        Filtered by:
      </Typography>
      <Box ml={2} mt={1} mb={2}>
        {!hasFiltersSelected ? (
          <Typography mt={2} variant="body2" color="text.secondary">
            No filters selected
          </Typography>
        ) : (
          <>
            {handleRemoveSearchFilter &&
              renderFilterChips(selectedSearchFilters, ({ currentFilter }) =>
                handleRemoveSearchFilter(currentFilter)
              )}
            {onSelectDepartment &&
              renderFilterChips(
                selectedDepartmentFilters,
                ({ currentFilter }) =>
                  onSelectDepartment({ currentFilter, isSelected: false })
              )}
            {onSelectProductType &&
              renderFilterChips(
                selectedProductTypeFilters,
                ({ currentFilter }) =>
                  onSelectProductType({
                    currentFilter,
                    isSelected: false
                  })
              )}
            {onSelectBrand &&
              renderFilterChips(selectedBrandFilters, ({ currentFilter }) =>
                onSelectBrand({ currentFilter, isSelected: false })
              )}
          </>
        )}
      </Box>
    </>
  )
}

export default memo(SelectedFilters)
