import { CurationSliceT } from 'app/curationSlice'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'

export type DossierOriginT = 'curation' | 'appointment'

export enum CustomerDossierStatusT {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type DossierLogsAttributesT = {
  curationId?: number
  curationSlice?: CurationSliceT
  appointmentId?: string
  customerId?: string
}

export const generateCustomerSummaryLog = ({
  dossierOrigin,
  dossierLogsAttributes,
  status
}: {
  dossierOrigin: DossierOriginT
  dossierLogsAttributes: DossierLogsAttributesT
  status: CustomerDossierStatusT
}): void => {
  if (dossierOrigin === 'curation') {
    generateNewRelicLogs('generateSummary', {
      status,
      curationId: dossierLogsAttributes?.curationId,
      ocpId: dossierLogsAttributes?.customerId
    })
  } else if (dossierOrigin === 'appointment') {
    generateNewRelicLogs('generateAppointmentDossierSummary', {
      status,
      appointmentId: dossierLogsAttributes?.appointmentId,
      ocpId: dossierLogsAttributes?.customerId
    })
  }
}

export const generateCustomerSummaryFeedbackDetailsLog = ({
  dossierOrigin,
  dossierLogsAttributes,
  customerSummaryFeedbackDetails
}: {
  dossierOrigin: DossierOriginT
  dossierLogsAttributes: DossierLogsAttributesT
  customerSummaryFeedbackDetails: string[]
}): void => {
  const feedbackDetails = {
    notAccurate: customerSummaryFeedbackDetails.includes('notAccurate'),
    notUseful: customerSummaryFeedbackDetails.includes('notUseful'),
    hardToUnderstand:
      customerSummaryFeedbackDetails.includes('hardToUnderstand')
  }

  if (dossierOrigin === 'curation') {
    generateNewRelicLogs('customerSummaryFeedbackDetails', {
      ...(dossierLogsAttributes?.curationSlice
        ? mapCurationSliceToNewRelicLog(dossierLogsAttributes?.curationSlice)
        : {}),
      ...feedbackDetails
    })
  } else if (dossierOrigin === 'appointment') {
    generateNewRelicLogs('customerSummaryAppointmentFeedbackDetails', {
      appointmentId: dossierLogsAttributes?.appointmentId,
      ...feedbackDetails
    })
  }
}

export enum CustomerSummaryFeedback {
  HELPFUL = 'helpful',
  UNHELPFUL = 'unhelpful'
}

export const generateCustomerSummaryFeedbackLog = ({
  dossierOrigin,
  dossierLogsAttributes,
  customerSummaryFeedbackVote
}: {
  dossierOrigin: DossierOriginT
  dossierLogsAttributes: DossierLogsAttributesT
  customerSummaryFeedbackVote: CustomerSummaryFeedback
}): void => {
  if (dossierOrigin === 'curation') {
    generateNewRelicLogs('customerSummaryFeedback', {
      ...(dossierLogsAttributes?.curationSlice
        ? {
            ...mapCurationSliceToNewRelicLog(
              dossierLogsAttributes?.curationSlice
            )
          }
        : {}),
      customerSummaryFeedbackVote
    })
  } else if (dossierOrigin === 'appointment') {
    generateNewRelicLogs('customerSummaryAppointmentFeedback', {
      appointmentId: dossierLogsAttributes?.appointmentId,
      customerSummaryFeedbackVote
    })
  }
}
