import { Button } from '@mui/material'
import { ReactElement } from 'react'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

type PropsT = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  shouldHaveTopMargin: boolean
}

const ScanButton = (props: PropsT): ReactElement => {
  const { shouldHaveTopMargin, onClick } = props

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      sx={{ mt: shouldHaveTopMargin ? 2 : 0 }}
      startIcon={<QrCodeScannerIcon />}
    >
      Scan Items
    </Button>
  )
}

export default ScanButton
