import { ChangeEvent, ReactElement, memo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { FilterOptionT } from 'types/FashionMapSearch'

interface PropsT {
  filters: FilterOptionT[]
  subCategories?: {
    subcategory: string
    values: { name: string; id: number; isSelected: boolean }[]
  }[]
  noFiltersMessage?: string
  onSelect: (e: ChangeEvent<HTMLInputElement>, subCategory?: string) => void
  showSearchBox: boolean
  summaryTitle: string
}

interface FilterOptionCheckBoxPropsT {
  filterName: string
  isSelected: boolean
  onSelect: (e: ChangeEvent<HTMLInputElement>, subCategory?: string) => void
  subCategory?: string
}

interface SubCategoryListT {
  subCategory: {
    subcategory: string
    values: {
      name: string
      id: number
      isSelected: boolean
    }[]
  }
  onSelect: (e: ChangeEvent<HTMLInputElement>, subCategory?: string) => void
}

const NoFilterMatches = ({ query }: { query: string }): ReactElement => {
  return (
    <Box mb={2}>
      <Typography variant="subtitle2" fontWeight="bold">
        No results found for &quot;{query}&quot;
      </Typography>
      <Typography variant="caption">
        Check the spelling and try again.
      </Typography>
    </Box>
  )
}

const calculateRender = (
  prev: FilterOptionCheckBoxPropsT,
  next: FilterOptionCheckBoxPropsT
) => {
  return prev.isSelected === next.isSelected
}

const FilterOptionCheckBox = memo(function FilterOptionCheckBox({
  filterName,
  isSelected,
  onSelect,
  subCategory
}: FilterOptionCheckBoxPropsT) {
  return (
    <>
      {filterName && (
        <ListItem disablePadding>
          <ListItemIcon>
            <Checkbox
              name={filterName}
              checked={isSelected}
              onChange={(e) => {
                onSelect(e, subCategory)
              }}
              data-testid={`${filterName}-checkbox`}
            />
          </ListItemIcon>
          <ListItemText
            primary={filterName}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </ListItem>
      )}
    </>
  )
},
calculateRender)

const SubCategoryList = ({
  subCategory,
  onSelect
}: SubCategoryListT): JSX.Element => {
  const [open, setOpen] = useState(false)
  return (
    <Box ml={3}>
      <List>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ListItemText
            primary={subCategory.subcategory}
            primaryTypographyProps={{ variant: 'subtitle2' }}
            onClick={() => setOpen(!open)}
            sx={{ cursor: 'pointer' }}
          />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>
        {open &&
          subCategory.values.map((filter) => (
            <FilterOptionCheckBox
              key={`${filter.id}-${filter.name}`}
              filterName={filter.name}
              isSelected={filter.isSelected}
              onSelect={onSelect}
              subCategory={subCategory.subcategory}
            />
          ))}
      </List>
    </Box>
  )
}

const FiltersAccordion = (props: PropsT): ReactElement => {
  const {
    filters,
    subCategories,
    noFiltersMessage,
    onSelect,
    showSearchBox,
    summaryTitle
  } = props
  const [query, setQuery] = useState('')

  const searchedFilterMatches = filters.filter((option) =>
    option.name.toLowerCase().includes(query.toLowerCase())
  )

  return (
    <Accordion
      defaultExpanded={false}
      disableGutters
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none'
        }
      }}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon data-testid={`ExpandMoreIcon-${summaryTitle}`} />
        }
        aria-controls={`${summaryTitle}-content`}
        id={`${summaryTitle}-header`}
      >
        <Typography variant="subtitle2">{summaryTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0 }}>
        {showSearchBox && (
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            size="small"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setQuery('')}
                >
                  <HighlightOffIcon />
                </IconButton>
              ),
              startAdornment: <SearchIcon />
            }}
          />
        )}

        {filters.length === 0 ? (
          <Typography variant="body2" p={2}>
            {noFiltersMessage}
          </Typography>
        ) : subCategories && subCategories.length > 0 ? (
          subCategories.map((subCategory, i) => (
            <SubCategoryList
              key={i}
              subCategory={subCategory}
              onSelect={onSelect}
            />
          ))
        ) : (
          <List>
            {!query ? (
              filters.map((filter) => (
                <FilterOptionCheckBox
                  key={`${filter.id}-${filter.name}`}
                  filterName={filter.name}
                  isSelected={filter.isSelected}
                  onSelect={onSelect}
                />
              ))
            ) : searchedFilterMatches.length > 0 ? (
              searchedFilterMatches.map((filter) => {
                return (
                  <FilterOptionCheckBox
                    key={`${filter.id}-${filter.name}`}
                    filterName={filter.name}
                    isSelected={filter.isSelected}
                    onSelect={onSelect}
                  />
                )
              })
            ) : (
              <NoFilterMatches query={query} />
            )}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(FiltersAccordion)
